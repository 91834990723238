import { SVGProps } from "react";

const CautionIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="70"
      height="70"
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="70" height="70" rx="35" fill="#FFE2E2" />
      <path
        d="M47.0092 41.4401L38.7505 25.9949C37.1505 23.0024 32.8518 23.0024 31.2505 25.9949L22.9919 41.4401C21.5244 44.1851 23.5169 47.5 26.6357 47.5H43.3654C46.4829 47.5 48.4767 44.1839 47.0092 41.4401ZM34.063 32.5C34.063 31.9825 34.483 31.5625 35.0005 31.5625C35.518 31.5625 35.938 31.9825 35.938 32.5V37.5C35.938 38.0175 35.518 38.4375 35.0005 38.4375C34.483 38.4375 34.063 38.0175 34.063 37.5V32.5ZM35.0256 42.5C34.3356 42.5 33.7691 41.94 33.7691 41.25C33.7691 40.56 34.323 40 35.013 40H35.0256C35.7168 40 36.2756 40.56 36.2756 41.25C36.2756 41.94 35.7156 42.5 35.0256 42.5Z"
        fill="#DC0000"
      />
    </svg>
  );
};

export default CautionIcon;
