import { SVGProps } from "react";

const UsersIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.83 9.43103C13.772 9.33903 13.776 9.22305 13.829 9.12805C14.264 8.35205 14.511 7.45602 14.511 6.49902C14.511 6.16702 14.4819 5.843 14.424 5.526C14.405 5.422 14.4369 5.314 14.5179 5.245C15.0729 4.774 15.791 4.49902 16.561 4.49902C18.678 4.49902 20.3281 6.54998 19.6281 8.75098C19.3241 9.70598 18.544 10.481 17.587 10.78C16.038 11.264 14.566 10.601 13.83 9.43103ZM18.11 12.28H16.1281C15.8621 12.28 15.725 12.603 15.918 12.787C17.338 14.139 18.001 16.088 18.001 18.02V19.2C18.001 19.366 18.135 19.5 18.301 19.5H22.001C22.277 19.5 22.501 19.276 22.501 19V16.6801C22.5 14.6501 21.35 12.28 18.11 12.28ZM9.00903 10.5C11.215 10.5 13.009 8.706 13.009 6.5C13.009 4.294 11.215 2.5 9.00903 2.5C6.80303 2.5 5.00903 4.294 5.00903 6.5C5.00903 8.706 6.80303 10.5 9.00903 10.5ZM11 12.5H7C2.94 12.5 1.5 15.473 1.5 18.019V21C1.5 21.276 1.724 21.5 2 21.5H16C16.276 21.5 16.5 21.276 16.5 21V18.019C16.5 15.473 15.06 12.5 11 12.5Z"
        fill="#939393"
      />
    </svg>
  );
};

export { UsersIcon };
