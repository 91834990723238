import { z } from "zod";

export const validateRedirectSearch = z.object({
  redirect: z.string().optional(),
});

export const codeRedirectSearch = z.object({
  code: z.string().optional(),
});

export const emailSearch = z.object({
  email: z.string().email().optional(),
});
