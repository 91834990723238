const MessageBubbleIcon = () => {
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.69063 11.5998C8.17293 11.5665 8.65122 11.4895 9.11963 11.3698C9.69767 11.544 10.3079 11.5837 10.9036 11.4858L10.9746 11.4798C11.1876 11.4798 11.4666 11.6018 11.8736 11.8588V11.4358C11.8735 11.3622 11.8933 11.29 11.9308 11.2266C11.9682 11.1633 12.0221 11.1112 12.0866 11.0758C12.264 10.9772 12.428 10.8648 12.5786 10.7388C13.1706 10.2438 13.5056 9.58284 13.5056 8.88284C13.5056 8.65284 13.4686 8.42284 13.3956 8.20284C13.5745 7.87369 13.7186 7.52682 13.8256 7.16784C14.1694 7.67422 14.354 8.2718 14.3556 8.88384C14.3556 9.83384 13.9076 10.7208 13.1316 11.3688C13.0016 11.4768 12.8656 11.5768 12.7236 11.6688V12.6568C12.7236 12.9968 12.3256 13.1918 12.0456 12.9898C11.7803 12.7938 11.506 12.6112 11.2226 12.4418C11.1414 12.3939 11.0568 12.3518 10.9696 12.3158C10.7338 12.3501 10.4959 12.3675 10.2576 12.3678C9.34436 12.3747 8.45008 12.1071 7.69063 11.5998ZM2.57062 9.62284C1.34762 8.59984 0.640625 7.20484 0.640625 5.70984C0.640625 2.65584 3.56062 0.214844 7.12562 0.214844C10.6906 0.214844 13.6116 2.65484 13.6116 5.70984C13.6116 8.76384 10.6916 11.2048 7.12562 11.2048C6.72885 11.2055 6.33264 11.1751 5.94063 11.1138C5.77262 11.1518 5.10162 11.5458 4.13363 12.2438C3.78363 12.4968 3.28662 12.2518 3.28662 11.8268V10.1398C3.03558 9.98533 2.79627 9.81253 2.57062 9.62284ZM5.96462 10.0728C5.99462 10.0728 6.02429 10.0755 6.05363 10.0808C6.40429 10.1388 6.76162 10.1678 7.12562 10.1678C10.1376 10.1678 12.5476 8.15284 12.5476 5.70984C12.5476 3.26684 10.1386 1.25284 7.12663 1.25284C4.11462 1.25284 1.70362 3.26784 1.70362 5.71084C1.70362 6.89184 2.26762 8.00484 3.26362 8.83684C3.51429 9.04684 3.78729 9.23451 4.08262 9.39984C4.16293 9.44456 4.22995 9.50976 4.27685 9.58881C4.32375 9.66786 4.34886 9.75793 4.34962 9.84984V10.8218C5.11562 10.3148 5.61862 10.0748 5.96462 10.0748"
        fill="url(#paint0_linear_4850_8908)"
      />
      <path
        d="M4.49763 6.64369C4.72492 6.64369 4.9429 6.5534 5.10362 6.39268C5.26433 6.23196 5.35463 6.01398 5.35463 5.78669C5.35463 5.5594 5.26433 5.34142 5.10362 5.1807C4.9429 5.01998 4.72492 4.92969 4.49763 4.92969C4.27033 4.92969 4.05235 5.01998 3.89163 5.1807C3.73092 5.34142 3.64062 5.5594 3.64063 5.78669C3.64062 6.01398 3.73092 6.23196 3.89163 6.39268C4.05235 6.5534 4.27033 6.64369 4.49763 6.64369Z"
        fill="url(#paint1_linear_4850_8908)"
      />
      <path
        d="M7.28669 6.64369C7.51398 6.64369 7.73196 6.5534 7.89268 6.39268C8.0534 6.23196 8.14369 6.01398 8.14369 5.78669C8.14369 5.5594 8.0534 5.34142 7.89268 5.1807C7.73196 5.01998 7.51398 4.92969 7.28669 4.92969C7.0594 4.92969 6.84142 5.01998 6.6807 5.1807C6.51998 5.34142 6.42969 5.5594 6.42969 5.78669C6.42969 6.01398 6.51998 6.23196 6.6807 6.39268C6.84142 6.5534 7.0594 6.64369 7.28669 6.64369Z"
        fill="url(#paint2_linear_4850_8908)"
      />
      <path
        d="M10.0679 6.64369C10.2952 6.64369 10.5132 6.5534 10.6739 6.39268C10.8346 6.23196 10.9249 6.01398 10.9249 5.78669C10.9249 5.5594 10.8346 5.34142 10.6739 5.1807C10.5132 5.01998 10.2952 4.92969 10.0679 4.92969C9.84065 4.92969 9.62267 5.01998 9.46195 5.1807C9.30123 5.34142 9.21094 5.5594 9.21094 5.78669C9.21094 6.01398 9.30123 6.23196 9.46195 6.39268C9.62267 6.5534 9.84065 6.64369 10.0679 6.64369Z"
        fill="url(#paint3_linear_4850_8908)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4850_8908"
          x1="7.49862"
          y1="0.214844"
          x2="7.49862"
          y2="13.0718"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1BC9FA" />
          <stop offset="1" stopColor="#1778F0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4850_8908"
          x1="7.49763"
          y1="0.215688"
          x2="7.49763"
          y2="13.0727"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1BC9FA" />
          <stop offset="1" stopColor="#1778F0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_4850_8908"
          x1="7.50169"
          y1="0.215688"
          x2="7.50169"
          y2="13.0727"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1BC9FA" />
          <stop offset="1" stopColor="#1778F0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_4850_8908"
          x1="7.49694"
          y1="0.214686"
          x2="7.49694"
          y2="13.0717"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1BC9FA" />
          <stop offset="1" stopColor="#1778F0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default MessageBubbleIcon;
