import { Button } from "@/components/ui/button";
import {
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
} from "@/components/ui/dialog";
import CloseCircleFilledIcon from "@/icons/close-circle-filled";

import { Trash } from "@/icons/trash";

interface DeleteOnboardingItemDialogProps {
  onConfirm: () => void;
}

export function DeleteOnboardingItemDialog({
  onConfirm,
}: DeleteOnboardingItemDialogProps) {
  return (
    <DialogContent className="sm:max-w-[425px] rounded-none">
      <div className="flex justify-between items-start">
        <DialogHeader>
          <div className="bg-[#FEF3F2] w-[3rem] h-[3rem] flex justify-center items-center rounded-full">
            <div className="bg-[#FEE4E2] w-[2.2rem] h-[2.2rem] flex justify-center items-center rounded-full ">
              <Trash width={24} height={24} className="cursor-pointer" />
            </div>
          </div>
        </DialogHeader>
        <DialogClose>
          <CloseCircleFilledIcon />
        </DialogClose>
      </div>
      <div>
        <p className="font-inter text-[1.125rem] leading-[1.75rem] font-[600]">
          Delete
        </p>
        <p className="font-inter text-[0.875rem] leading-[1.25rem] font-[400] text-grey-stone">
          Are you sure you want to delete?
        </p>
      </div>
      <DialogFooter className="flex items-center mt-[2rem]">
        <DialogClose asChild>
          <Button
            type="button"
            className="bg-white text-grey-btn-outlined text-[1rem] h-[2.75rem] border border-line"
          >
            Cancel
          </Button>
        </DialogClose>
        <DialogClose asChild>
          <Button
            type="button"
            className="bg-destructive-dark text-white text-[1rem] h-[2.75rem]"
            onClick={onConfirm}
          >
            Delete
          </Button>
        </DialogClose>
      </DialogFooter>
    </DialogContent>
  );
}
