import { Button } from "@/components/ui/button";
import { Header } from "@/components/ui/header";
import { __DEV__ } from "@/config/env";
import { AuthState, authStore } from "@/internals/auth";
import { MiscState } from "@/internals/misc";
import { cn } from "@/lib/utils";
import { QueryClient } from "@tanstack/react-query";
import {
  ErrorComponent,
  ErrorRouteComponent,
  Outlet,
  createRootRouteWithContext,
  redirect,
  useRouter,
} from "@tanstack/react-router";
import { TanStackRouterDevtools } from "@tanstack/router-devtools";
import { WretchError } from "wretch/resolver";

const fontSans = "ui-sans-serif";

const RootErrorComponent: ErrorRouteComponent = ({ error, reset }) => {
  const router = useRouter();
  if (error instanceof WretchError) {
    const status = error.status;
    if (status === 401) {
      const logout = async () => {
        authStore.getState().logout();
        redirect({
          to: "/sign-in",
          search: {
            // Use the current location to power a redirect after login
            // (Do not use `router.state.resolvedLocation` as it can
            // potentially lag behind the actual current location)
            redirect: location.href,
          },
        });
        // Reset the router error boundary
        reset();
        // Invalidate the route to reload the loader
        await router.invalidate();
        location.reload();
      };
      return (
        <div>
          <Header
            header={"You are currently logged out"}
            subtitle="Please click button below to login"
          />

          <Button onClick={logout}>Logout</Button>
        </div>
      );
    }
  }
  return <ErrorComponent error={error} />;
};

export const Route = createRootRouteWithContext<{
  auth: AuthState;
  misc: MiscState;
  queryClient: QueryClient;
}>()({
  component: App,
  beforeLoad: ({ context, location }) => {
    const ignoreAuthRoutes = ["/sign-in", "/sign-up", "/forgot-password"];
    const ignoreAuth = ignoreAuthRoutes.includes(location.pathname);

    const auth = context.auth;
    // const misc = context.misc;
    const ignoreStatusRoutes = ["/setup-user", "/verify-email"];
    const ignoreStatus = ignoreStatusRoutes.includes(location.pathname);

    // auth check -> email verification check -> setup user check

    // ignore auth check on certain routes
    if (ignoreAuth) {
      return;
    }

    // If the user is logged out, redirect them to the login page
    if (!auth.isAuthenticated || !auth.user) {
      throw redirect({
        to: "/sign-in",
        search: {
          // Use the current location to power a redirect after login
          // (Do not use `router.state.resolvedLocation` as it can
          // potentially lag behind the actual current location)
          redirect: location.href,
        },
      });
    }

    // ignore id check on certain routes
    if (ignoreStatus) {
      return;
    }
    // check if user hasn't validated their email
    if (auth.user!.email_verification_status === "unverified") {
      throw redirect({
        to: "/verify-email",
        search: {
          // Use the current location to power a redirect after login
          // (Do not use `router.state.resolvedLocation` as it can
          // potentially lag behind the actual current location)
          redirect: location.href,
        },
      });
    }

    // check if user hasn't been setup
    const user = auth.user;
    const setupUserCheck =
      !!user.address &&
      !!user.phone_number &&
      !!user.nationality &&
      !!user.date_of_birth;
    if (!setupUserCheck) {
      throw redirect({
        to: "/setup-user",
        search: {
          // Use the current location to power a redirect after login
          // (Do not use `router.state.resolvedLocation` as it can
          // potentially lag behind the actual current location)
          redirect: location.href,
        },
      });
    }

    // check if business has been selected
    // if (!misc.accountId) {
    //   throw redirect({
    //     to: "/select-business",
    //     search: {
    //       // Use the current location to power a redirect after login
    //       // (Do not use `router.state.resolvedLocation` as it can
    //       // potentially lag behind the actual current location)
    //       redirect: location.href,
    //     },
    //   });
    // }
  },
  errorComponent: RootErrorComponent,
});

function App() {
  return (
    <div
      className={cn(
        "min-h-screen bg-background font-sans antialiased",
        fontSans
      )}
    >
      <Outlet />
      {__DEV__ ? (
        <>
          {/* dev tools */}
          <TanStackRouterDevtools />
        </>
      ) : null}
    </div>
  );
}
