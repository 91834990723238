import { Settings } from "./types";

export const SETTINGS_QUERY_KEYS = {
  identityConfig: ["identity-config"],
  banksList: ["banks-list"],
  validateBankAccount: ({
    bank_code,
    account_number,
  }: Pick<Settings.BankValidationDto, "account_number" | "bank_code">) => [
    "validate_bank_account",
    bank_code,
    account_number,
  ],
} as const;
