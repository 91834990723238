import { FormInput } from "@/components/form-input";
import { UseFormReturn, useWatch } from "react-hook-form";
import { GbpAccountSchemaDto } from "./validators";
import { Form } from "@/components/ui/form";
import { FormSingleComboBox } from "@/components/form-single-combo-box";
import { countriesDrownDownData } from "@/i18n/countries-data";

const GBPAccount = ({
  newGbpForm,
  onSubmit,
}: {
  newGbpForm: UseFormReturn<GbpAccountSchemaDto>;
  onSubmit: () => void;
}) => {
  const account = useWatch({ control: newGbpForm.control });

  return (
    <Form {...newGbpForm}>
      <form
        onSubmit={(e) => {
          e.stopPropagation();
          newGbpForm.handleSubmit(onSubmit)(e);
        }}
        id={"gbp_form"}
        className=" pb-[1.5rem] flex flex-col gap-[24px]"
      >
        <div>
          <div className="flex flex-col gap-[1.5rem]">
            <FormInput
              disabled
              defaultValue={"EUR"}
              name={`currency`}
              label="Currency"
              className="hidden"
              labelClass="hidden"
              control={newGbpForm.control}
            />
            <FormSingleComboBox
              name={`country`}
              label="Country"
              data={countriesDrownDownData}
              control={newGbpForm.control}
            />
            <FormInput
              name={`account_name`}
              label="Legal Name"
              control={newGbpForm.control}
            />
            <FormInput
              name={`bank_name`}
              label="Bank Name"
              control={newGbpForm.control}
            />
            {account?.country !== "GB" ? (
              <>
                <FormInput
                  name={`iban`}
                  label="IBAN"
                  control={newGbpForm.control}
                />
                <FormInput
                  name={`swift`}
                  label="BIC/SWIFT"
                  control={newGbpForm.control}
                />
              </>
            ) : (
              <>
                <FormInput
                  name={`account_number`}
                  label="Account number"
                  control={newGbpForm.control}
                />
                <FormInput
                  name={`sort_code`}
                  label="Sort code"
                  control={newGbpForm.control}
                />
              </>
            )}
          </div>
        </div>
      </form>
    </Form>
  );
};

export default GBPAccount;
