import { MenuItem } from "@/components/ui/menu-item";
import ApplicationReviewInProgressSvg from "@/icons/application-review-in-progress";
import MailEnvelope from "@/icons/mail-envelope";
import MessageBubbleIcon from "@/icons/message-bubble";
import { useMiscStore } from "@/internals/misc";

const ApplicationReviewInProgress = () => {
  const tawkMessengerRef = useMiscStore((state) => state.tawkMessengerRef);

  const openWidget = () => {
    tawkMessengerRef?.current?.maximize();
  };
  return (
    <div className="flex flex-col items-center max-w-[33.9375rem] mx-auto md:mt-[7.8rem]">
      <ApplicationReviewInProgressSvg />
      <p className="text-center font-bold text-[1.125rem] mb-[0.75rem]">
        Application Review In Progress
      </p>
      <p className="text-center font-[500] text-[0.875rem] mb-[1rem]">
        We're diligently reviewing your documents to ensure everything is in
        order. Your patience is appreciated as we strive for accuracy and
        thoroughness. Rest assured, we're working hard to complete the process
        as swiftly as possible.
      </p>
      <div className="space-y-[0.875rem]">
        <p className="text-[0.875rem] text-center mb-0 font-[500]">
          To contact us:
        </p>

        <div className="flex gap-[0.75rem]">
          <a href="mailto:support@clearerpay.com">
            <MenuItem
              className="w-[6.0625rem] flex justify-center items-center  px-2.5 bg-grey hover:bg-grey-border rounded-[0.625rem]"
              size="lg"
              leftIcon={<MailEnvelope />}
            >
              <p className=" text-[0.875rem] font-[500]">Mail</p>
            </MenuItem>
          </a>

          <MenuItem
            className="w-[6.0625rem] flex justify-center items-center  px-2.5 bg-grey hover:bg-grey-border rounded-[0.625rem]"
            size="lg"
            leftIcon={<MessageBubbleIcon />}
            onClick={openWidget}
          >
            <p className=" text-[0.875rem] font-[500] ">Live Chat</p>
          </MenuItem>
        </div>
      </div>
    </div>
  );
};

export default ApplicationReviewInProgress;
