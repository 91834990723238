import { SelectItem } from "@/components/ui/multi-select/types";
import { getKeys } from "@/internals/object-keys";
import { countries, ICountry, TCountryCode } from "countries-list";

export type ISO3166Alpha2 = TCountryCode;
export type CountryCodeAlpha2 = TCountryCode;

interface Country extends ICountry {
  iso2: ISO3166Alpha2;
}

export const iso2Countries: Country[] = getKeys(countries).map((iso2) => ({
  ...countries[iso2],
  iso2: iso2,
}));

export const countriesDrownDownData: SelectItem<ISO3166Alpha2>[] =
  iso2Countries.map((country) => ({
    label: country.name,
    value: country.iso2,
  }));
