import { createFileRoute, useRouter } from "@tanstack/react-router";

import { Button } from "@/components/ui/button";

import { FormSingleComboBox } from "@/components/form-single-combo-box";

import {
  CheckboxWithLabel,
  useCheckbox,
} from "@/components/ui/checkbox-with-label";
import { Form } from "@/components/ui/form";
import { Header } from "@/components/ui/header";
import { countriesDrownDownData } from "@/i18n/countries-data";

import { zodResolver } from "@hookform/resolvers/zod";
import { FieldPath, SubmitHandler, useForm, useWatch } from "react-hook-form";
import { z } from "zod";

import { FormDatePicker } from "@/components/form-date-picker";
import FormFileInput from "@/components/form-file-input";
import { FormInput } from "@/components/form-input";
import { FormMultipleComboBox } from "@/components/form-multitple-combo-box";
import { FormPhoneInput } from "@/components/form-phone-input";
import { FormTextArea } from "@/components/form-textarea";
import { AddressInput } from "@/components/ui/address-input";
import {
  Clients,
  InitOnboardingProfile,
  Licenses,
  RC_NUMBER_MAX_LENGTH,
  SourceOfFunds,
  addressDefault,
  positionInCompany,
} from "@/data/onboarding.data";
import { ValidationResponseError } from "@/domains";
import { isValidPhoneNumber } from "libphonenumber-js";

import { Dialog, DialogContent } from "@/components/ui/dialog";
import {
  Organization,
  accountsQueryOptions,
  useCreateBusiness,
} from "@/domains/organizations";
import { extractValidationErrors } from "@/domains/utils";
import { getAddressErrors, onPlaceSelectedFn } from "@/internals/address";
import { useToggle } from "@/internals/hooks/useToggle";
import { getKeys } from "@/internals/object-keys";
import {
  AddressSchema,
  InternalFileSchema,
} from "@/internals/validators/form.validation";
import { useQuery } from "@tanstack/react-query";
import React from "react";
import { toast } from "sonner";
import { WretchError } from "wretch";

export const Route = createFileRoute("/create-business")({
  component: CompanyProfile,
});

const requiresLicenseSchema = z.enum(["true", "false"]);

const requiresRegulatoryLicenseSchema = z.object({
  requires_license: z.literal(requiresLicenseSchema.enum.true),
  license_name: z.string().trim().min(1, "Required"),
  copy_of_regulatory_license: InternalFileSchema,
});

const doesNotRequireRegulatoryLicenseSchema = z.object({
  requires_license: z.literal(requiresLicenseSchema.enum.false),
});

const conditionalSchema = z.discriminatedUnion("requires_license", [
  requiresRegulatoryLicenseSchema,
  doesNotRequireRegulatoryLicenseSchema,
]);

const defaultCompanyProfileSchema = z.object({
  email_address: z.string({ required_error: "Required" }).email(),
  rc_number: z
    .string()
    .trim()
    .min(1, "Required")
    .max(RC_NUMBER_MAX_LENGTH, `Max ${RC_NUMBER_MAX_LENGTH} Characters`),
  company_name: z.string().trim().min(1, "Required"),
  requires_license: requiresLicenseSchema,
  funds_source_description: z
    .string()
    .min(10, "Min 10 characters")
    .max(5000, "Max 5000 characters"),
  operations_description: z
    .string()
    .min(10, "Min 10 characters")
    .max(5000, "Max 5000 characters"),

  legal_address: AddressSchema,
  operations_address: AddressSchema,
  client_type: z.string().trim().min(1, "Required"),
  funds_source: z.string().trim().min(1, "Required"),
  outflow_countries: z.array(z.string()).min(1, "Required"),
  inflow_countries: z.array(z.string()).min(1, "Required"),
  country_of_incorporation: z.string().trim().min(1, "Required"),
  trading_name: z.string().trim().min(1, "Required"),
  tax_id: z.string().optional(),
  registration_date: z.date({ required_error: "Required" }),
  phone_number: z.string().refine(
    (value) => {
      if (!value) {
        return false;
      }
      if (typeof value != "string") {
        return false;
      }
      return isValidPhoneNumber(value);
    },
    { message: "Not a valid phone number" }
  ),
  position_in_company: z.string().trim().min(1, "Required"),
});

const CompanyProfileSchema = z.intersection(
  defaultCompanyProfileSchema,
  conditionalSchema
);

type CompanyProfileSchemaDTO = z.infer<typeof CompanyProfileSchema>;

export function CompanyProfile() {
  const router = useRouter();
  const { isChecked, toggle } = useCheckbox();
  const form = useForm<CompanyProfileSchemaDTO>({
    resolver: zodResolver(CompanyProfileSchema),
    // @ts-expect-error TODO: fix, don't know why this is showing
    defaultValues: InitOnboardingProfile,
  });
  const requires_license = useWatch({
    control: form.control,
    name: "requires_license",
  });
  // const { data, isLoading } = useQuery(cacQueryOptions(rc_number ?? ""));
  const { refetch } = useQuery(accountsQueryOptions());
  const createBusiness = useCreateBusiness();

  const [isOpen, { on, off, _set }] = useToggle(false);

  const onLegalBusinessAddressSelected = onPlaceSelectedFn(
    form.setValue,
    "legal_address"
  );

  const legalBusinessAddressErrors = React.useMemo(() => {
    return getAddressErrors(form.formState.errors?.legal_address);
  }, [form.formState.errors?.legal_address]);

  const onOperationsAddressSelected = onPlaceSelectedFn(
    form.setValue,
    "operations_address"
  );

  const operationsAddressErrors = React.useMemo(() => {
    return getAddressErrors(form.formState.errors?.operations_address);
  }, [form.formState.errors?.operations_address]);

  const onSubmit: SubmitHandler<CompanyProfileSchemaDTO> = (values) => {
    createBusiness.mutate(
      {
        ...values,
        registration_date: values.registration_date.toISOString(),
        requires_license: values.requires_license === "true" ? true : false,
        client_type:
          values.client_type === "both"
            ? [String(Organization.Corporate), String(Organization.Individual)]
            : [values.client_type],
        funds_source: [values.funds_source],
        position_held: values.position_in_company,
      },
      {
        onSuccess(data) {
          toast("Business created successfully", {
            description: "Please proceed to onboard",
          });
          refetch();
          // navigate to onboarding flow with the business id
          router.navigate({
            to: "/organization/$organizationId/onboarding/purpose-of-account",
            params: {
              organizationId: data.data.organization.id,
            },
          });
        },
        onError(error) {
          try {
            off();
            const err = error as WretchError;
            if (err.status === 400) {
              const response = err.json as ValidationResponseError;
              const errors: Record<
                FieldPath<Organization.CreateBusinessDTO>,
                string
              > = extractValidationErrors(response.data.validation_error);
              const keys = getKeys(errors);
              keys.forEach((key) => {
                const value = errors[key];
                form.setError(
                  // @ts-expect-error key here is messing up
                  key,
                  { message: value, type: "value" },
                  { shouldFocus: true }
                );
              });
            }
          } catch (error) {
            console.log(error);
          }
        },
      }
    );
  };
  const onFormError = () => {
    if (!form.getFieldState("position_in_company").error) off();
  };

  return (
    <div className="flex flex-col md:px-80 px-3 min-h-screen mb-10">
      <div className="flex flex-col w-[500px] max-w-[500px] mx-auto mt-5">
        <h1 className="text-2xl font-bold text-black mb-[10px]">
          Create a business
        </h1>
        <p className="font-medium text-base text-grey-text mb-[10px]">
          Tell us about your company
        </p>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit, onFormError)}
            id="create-business-form"
          >
            <div className="mt-[38px] flex flex-col gap-[24px]">
              <FormInput
                name="rc_number"
                label="Company registration number"
                type="number"
                maxLength={10}
                control={form.control}
              />

              <FormInput
                name="company_name"
                label="Company Legal Business name"
                control={form.control}
              />

              <FormInput
                name="trading_name"
                label="Company trading name"
                control={form.control}
              />

              <FormInput
                name="email_address"
                label="Company Email address"
                control={form.control}
              />

              <FormSingleComboBox
                label="Country of incorporation"
                data={countriesDrownDownData}
                name="country_of_incorporation"
                control={form.control}
              />

              <FormDatePicker
                control={form.control}
                label="Date of Incorporation"
                name="registration_date"
              />
            </div>

            <Header
              header="Legal Business Address"
              subtitle="This is the business registered with your CAC"
            />

            <div className="mt-[24px] flex flex-col gap-[24px]">
              <div>
                <AddressInput
                  control={form.control}
                  name="legal_address.address"
                  label="Address"
                  onPlaceSelected={onLegalBusinessAddressSelected}
                />
                <span className="text-destructive">
                  {legalBusinessAddressErrors}
                </span>
              </div>

              <CheckboxWithLabel
                className="border-grey-background data-[state=checked]:bg-transparent data-[state=checked]:text-grey-background"
                label="Operations address is the same"
                handleOnChange={() => {
                  toggle();
                  // TODO: BAD CODE, BUT IT WORKS
                  if (!isChecked) {
                    form.setValue(
                      "operations_address",
                      form.getValues("legal_address")
                    );
                  } else {
                    form.setValue("operations_address", addressDefault);
                  }
                }}
              />
            </div>

            <Header
              header="Operations Address"
              subtitle="This is the business registered where your operation happen"
            />

            <div className="mt-[24px] flex flex-col gap-[24px]">
              <div>
                <AddressInput
                  control={form.control}
                  name="operations_address.address"
                  label="Address"
                  onPlaceSelected={onOperationsAddressSelected}
                />
                <span className="text-destructive">
                  {operationsAddressErrors}
                </span>
              </div>

              <FormPhoneInput
                name="phone_number"
                control={form.control}
                label="Phone"
                defaultCountry={"NG"}
              />

              <FormInput
                label="Tax number (optional)"
                name="tax_id"
                control={form.control}
              />
            </div>

            <Header
              header="Other Information"
              subtitle="Other Company Information"
            />

            <div className="my-[24px] flex flex-col gap-[24px]">
              <FormSingleComboBox
                label="Do you require a regulatory license for your business operations?"
                data={Licenses}
                control={form.control}
                name="requires_license"
              />

              {requires_license === "true" ? (
                <>
                  <FormInput
                    label="License name"
                    name="license_name"
                    control={form.control}
                  />
                  <FormFileInput
                    label="Upload copy of regulatory license"
                    maxSize={10}
                    name={"copy_of_regulatory_license"}
                    control={form.control}
                  />
                </>
              ) : null}

              <FormTextArea
                name="operations_description"
                label="Describe your business activity"
                control={form.control}
              />

              <FormSingleComboBox
                label="Are your clients companies or individuals?"
                data={Clients}
                control={form.control}
                name="client_type"
              />

              <FormMultipleComboBox
                control={form.control}
                label="Incoming payment countries"
                data={countriesDrownDownData}
                placeholder=""
                name={"inflow_countries"}
              />

              <FormMultipleComboBox
                control={form.control}
                label="Outgoing payment countries"
                data={countriesDrownDownData}
                placeholder=""
                name={"outflow_countries"}
              />

              <FormSingleComboBox
                label="Source of funds"
                data={SourceOfFunds}
                control={form.control}
                name="funds_source"
              />

              <div>
                <FormTextArea
                  name="funds_source_description"
                  label="How does your business generate income?"
                  control={form.control}
                />

                <p className="font-medium mt-2 text-xs">
                  Min 10 characters, Max 5000 characters
                </p>
              </div>
            </div>

            <div className="items-center">
              <Dialog open={isOpen} onOpenChange={_set}>
                <Button
                  size={"sm"}
                  type="button"
                  className="w-full"
                  loading={createBusiness.isPending}
                  onClick={async () => {
                    const keys = getKeys(InitOnboardingProfile).filter(
                      (key) => key !== "position_in_company"
                    );
                    const valid = await form.trigger(keys, {
                      shouldFocus: true,
                    });
                    if (valid) {
                      on();
                    }
                  }}
                >
                  Continue
                </Button>
                <DialogContent>
                  <div className="mt-[38px] flex flex-col gap-[24px]">
                    <FormSingleComboBox
                      label="What is your position in this company?"
                      data={positionInCompany}
                      control={form.control}
                      name="position_in_company"
                    />
                    <Button
                      size={"sm"}
                      type="submit"
                      form="create-business-form"
                      className="w-full"
                      loading={createBusiness.isPending}
                    >
                      Create Business
                    </Button>
                  </div>
                </DialogContent>
              </Dialog>
            </div>
          </form>
        </Form>
      </div>
    </div>
  );
}
