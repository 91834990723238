import { cn } from "@/lib/utils";
import * as React from "react";
import { FieldError, useFormContext } from "react-hook-form";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  iconLeft?: React.ReactNode; // Icon to be displayed on the left side
  iconRight?: React.ReactNode; // Icon to be displayed on the right side
  onInputChange?: (value: string) => void; // Function to handle input changes
  inputClassName?: string; // Add inputClassName to PhoneInputProps
  wrapperClassName?: string;
  isInvalid?: FieldError;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      wrapperClassName,
      className,
      type,
      iconLeft,
      iconRight,
      onInputChange,
      ...props
    },
    ref
  ) => {
    const { formState, getFieldState } = useFormContext();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;

      onInputChange?.(value); // Call the onInputChange function with the new value
    };
    const { error } = React.useMemo(() => {
      return getFieldState(props.name as string, formState);
    }, [formState, getFieldState, props.name]);

    return (
      <div
        className={cn("relative flex items-center w-full", wrapperClassName)}
      >
        {iconLeft && (
          <span className="absolute inset-y-0 left-0 pl-3 flex items-center">
            {iconLeft}
          </span>
        )}
        <input
          type={type}
          className={cn(
            "flex h-10 w-full rounded-md border border-text-input bg-background px-3 py-2 text-base ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground  disabled:cursor-not-allowed focus:outline-none focus:ring-1 focus:ring-primary-green focus:border-primary-green focus:shadow-primary-green",
            error && "border-destructive",
            iconLeft ? "pl-10" : null, // Add left padding if there's an icon on the left
            iconRight ? "pr-10" : null, // Add right padding if there's an icon on the right
            className
          )}
          ref={ref}
          onChange={handleChange} // Handle changes to the input value
          {...props}
        />
        {iconRight && (
          <span className="absolute inset-y-0 right-0 pr-3 flex items-center">
            {iconRight}
          </span>
        )}
      </div>
    );
  }
);

Input.displayName = "Input";

export { Input };
