import {
  AddressSchema,
  InternalFileSchema,
} from "@/internals/validators/form.validation";
import { isValidPhoneNumber } from "libphonenumber-js";
import { z } from "zod";

export const shareHolderTypeEnum = z.enum(["corporate", "individual"]);

const businessShareholderSchema = z.object({
  shareholder_type: z.literal(shareHolderTypeEnum.enum.corporate),
  name: z.string().trim().min(1, "Required"),
  register_of_directors: InternalFileSchema,
  register: InternalFileSchema,
  memorandum: InternalFileSchema,
  incorporation_certificate: InternalFileSchema,
});

const individualShareholderSchema = z.object({
  shareholder_type: z.literal(shareHolderTypeEnum.enum.individual),
  first_name: z.string().trim().min(1, "Required"),
  last_name: z.string().trim().min(1, "Required"),
  verification: InternalFileSchema,
});

const shareholdersConditionalSchema = z.discriminatedUnion("shareholder_type", [
  businessShareholderSchema,
  individualShareholderSchema,
]);

const defaultShareholdersSchema = z.object({
  id: z.string().nullable(),
  shareholder_type: shareHolderTypeEnum,
  stakeholder_shareholding: z
    .string()
    .trim()
    .refine((val) => /^\d+(\.\d+)?$/.test(val), {
      message: "Input is not a valid number",
    })
    .refine(
      (val) => {
        const num = parseFloat(val);
        return num >= 0 && num <= 100;
      },
      { message: "Number is not within the range of 0 and 100" }
    )
    .transform((val) => parseFloat(val).toFixed(3)),
  email: z.string().trim().min(1, "Required"),
  phone_number: z.string().refine(
    (value) => {
      if (!value) {
        return false;
      }
      if (typeof value != "string") {
        return false;
      }
      return isValidPhoneNumber(value);
    },
    { message: "Not a valid phone number" }
  ),
  address: AddressSchema,
  nationality: z.string().trim().min(1, "Required"),
  date_of_birth: z.date({ required_error: "Required" }),
});

export const ShareholdersSchema = z.intersection(
  defaultShareholdersSchema,
  shareholdersConditionalSchema
);

const defaultDirectorSchema = z.object({
  id: z.string().nullable(),
  first_name: z.string().trim().min(1, "Required"),
  last_name: z.string().trim().min(1, "Required"),
  date_of_birth: z.date({ required_error: "Required" }),
  address: AddressSchema,
  nationality: z.string().trim().min(1, "Required"),
  email: z.string().email().trim().min(1, "Required"),
  phone_number: z.string().refine(
    (value) => {
      if (!value) {
        return false;
      }
      if (typeof value != "string") {
        return false;
      }
      return isValidPhoneNumber(value);
    },
    { message: "Not a valid phone number" }
  ),
  verification: InternalFileSchema,
  proof_of_address: InternalFileSchema,
  isShareholder: z.boolean(),
});

const directorIsShareholderSchema = z.object({
  isShareholder: z.literal(true),
  stakeholder_shareholding: z
    .string()
    .trim()
    .refine((val) => /^\d+(\.\d+)?$/.test(val), {
      message: "Input is not a valid number",
    })
    .refine(
      (val) => {
        const num = parseFloat(val);
        return num >= 0 && num <= 100;
      },
      { message: "Number is not within the range of 0 and 100" }
    )
    .transform((val) => parseFloat(val).toFixed(3)),
});
const directorIsNotShareholderSchema = z.object({
  isShareholder: z.literal(false),
});

const conditionalDirectorSchema = z.discriminatedUnion("isShareholder", [
  directorIsShareholderSchema,
  directorIsNotShareholderSchema,
]);

export const directorSchema = z.intersection(
  defaultDirectorSchema,
  conditionalDirectorSchema
);

export const companyStructureSchema = z.object({
  directors: z.array(directorSchema),
  shareholders: z.array(ShareholdersSchema).min(1),
});

export type companyStructureSchemaDTO = z.infer<typeof companyStructureSchema>;
export type DirectorSchemaDTO = z.infer<typeof directorSchema>;
export type ShareholderSchemaDTO = z.infer<typeof ShareholdersSchema>;
