import { ClearerLogo } from "@/assets";
import { FormInput } from "@/components/form-input";

import { Button } from "@/components/ui/button";
import { Form } from "@/components/ui/form";
import { Header } from "@/components/ui/header";
import { useSignUp } from "@/domains/users";
import { getKeys } from "@/internals/object-keys";
import { zodResolver } from "@hookform/resolvers/zod";
import { createFileRoute, Link, useRouter } from "@tanstack/react-router";

import { ValidationResponseError } from "@/domains";
import { useSignIn } from "@/domains/sessions";
import { extractValidationErrors } from "@/domains/utils";
import { useAuthStore } from "@/internals/auth";
import { useRedirectEffect } from "@/internals/hooks/useRedirectEffect";
import { FieldPath, SubmitHandler, useForm } from "react-hook-form";
import { toast } from "sonner";
import { WretchError } from "wretch";
import { z } from "zod";

const signUpDtoSchema = z.object({
  email_address: z.string({ required_error: "Required" }).email(),
  firstname: z
    .string({ required_error: "Required" })
    .min(2, "2 min Characters")
    .max(24, "24 max characters"),
  lastname: z
    .string({ required_error: "Required" })
    .min(2, "2 min Characters")
    .max(24, "24 max characters"),
  password: z
    .string({ required_error: "Required" })
    .min(8, "8 min Characters")
    .max(128, "128 max characters"),
});

type SignUpDto = z.infer<typeof signUpDtoSchema>;

export const Route = createFileRoute("/sign-up")({
  component: Register,
});

const init = {
  address: {
    address: "",
    state: "",
    city: "",
    zip_code: "",
    country: "",
  },
  email_address: "",
  firstname: "",
  lastname: "",
  password: "",
};

export function Register() {
  const signUpMutation = useSignUp();
  const signInMutation = useSignIn();
  const router = useRouter();
  const isAuthenticated = useAuthStore((s) => s.isAuthenticated);
  const login = useAuthStore((s) => s.login);

  useRedirectEffect(isAuthenticated, "/create-business");

  const form = useForm<SignUpDto>({
    resolver: zodResolver(signUpDtoSchema),
    defaultValues: init,
  });

  const onSubmit: SubmitHandler<SignUpDto> = (values) => {
    signUpMutation.mutate(values, {
      onSuccess() {
        signInMutation.mutate(
          {
            email_address: values.email_address,
            password: values.password,
          },
          {
            onSuccess: (data) => {
              toast.success("Registration Complete", {
                description: "Please check email for OTP",
              });
              login(data);
              router.navigate({ to: "/create-business" });
              router.invalidate();
            },
          }
        );
      },
      onError(error) {
        try {
          const err = error as WretchError;
          if (err.status === 400) {
            const response = err.json as ValidationResponseError;
            const errors: Record<
              FieldPath<SignUpDto>,
              string
            > = extractValidationErrors(response.data.validation_error);
            const keys = getKeys(errors);
            keys.forEach((key) => {
              const value = errors[key];
              form.setError(key, { message: value, type: "value" });
            });
          }
        } catch (error) {
          console.log(error);
        }
      },
    });
  };

  return (
    <div className="flex">
      <div className="md:w-80 bg-white bg-gradient-to-b from-gray-50 via-gray-200 to-gray-300  h-screen flex-1 fixed  hidden md:flex flex-col justify-between items-center py-[5.4rem] ">
        <a href="https://clearerpay.com/" target="_blank">
          <img
            src={ClearerLogo}
            className="object-cover w-[13.8rem] h-[4.75rem]"
          />
        </a>
        <div className="text-center">
          <Link to="/sign-in" as="span">
            <p className="text-[1.125rem]">Have an account?</p>
            <span className="font-bold leading-5 text-[1.125rem]">Sign in</span>
          </Link>
        </div>
      </div>
      <main className="flex-1">
        <div className="flex flex-col md:ml-80 sm:border-r sm:border-zinc-700 min-h-screen">
          <div className="flex flex-col pt-2 px-4  pb-4 items-center ">
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(onSubmit)}
                className="w-full  md:w-[31rem] "
              >
                <Header
                  header="Create an account"
                  subtitle="This will be used to create your account"
                  className="mb-[2.375rem]"
                />
                <div className="space-y-[1.5rem] w-full  md:w-[31rem]">
                  <div className="md:flex gap-[1.5rem] justify-self-stretch">
                    <FormInput
                      name="firstname"
                      control={form.control}
                      label="First Name"
                    />

                    <FormInput
                      name="lastname"
                      control={form.control}
                      label="Last Name"
                    />
                  </div>

                  <FormInput
                    name="email_address"
                    control={form.control}
                    label="Email Address"
                  />

                  <FormInput
                    label="Password"
                    control={form.control}
                    name="password"
                    type="password"
                  />
                </div>
                <div className="space-y-[2.375rem] mt-[2.375rem]">
                  <p className="font-medium text-sm text-grey-text">
                    Creating an account means your agree to our{" "}
                    <Link to="/" className="text-primary-green">
                      Terms & Conditions
                    </Link>
                  </p>
                  <Button
                    size="lg"
                    variant="default"
                    type="submit"
                    className="w-full"
                    loading={
                      signUpMutation.isPending || signInMutation.isPending
                    }
                  >
                    Get Started
                  </Button>
                </div>
              </form>
            </Form>
          </div>
        </div>
      </main>
    </div>
  );
}
