import { PlusIcon } from "@heroicons/react/20/solid";

import { useRef, useState } from "react";

import { InitSettlementAccounts } from "@/data/onboarding.data";

import { useFieldArray, useForm, useFormContext } from "react-hook-form";

import { Button } from "@/components/ui/button";

import { banksListOptions } from "@/domains/settings";
import { zodResolver } from "@hookform/resolvers/zod";
import { useSuspenseQuery } from "@tanstack/react-query";
import { DrawerViews, DrawerViewsKeys } from "../company-structure/types";
import OnboardingAccordion from "../onboarding-accordions/onboarding-accordion";
import OnboardingAccordionContent from "../onboarding-accordions/onboarding-accordion-content";
import OnboardingItemCard from "../onboarding-accordions/onboarding-item-card";
import OnboardingItemDrawer from "../onboarding-accordions/onboarding-item-drawer";
import NGNAccount from "./NGNAccount";
import {
  ngnAccountSchema,
  NgnAccountSchemaDto,
  SettlementAccountsSchemaDto,
} from "./validators";

const NgnSection = () => {
  const drawerTriggerRef = useRef<HTMLButtonElement>(null);
  const closeDrawerButtonRef = useRef<HTMLButtonElement>(null);
  const [editingIndex, setEditingIndex] = useState<string>();
  const { data: banksList } = useSuspenseQuery(banksListOptions());
  // const closeDrawer = () => {
  //   if (closeDrawerButtonRef.current) {
  //     closeDrawerButtonRef.current.click();
  //   }
  // };
  const openDrawer = () => {
    if (drawerTriggerRef.current) {
      drawerTriggerRef.current.click();
    }
  };
  const { control } = useFormContext<SettlementAccountsSchemaDto>();
  const newNgnForm = useForm<NgnAccountSchemaDto>({
    defaultValues: InitSettlementAccounts["ngn_account"][0],
    resolver: zodResolver(ngnAccountSchema),
  });
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "ngn_account",
  });
  const getFieldsMap = (account: NgnAccountSchemaDto) => ({
    "Account Name": account.account_name,
    "Account Number": account.account_number,
    "Bank Name": account.bank_name,
  });
  const handleClickEdit = (account: NgnAccountSchemaDto, index: number) => {
    setEditingIndex(String(index));
    Object.keys(account).forEach((key) => {
      newNgnForm.setValue(
        key as keyof NgnAccountSchemaDto,
        account[key as keyof NgnAccountSchemaDto]
      );
    });
    setCurrView("edit");
  };
  const handleAddNew = () => {
    if (fields[0] && fields[0].account_number === "") {
      update(0, newNgnForm.getValues());
    } else {
      append(newNgnForm.getValues());
    }
    setCurrView("list");
    newNgnForm.reset();
  };

  const handleSubmitUpdate = () => {
    if (editingIndex) {
      update(Number(editingIndex), newNgnForm.getValues());
      setCurrView("list");
      newNgnForm.reset();
    }
  };

  const [currView, setCurrView] = useState<DrawerViewsKeys>("form");
  const DrawerView: DrawerViews = {
    form: {
      heading: "Add NGN(₦) Account",
      view: (
        <NGNAccount
          newNgnForm={newNgnForm}
          onSubmit={handleAddNew}
          banksList={banksList?.NG}
        />
      ),
      footer: (
        <Button
          type="submit"
          form={"ngn_form"}
          className="flex gap-[0.5rem] items-center h-[3.5rem]"
        >
          <p>Add</p>
        </Button>
      ),
    },
    list: {
      heading: "NGN(₦) Account details",
      view: (
        <div className="flex flex-col gap-[1.5rem] ">
          {fields.map((field, index) => {
            if (field.account_number !== "")
              return (
                <OnboardingItemCard
                  key={index}
                  item={getFieldsMap(field)}
                  onEditClick={() => {
                    handleClickEdit(field, index);
                  }}
                  onDelete={() => {
                    remove(index);
                  }}
                />
              );
          })}
        </div>
      ),
      footer: (
        <Button
          variant={"secondary"}
          type="button"
          className="flex gap-[0.5rem] items-center h-[3.5rem] "
          onClick={() => {
            setCurrView("form");
          }}
        >
          {" "}
          <p>Add NGN Account</p>
          <PlusIcon width={16} height={16} />{" "}
        </Button>
      ),
    },
    edit: {
      heading: "NGN(₦) Account details",
      view: (
        <NGNAccount
          newNgnForm={newNgnForm}
          onSubmit={handleSubmitUpdate}
          banksList={banksList?.NG}
        />
      ),
      footer: (
        <Button
          type="submit"
          form={"ngn_form"}
          className="flex gap-[0.5rem] items-center h-[3.5rem]"
        >
          <p>Update</p>
        </Button>
      ),
    },
  };

  return (
    <section className={"mt-[38px]"}>
      <OnboardingAccordion
        name={"NGN Account details"}
        buttonLabel="Add NGN account"
        openFormDrawer={() => {
          newNgnForm.reset();
          setCurrView("form");
          openDrawer();
        }}
      >
        <OnboardingAccordionContent
          type="account"
          fields={
            fields[0] && fields[0].account_number !== ""
              ? fields.map((field) => getFieldsMap(field))
              : undefined
          }
          openListDrawer={() => {
            setCurrView("list");
            openDrawer();
          }}
          onEditClick={() => {
            openDrawer();
            handleClickEdit(fields[0], 0);
          }}
          onDelete={() => {
            remove(0);
          }}
        />
      </OnboardingAccordion>
      <OnboardingItemDrawer
        triggerRef={drawerTriggerRef}
        heading={DrawerView[currView].heading}
        closeButtonRef={closeDrawerButtonRef}
        Footer={DrawerView[currView].footer}
      >
        {DrawerView[currView].view}
      </OnboardingItemDrawer>
    </section>
  );
};

export default NgnSection;
