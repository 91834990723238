import { Settings } from "@/domains/settings/types";
import { Control, FieldPath, FieldValues } from "react-hook-form";
import { FileUpload } from "./ui/file-upload";
import {
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  useFormField,
} from "./ui/form";
import { InputProps } from "./ui/input";

interface FormFileProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends InputProps {
  control: Control<TFieldValues>;
  name: TName;
  inputStyles?: string;
  maxSize?: number;
  label?: string;
}

function Message() {
  const { error } = useFormField();

  // @ts-expect-error id does not exist on error no fix for this tbh
  const message = error?.message ?? error?.id?.message;

  return <FormMessage>{message}</FormMessage>;
}

const FormFileInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  props: FormFileProps<TFieldValues, TName>
) => {
  const { control, name, ...rest } = props;

  return (
    <FormField
      control={control}
      name={name}
      // ref shouldn't be in here
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      render={({ field: { ref, ...field }, fieldState }) => {
        return (
          <FormItem className="w-full ">
            {/* <FormLabel className="text-lg font-medium">{label}</FormLabel> */}
            <FormControl>
              <FileUpload
                inputStyles={fieldState.error && "border-destructive"}
                {...field}
                {...rest}
                inputName={name}
                value={field.value as Settings.InternalFile | null | undefined}
                onUploadComplete={(file: Settings.InternalFile | null) => {
                  if (file) {
                    return field.onChange(file);
                  } else {
                    field.onChange(null);
                  }
                }}
              />
            </FormControl>
            <Message />
          </FormItem>
        );
      }}
    />
  );
};

export default FormFileInput;
