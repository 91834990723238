import React from "react";
import { useToggle } from "./useToggle";

export default function useComponentFocus(value = false) {
  const [focused, { on, off }] = useToggle(value);
  return React.useMemo(
    () => ({
      focused,
      onMouseEnter: on,
      onMouseLeave: off,
    }),
    [focused, off, on]
  );
}
