import { Toaster as SonnerToaster } from "@/components/ui/sonner";
import { Toaster } from "@/components/ui/toaster";
import { QueryClientProvider } from "@tanstack/react-query";
import { createRouter, RouterProvider } from "@tanstack/react-router";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import React, { useRef } from "react";
import ReactDOM from "react-dom/client";
import { initAnalyticsTools } from "./config/analytics.ts";
import { __DEV__ } from "./config/env.ts";
import { queryClient } from "./config/react-query.ts";
import "./globals.css";
import { AuthStoreProvider, useAuthStore } from "./internals/auth";
import { MiscStoreProvider, useMiscStore } from "./internals/misc.tsx";
import { ThemeProvider } from "./internals/theme-provider.tsx";
import { routeTree } from "./routeTree.gen.ts";
import { PendingComponent } from "./sections/pending.tsx";

// Create a new router instance
const router = createRouter({
  routeTree,
  // defaultErrorComponent: ({ error }) => <ErrorComponent error={error} />,
  context: {
    auth: undefined!, // We'll inject this when we render
    misc: undefined!,
    queryClient,
  },
  defaultPreload: "intent",
  // Since we're using React Query, we don't want loader calls to ever be stale
  // This will ensure that the loader is always called when the route is preloaded or visited
  defaultPreloadStaleTime: 0,
  defaultPendingComponent: PendingComponent,
});

// Register the router instance for type safety
declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}
// eslint-disable-next-line react-refresh/only-export-components
function InnerApp() {
  const auth = useAuthStore();
  const misc = useMiscStore();
  const ref = useRef(null);

  React.useEffect(() => {
    initAnalyticsTools();
    if (ref.current && !misc.tawkMessengerRef) {
      misc.setTawkMessengerRef(ref);
    }
    // if we set, this will recursively set the value
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <RouterProvider router={router} context={{ auth, misc }} />
      {/* tawk */}
      {__DEV__ ? null : (
        <TawkMessengerReact
          ref={ref}
          propertyId="6524c00b6fcfe87d54b83bc2"
          widgetId="1hcbosbgn"
        />
      )}
    </>
  );
}

ReactDOM.createRoot(document.getElementById("app")!).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <AuthStoreProvider>
        <MiscStoreProvider>
          <ThemeProvider defaultTheme="light" storageKey="vite-ui-theme">
            <InnerApp />
            {/* toasters */}
            <Toaster />
            <SonnerToaster />
          </ThemeProvider>
        </MiscStoreProvider>
      </AuthStoreProvider>
    </QueryClientProvider>
  </React.StrictMode>
);
