const BriefCaseIcon = () => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 4H13.75V2.5C13.75 1.54 12.96 0.75 12 0.75H6C5.03 0.75 4.25 1.54 4.25 2.5V4H3C1 4 0 5 0 7V7.95996C0 8.88996 0.550004 9.71004 1.41 10.04C2.79 10.59 4.21999 11.0101 5.64999 11.3101C5.75999 11.3301 5.86 11.25 5.92 11.16C6.64 10.08 7.8 9.43005 9 9.43005C10.21 9.43005 11.36 10.08 12.09 11.16C12.15 11.25 12.25 11.3301 12.36 11.3101C13.79 11.0101 15.21 10.59 16.59 10.05C17.45 9.71005 18 8.88996 18 7.95996V7C18 5 17 4 15 4ZM5.75 4V2.5C5.75 2.36 5.86 2.25 6 2.25H12C12.14 2.25 12.25 2.36 12.25 2.5V4H5.75ZM12.66 12.78C12.55 12.8 12.43 12.8101 12.32 12.8101C11.74 12.8101 11.19 12.51 10.84 11.99C10.4 11.33 9.71 10.9301 9 10.9301C8.3 10.9301 7.61 11.33 7.17 11.99C6.75 12.61 6.04001 12.92 5.35001 12.78C3.83001 12.47 2.32 12.0199 0.860001 11.4399C0.550001 11.3199 0.26 11.16 0 10.95V16C0 18 1 19 3 19H15C17 19 18 18 18 16V10.96C17.74 11.16 17.45 11.3199 17.14 11.4399C15.68 12.0199 14.17 12.47 12.66 12.78ZM9.002 14C8.451 14 8 13.552 8 13C8 12.448 8.443 12 8.994 12H9.002C9.553 12 10 12.448 10 13C10 13.552 9.553 14 9.002 14Z"
        fill="#939393"
      />
    </svg>
  );
};

export default BriefCaseIcon;
