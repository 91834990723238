import React, { useRef } from "react";
import { StoreApi, create, useStore } from "zustand";

interface TawkMethods {
  showWidget: Noop;
  maximize: Noop;
}

export interface MiscState {
  accountId: string | null;
  selectBusiness: (accountId: string) => void;
  tawkMessengerRef: React.MutableRefObject<null | TawkMethods> | null;
  setTawkMessengerRef: (
    ref: React.MutableRefObject<null | TawkMethods>
  ) => void;
}

export const MiscStoreContext = React.createContext<StoreApi<MiscState> | null>(
  null
);

// eslint-disable-next-line react-refresh/only-export-components
export const miscStore = create<MiscState>((set) => ({
  accountId: null,
  selectBusiness: (accountId: string) => set({ accountId }),
  tawkMessengerRef: null,
  setTawkMessengerRef(ref) {
    set({ tawkMessengerRef: ref });
  },
}));

export const MiscStoreProvider = ({ children }: React.PropsWithChildren) => {
  const storeRef = useRef<StoreApi<MiscState>>();
  if (!storeRef.current) {
    storeRef.current = miscStore;
  }
  return (
    <MiscStoreContext.Provider value={storeRef.current}>
      {children}
    </MiscStoreContext.Provider>
  );
};

export function useMiscStore(): MiscState;
export function useMiscStore<T>(selector: (state: MiscState) => T): T;
// eslint-disable-next-line react-refresh/only-export-components
export function useMiscStore<T>(selector?: (state: MiscState) => T) {
  const store = React.useContext(MiscStoreContext);
  if (!store) {
    throw new Error("Missing MiscStoreProvider");
  }
  return useStore(store, selector!);
}

// eslint-disable-next-line react-refresh/only-export-components
export function useMisc() {
  return useMiscStore((s) => s.accountId);
}
