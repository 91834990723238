import { RC_NUMBER_MAX_LENGTH } from "@/data/onboarding.data";
import { queryOptions, useMutation } from "@tanstack/react-query";
import {
  createBusiness,
  createSettlementAccounts,
  endOnboarding,
  getAccount,
  getAccounts,
  getCAC,
  getDocuments,
  getOrganization,
  getServices,
  getSettlementAccounts,
  getStructure,
  setDocuments,
  setServices,
  setStructure,
  updateAccount,
  updateStructure,
} from "./api";
import { ORGANIZATION_QUERY_KEYS } from "./constants";
import { mapSettlementAccounts } from "./utils";

export function accountsQueryOptions() {
  return queryOptions({
    queryKey: ORGANIZATION_QUERY_KEYS.accounts,
    queryFn: async () => getAccounts(),
  });
}

export function accountQueryOptions(id: string) {
  return queryOptions({
    queryKey: ORGANIZATION_QUERY_KEYS.account(id),
    queryFn: async () => getAccount({ id }),
    select(data) {
      return data.data;
    },
  });
}

export function useUpdateAccount() {
  return useMutation({
    mutationFn: updateAccount,
  });
}
export function servicesQueryOptions(id: string) {
  return queryOptions({
    queryKey: ORGANIZATION_QUERY_KEYS.services(id),
    queryFn: async () => getServices({ id }),
  });
}

export function organizationQueryOptions(id: string) {
  return queryOptions({
    queryKey: ORGANIZATION_QUERY_KEYS.organization(id),
    queryFn: async () => getOrganization({ id }),
    select(data) {
      return data.data;
    },
  });
}

export function cacQueryOptions(rc_number: string) {
  return queryOptions({
    queryKey: ORGANIZATION_QUERY_KEYS.cac(rc_number),
    queryFn: async () => getCAC(rc_number),
    enabled: rc_number.length == RC_NUMBER_MAX_LENGTH,
  });
}

export function useCreateBusiness() {
  return useMutation({
    mutationFn: createBusiness,
  });
}

export function useSetServices() {
  return useMutation({
    mutationFn: setServices,
  });
}

export function useSetStructure() {
  return useMutation({
    mutationFn: setStructure,
  });
}

export function useUpdateStructure() {
  return useMutation({
    mutationFn: updateStructure,
  });
}

export function useSetDocuments() {
  return useMutation({
    mutationFn: setDocuments,
  });
}

export function useEndOnboarding() {
  return useMutation({
    mutationFn: endOnboarding,
  });
}

export function documentsOptions(id: string) {
  return queryOptions({
    queryKey: ORGANIZATION_QUERY_KEYS.documents(id),
    queryFn: async () => getDocuments({ id }),
    select(data) {
      return data.data;
    },
  });
}

export function structureOptions(id: string) {
  return queryOptions({
    queryKey: ORGANIZATION_QUERY_KEYS.structure(id),
    queryFn: async () => getStructure({ id }),
    select(data) {
      return data.data;
    },
  });
}

export const useCreateSettlementAccounts = () => {
  return useMutation({
    mutationFn: createSettlementAccounts,
    mutationKey: ["create_settlement_account"],
  });
};

export function settlementAccountOptions(organizationId: string) {
  return queryOptions({
    queryKey: ORGANIZATION_QUERY_KEYS.settlementAccounts(organizationId),
    queryFn: async () => await getSettlementAccounts({ id: organizationId }),
    select(data) {
      return mapSettlementAccounts(data.data);
    },
  });
}
