import SignOutModal from "@/components/sign-out-modal";
import { Button } from "@/components/ui/button";
import { DialogTrigger } from "@/components/ui/dialog";
import { Header } from "@/components/ui/header";
import { identityConfigOptions } from "@/domains/settings";
import { LoginIcon } from "@/icons/login-in";
import { useAuthStore, useAuthUser } from "@/internals/auth";
import { useRedirectEffect } from "@/internals/hooks/useRedirectEffect";
import { validateRedirectSearch } from "@/internals/validators/route.validation";
import { Dialog } from "@radix-ui/react-dialog";
import { useSuspenseQuery } from "@tanstack/react-query";

import { FormDatePicker } from "@/components/form-date-picker";
import { FormPhoneInput } from "@/components/form-phone-input";
import { FormSingleComboBox } from "@/components/form-single-combo-box";
import { AddressInput } from "@/components/ui/address-input";
import { Form } from "@/components/ui/form";
import { ValidationResponseError } from "@/domains";
import { sessionQueryOptions } from "@/domains/sessions";
import { useUpdateUserProfile } from "@/domains/users";
import { extractValidationErrors } from "@/domains/utils";
import { countriesDrownDownData } from "@/i18n/countries-data";
import { getAddressErrors, onPlaceSelectedFn } from "@/internals/address";
import { getKeys } from "@/internals/object-keys";
import { AddressSchema } from "@/internals/validators/form.validation";
import { zodResolver } from "@hookform/resolvers/zod";
import { createFileRoute } from "@tanstack/react-router";
import { isValidPhoneNumber } from "libphonenumber-js";
import React from "react";
import { FieldPath, SubmitHandler, useForm } from "react-hook-form";
import { WretchError } from "wretch";
import { z } from "zod";

export const Route = createFileRoute("/setup-user")({
  component: IdentityVerification,
  validateSearch: validateRedirectSearch,
  loader: (opts) =>
    Promise.all([
      opts.context.queryClient.ensureQueryData(identityConfigOptions()),
      opts.context.queryClient.ensureQueryData(sessionQueryOptions()),
    ]),
});

const updateUserDTOSchema = z.object({
  address: AddressSchema,
  phone_number: z.string().refine(
    (value) => {
      if (!value) {
        return false;
      }
      if (typeof value != "string") {
        return false;
      }
      return isValidPhoneNumber(value);
    },
    { message: "Not a valid phone number" }
  ),
  nationality: z
    .string({ required_error: "Required" })
    .trim()
    .min(1, "Required"),
  date_of_birth: z.date({ required_error: "Required" }),
});
type updateUserDTO = z.infer<typeof updateUserDTOSchema>;

const init = {
  address: {
    address: "",
    state: "",
    city: "",
    zip_code: "",
    country: "",
  },
  phone_number: "",
  nationality: "",
  date_of_birth: undefined,
};

function IdentityVerification() {
  const { refetch } = useSuspenseQuery(sessionQueryOptions());
  const updateUserProfile = useUpdateUserProfile();

  const user = useAuthUser();
  const setUser = useAuthStore((s) => s.setUser);

  const setupUserCheck =
    !!user?.address &&
    !!user?.phone_number &&
    !!user?.nationality &&
    !!user?.date_of_birth;

  useRedirectEffect(setupUserCheck, "/create-business");

  const form = useForm<updateUserDTO>({
    resolver: zodResolver(updateUserDTOSchema),
    defaultValues: init,
  });

  const onPlaceSelected = onPlaceSelectedFn(form.setValue, "address");

  const addressErrors = React.useMemo(() => {
    return getAddressErrors(form.formState.errors?.address);
  }, [form.formState.errors?.address]);

  const onSubmit: SubmitHandler<updateUserDTO> = (values) => {
    updateUserProfile.mutate(values, {
      onSuccess: (response) => {
        setUser(response.data.user);
        refetch();
      },
      onError(error) {
        try {
          const err = error as WretchError;
          if (err.status === 400) {
            const response = err.json as ValidationResponseError;
            const errors: Record<
              FieldPath<updateUserDTO>,
              string
            > = extractValidationErrors(response.data.validation_error);
            const keys = getKeys(errors);
            keys.forEach((key) => {
              const value = errors[key];
              form.setError(key, { message: value, type: "value" });
            });
          }
        } catch (error) {
          console.log(error);
        }
      },
    });
  };

  return (
    <div className="flex flex-col justify-center items-center border h-screen pb-[2.875rem]">
      <div className="flex-1 w-full md:w-[31.25rem] space-y-[2.375rem] flex flex-col justify-center">
        <Header
          header="Setup User"
          headerClassName="capitalize text-center"
          subtitle="complete user profile"
          subHeaderClassName="text-center"
        />

        {/* form */}
        <Form {...form}>
          <form
            id={"update-user-details-form"}
            onSubmit={form.handleSubmit(onSubmit)}
            className="w-full  md:w-[31rem] flex flex-col gap-4"
          >
            <div>
              <AddressInput
                control={form.control}
                name="address.address"
                label="Address"
                onPlaceSelected={onPlaceSelected}
              />
              <span className="text-destructive">{addressErrors}</span>
            </div>

            <FormPhoneInput
              name="phone_number"
              label="Phone"
              defaultCountry={"NG"}
              control={form.control}
            />
            <FormSingleComboBox
              name="nationality"
              label="Nationality"
              data={countriesDrownDownData}
              control={form.control}
            />

            <FormDatePicker
              label="Date of birth"
              name="date_of_birth"
              control={form.control}
            />

            {/* divider */}

            <Button
              loading={updateUserProfile.isPending}
              type="submit"
              form="update-user-details-form"
            >
              Update Profile
            </Button>
          </form>
        </Form>
      </div>
      <Dialog>
        <DialogTrigger asChild>
          <div className="flex  w-full justify-center cursor-pointer">
            <div className="flex gap-x-2  text-text-input-placeholder">
              <LoginIcon />
              <p className="text-[1rem] text-text-input-placeholder">
                Sign Out
              </p>
            </div>
          </div>
        </DialogTrigger>
        <SignOutModal />
      </Dialog>
    </div>
  );
}
