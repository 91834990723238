import { api } from "@/config/wrench";
import { APIResponse } from "../response";
import { Settings } from "../settings/types";
import { Account, CreateBusinessResponse, Organization } from "./types";

const organizationsApi = api.url("/organizations");

export function getAccounts(): Promise<APIResponse<Account.Doc[]>> {
  return organizationsApi.get("/accounts").json<APIResponse<Account.Doc[]>>();
}

export function getAccount({
  id,
}: Settings.ID): Promise<APIResponse<Account.Doc | null>> {
  return organizationsApi.get(`/${id}/account`).json();
}

export function updateAccount({
  id,
  ...params
}: Settings.ID): Promise<APIResponse<Account.Doc | null>> {
  return organizationsApi.url(`/${id}/account`).put(params).json();
}

export function getCAC(
  rc_number: string
): Promise<Organization.GetCACResponse> {
  return organizationsApi
    .query({ rc_number })
    .get("/cac")
    .json<Organization.GetCACResponse>();
}

export function createBusiness(
  params: Organization.CreateBusinessDTO
): Promise<CreateBusinessResponse> {
  return organizationsApi.post(params).json<CreateBusinessResponse>();
}

export function setServices({
  id,
  ...params
}: Organization.PurposeOfAccountDTO): Promise<Organization.ServicesResponse> {
  return organizationsApi
    .url(`/${id}/services`)
    .post(params)
    .json<Organization.ServicesResponse>();
}

export function getServices({
  id,
}: Settings.ID): Promise<Organization.ServicesResponse> {
  return organizationsApi
    .get(`/${id}/services`)
    .json<Organization.ServicesResponse>();
}

export function getOrganization({
  id,
}: Settings.ID): Promise<Organization.OrganizationResponse> {
  return organizationsApi.get(`/${id}`).json();
}

export function setStructure({
  id,
  ...params
}: Organization.SetCompanyStructureDTO &
  Settings.ID): Promise<Organization.StructureResponse> {
  return organizationsApi.url(`/${id}/affiliate`).post(params).json();
}

export function updateStructure({
  id,
  ...params
}: Organization.SetCompanyStructureDTO &
  Settings.ID): Promise<Organization.StructureResponse> {
  return organizationsApi.url(`/${id}/affiliate`).put(params).json();
}

export function getStructure({
  id,
}: Settings.ID): Promise<Organization.StructureResponse> {
  return organizationsApi.get(`/${id}/affiliate`).json();
}

export function setDocuments({
  id,
  ...params
}: Organization.SetDocumentsDTO & Settings.ID): Promise<APIResponse<null>> {
  return organizationsApi.url(`/${id}/documents`).post(params).json();
}

export function getDocuments({
  id,
}: Settings.ID): Promise<Organization.DocumentsResponse> {
  return organizationsApi.url(`/${id}/documents`).get().json();
}

export function endOnboarding({ id }: Settings.ID): Promise<APIResponse> {
  return organizationsApi.url(`/${id}/end-onboarding`).post().json();
}

export function createSettlementAccounts({
  id,
  ...params
}: Organization.SettlementAccountsDTO & { id: string }) {
  return organizationsApi.url(`/${id}/settlement-accounts`).post(params).json();
}

export function getSettlementAccounts({
  id,
}: {
  id: string;
}): Promise<Organization.SettlementAccountsResponse> {
  return organizationsApi.url(`/${id}/settlement-accounts`).get().json();
}
