import React from "react";
import { HeaderProps } from "./types";

const Header: React.FC<HeaderProps> = ({
  className,
  header,
  subtitle,
  headerClassName,
  subHeaderClassName,
}) => {
  return (
    <div
      className={`grid w-full items-center gap-[10px] mt-[38px] ${
        className ? className : ""
      }`}
    >
      <p className={`text-black font-bold text-2xl ${headerClassName ?? ""}`}>
        {header}
      </p>

      <p
        className={`font-medium text-base text-grey-text ${subHeaderClassName ?? ""}`}
      >
        {subtitle}
      </p>
    </div>
  );
};

export { Header };
