import { _axiosApi } from "@/config/axios";
import { api, nullifySignal } from "@/config/wrench";
import { AxiosProgressEvent } from "axios";
import { Settings } from "./types";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";

const settings = api.url("/settings");

export function getIdentityConfig(
  controller?: AbortController
): Promise<Settings.IdentityConfigResponse> {
  return settings
    .signal(nullifySignal(controller))
    .get("/identity/config")
    .json<Settings.IdentityConfigResponse>();
}

export function uploadFile(
  formData: FormData,
  onUploadProgress?: ((progressEvent: AxiosProgressEvent) => void) | undefined
): {
  promise: Promise<Settings.FileUploadResponse>;
  controller: AbortController;
} {
  const controller = new AbortController();
  const promise = _axiosApi.post<FormData, Settings.FileUploadResponse>(
    "/web/upload",
    formData,
    {
      withCredentials: true,
      onUploadProgress: onUploadProgress,
      signal: controller.signal,
    }
  );

  return { promise, controller };
}

export function useUploadFile() {
  const [_controller, setController] = useState<AbortController>();
  const mutation = useMutation({
    mutationFn: async ({
      formData,
      onUploadProgress,
    }: {
      formData: FormData;
      onUploadProgress?:
        | ((progressEvent: AxiosProgressEvent) => void)
        | undefined;
    }) => {
      const { promise, controller } = uploadFile(formData, onUploadProgress);
      setController(controller);
      const data = await promise;
      return { data, controller };
    },
  });

  return { mutation, controller: _controller };
}

export function getBanksList() {
  return settings.url("/banks").get().json<Settings.BanksResponse>();
}

export function validateBankAccount(params: Settings.BankValidationDto) {
  return settings
    .url("/resolve-account")
    .post(params)
    .json<Settings.BankValidationResponse>();
}
