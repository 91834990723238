import { PlusIcon } from "@heroicons/react/20/solid";

import { useRef, useState } from "react";
import { format } from "date-fns";
import { InitDirector } from "@/data/onboarding.data";

import {
  useFieldArray,
  useForm,
  useFormContext,
  useWatch,
} from "react-hook-form";

import { Button } from "@/components/ui/button";
import {
  companyStructureSchemaDTO,
  directorSchema,
  DirectorSchemaDTO,
  ShareholderSchemaDTO,
} from "./validator";
import OnboardingAccordion from "../onboarding-accordions/onboarding-accordion";
import OnboardingItemDrawer from "../onboarding-accordions/onboarding-item-drawer";
import OnboardingAccordionContent from "../onboarding-accordions/onboarding-accordion-content";
import OnboardingItemCard from "../onboarding-accordions/onboarding-item-card";
import { zodResolver } from "@hookform/resolvers/zod";

import AddDirector from "./add-director";
import { DrawerViews, DrawerViewsKeys } from "./types";
import { getKeys } from "@/internals/object-keys";
const DirectorSection = () => {
  const drawerTriggerRef = useRef<HTMLButtonElement>(null);
  const closeDrawerButtonRef = useRef<HTMLButtonElement>(null);
  const [editingIndex, setEditingIndex] = useState<string>();
  const [isEditMode, setIsEditMode] = useState(false);

  const openDrawer = () => {
    if (drawerTriggerRef.current) {
      drawerTriggerRef.current.click();
    }
  };
  const { control } = useFormContext<companyStructureSchemaDTO>();
  const newDirectorForm = useForm<DirectorSchemaDTO>({
    defaultValues: InitDirector,
    resolver: zodResolver(directorSchema),
  });
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "directors",
  });
  const {
    append: appendShareholder,
    fields: shareholderFields,
    update: updateShareHolderFields,
  } = useFieldArray({
    control,
    name: "shareholders",
  });

  const directorData = useWatch({
    control: newDirectorForm.control,
  });

  const getFieldsMap = (director: DirectorSchemaDTO) => ({
    Name: `${director.first_name} ${director.last_name}`,
    Email: director.email,
    "Date of Birth": format(director.date_of_birth, "dd-MM-yyyy"),
  });
  const handleClickEdit = (director: DirectorSchemaDTO, index: number) => {
    setEditingIndex(String(index));
    getKeys(director).forEach((key) => {
      newDirectorForm.setValue(key, director[key]);
    });

    if (directorData.isShareholder) {
      setIsEditMode(true);
    }

    setCurrView("edit");
  };
  const handleAddNew = () => {
    if (fields[0] && fields[0].first_name === "") {
      update(0, newDirectorForm.getValues());
    } else {
      append(newDirectorForm.getValues());
    }
    if (directorData.isShareholder) {
      addDirectorAsShareholder();
      newDirectorForm.unregister("isShareholder");
    }

    setCurrView("list");
    newDirectorForm.reset();
  };
  const addDirectorAsShareholder = () => {
    if (shareholderFields[0] && shareholderFields[0].email === "") {
      updateShareHolderFields(0, {
        ...directorData,
        shareholder_type: "individual",
        ...(directorData.isShareholder && {
          stakeholder_shareholding: directorData.stakeholder_shareholding,
        }),
      } as ShareholderSchemaDTO);
    } else {
      appendShareholder({
        ...directorData,
        shareholder_type: "individual",
        ...(directorData.isShareholder && {
          stakeholder_shareholding: directorData.stakeholder_shareholding,
        }),
      } as ShareholderSchemaDTO);
    }
  };
  const handleSubmitUpdate = () => {
    if (editingIndex) {
      update(Number(editingIndex), newDirectorForm.getValues());
      setCurrView("list");
      newDirectorForm.reset();
    }
  };

  const [currView, setCurrView] = useState<DrawerViewsKeys>("form");

  const DrawerView: DrawerViews = {
    form: {
      heading: "Add director",
      view: (
        <AddDirector
          newDirectorForm={newDirectorForm}
          onSubmit={handleAddNew}
        />
      ),
      footer: (
        <Button
          type="submit"
          form={"director_form"}
          className="flex gap-[0.5rem] items-center h-[3.5rem]"
        >
          <p>Add</p>
        </Button>
      ),
    },
    list: {
      heading: "Director Information",
      view: (
        <div className="flex flex-col gap-[1.5rem] ">
          {fields.map((field, index) => {
            if (field.first_name !== "")
              return (
                <OnboardingItemCard
                  key={index}
                  item={getFieldsMap(field)}
                  onEditClick={() => {
                    handleClickEdit(field, index);
                  }}
                  onDelete={() => {
                    remove(index);
                  }}
                />
              );
          })}
        </div>
      ),
      footer: (
        <Button
          variant={"secondary"}
          type="button"
          className="flex gap-[0.5rem] items-center h-[3.5rem] "
          onClick={() => {
            setCurrView("form");
          }}
        >
          {" "}
          <p>Add Director</p>
          <PlusIcon width={16} height={16} />{" "}
        </Button>
      ),
    },
    edit: {
      heading: "Edit Director",
      view: (
        <AddDirector
          newDirectorForm={newDirectorForm}
          onSubmit={handleSubmitUpdate}
          isEditMode={isEditMode}
        />
      ),
      footer: (
        <Button
          type="submit"
          form={"director_form"}
          className="flex gap-[0.5rem] items-center h-[3.5rem]"
        >
          <p>Update</p>
        </Button>
      ),
    },
  };

  return (
    <section className={"mt-[38px]"}>
      <OnboardingAccordion
        name={"Director Information"}
        buttonLabel="Add Director"
        openFormDrawer={() => {
          newDirectorForm.reset();
          setCurrView("form");
          openDrawer();
        }}
      >
        <OnboardingAccordionContent
          type="director"
          fields={
            fields[0] && fields[0].first_name !== ""
              ? fields.map((field) => getFieldsMap(field))
              : undefined
          }
          openListDrawer={() => {
            setCurrView("list");
            openDrawer();
          }}
          onEditClick={() => {
            openDrawer();
            handleClickEdit(fields[0], 0);
          }}
          onDelete={() => {
            remove(0);
          }}
        />
      </OnboardingAccordion>
      <OnboardingItemDrawer
        triggerRef={drawerTriggerRef}
        heading={DrawerView[currView].heading}
        closeButtonRef={closeDrawerButtonRef}
        Footer={DrawerView[currView].footer}
      >
        {DrawerView[currView].view}
      </OnboardingItemDrawer>
    </section>
  );
};

export default DirectorSection;
