import { iSO4217CountryCodeDrownDownData } from "@/i18n/currency-data";
import { PlusIcon } from "@heroicons/react/20/solid";

import { useMemo, useRef, useState } from "react";

import {
  formatAmountLimitPair,
  InitPurposeOfAccount,
  volumeBands,
} from "@/data/onboarding.data";

import { FormSingleComboBox } from "@/components/form-single-combo-box";

import { Button } from "@/components/ui/button";
import { Form } from "@/components/ui/form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  SubmitHandler,
  UseFormReturn,
  useFieldArray,
  useForm,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { z } from "zod";
import { DrawerViewsKeys, DrawerViews } from "../company-structure/types";
import OnboardingAccordion from "../onboarding-accordions/onboarding-accordion";
import OnboardingAccordionContent from "../onboarding-accordions/onboarding-accordion-content";
import OnboardingItemCard from "../onboarding-accordions/onboarding-item-card";
import OnboardingItemDrawer from "../onboarding-accordions/onboarding-item-drawer";
import { ServiceSchemaDtoSchema, purposeOfAccountDtoSchema } from "./validator";

export function CurrencySwap() {
  const { control } = useFormContext<purposeOfAccountDtoSchema>();
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "currency_swap",
  });
  const drawerTriggerRef = useRef<HTMLButtonElement>(null);
  const closeDrawerButtonRef = useRef<HTMLButtonElement>(null);
  const [editingIndex, setEditingIndex] = useState<string>();

  const openDrawer = () => {
    if (drawerTriggerRef.current) {
      drawerTriggerRef.current.click();
    }
  };
  const newCurrencySwapForm = useForm<ServiceSchemaDtoSchema[0]>({
    defaultValues: InitPurposeOfAccount.business_account[0],
    resolver: zodResolver(
      z.object({
        pair: z.string().trim().min(1, "Required"),
        expected_monthly_volume: z.string().trim().min(1, "Required"),
        expected_annual_volume: z.string().trim().min(1, "Required"),
      })
    ),
  });
  const getFieldsMap = (service: ServiceSchemaDtoSchema[0]) => {
    return {
      "Desired currency pair": service.pair,
      "Expected Monthly Volumes": formatAmountLimitPair({
        value: service.expected_monthly_volume,
        currency: service.pair.split("/")[1],
      }),
      "Expected Annual Volumes": formatAmountLimitPair({
        value: service.expected_annual_volume,
        currency: service.pair.split("/")[1],
      }),
    };
  };
  const handleClickEdit = (
    service: ServiceSchemaDtoSchema[0],
    index: number
  ) => {
    setEditingIndex(String(index));
    newCurrencySwapForm.setValue("pair", service.pair);
    newCurrencySwapForm.setValue(
      "expected_annual_volume",
      service.expected_annual_volume
    );
    newCurrencySwapForm.setValue(
      "expected_monthly_volume",
      service.expected_monthly_volume
    );

    setCurrView("edit");
  };
  const handleAddNew = () => {
    if (fields[0] && fields[0].pair === "") {
      update(0, newCurrencySwapForm.getValues());
    } else {
      append(newCurrencySwapForm.getValues());
    }
    setCurrView("list");
    newCurrencySwapForm.reset();
  };
  const handleSubmitUpdate = () => {
    // TODO: fix falsy issue for first item.
    if (editingIndex) {
      update(Number(editingIndex), newCurrencySwapForm.getValues());
      setCurrView("list");
      newCurrencySwapForm.reset();
    }
  };

  const [currView, setCurrView] = useState<DrawerViewsKeys>("form");
  const DrawerView: DrawerViews = {
    form: {
      heading: "Add currency",
      view: (
        <Pair
          newCurrencySwapForm={newCurrencySwapForm}
          onSubmit={handleAddNew}
        />
      ),
      footer: (
        <Button
          type="submit"
          form={"currency_swap_form"}
          className="flex gap-[0.5rem] items-center h-[3.5rem]"
        >
          <p>Add</p>
        </Button>
      ),
    },
    list: {
      heading: "Business Account",
      view: (
        <div className="flex flex-col gap-[1.5rem] max-h-[80dvh] overflow-y-scroll">
          {fields.map((field, index) => {
            if (field.pair !== "")
              return (
                <OnboardingItemCard
                  key={index}
                  item={getFieldsMap(field)}
                  onEditClick={() => {
                    handleClickEdit(field, index);
                  }}
                  onDelete={() => {
                    remove(index);
                  }}
                />
              );
          })}
        </div>
      ),
      footer: (
        <Button
          variant={"secondary"}
          type="button"
          className="flex gap-[0.5rem] items-center h-[3.5rem] "
          onClick={() => {
            setCurrView("form");
          }}
        >
          {" "}
          <p>Add currency</p>
          <PlusIcon width={16} height={16} />{" "}
        </Button>
      ),
    },
    edit: {
      heading: "Edit currency",
      view: (
        <Pair
          newCurrencySwapForm={newCurrencySwapForm}
          onSubmit={handleSubmitUpdate}
        />
      ),

      footer: (
        <Button
          type="submit"
          form={"currency_swap_form"}
          className="flex gap-[0.5rem] items-center h-[3.5rem]"
        >
          <p>Update</p>
        </Button>
      ),
    },
  };
  return (
    <section className={"mt-[38px]"}>
      <OnboardingAccordion
        name={"Currency Swap"}
        buttonLabel="Add Currency"
        openFormDrawer={() => {
          newCurrencySwapForm.reset();
          setCurrView("form");
          openDrawer();
        }}
      >
        <OnboardingAccordionContent
          type="currency"
          fields={
            fields[0] && fields[0].pair !== ""
              ? fields.map((field) => getFieldsMap(field))
              : undefined
          }
          openListDrawer={() => {
            setCurrView("list");
            openDrawer();
          }}
          onEditClick={() => {
            openDrawer();
            handleClickEdit(fields[0], 0);
          }}
          onDelete={() => {
            remove(0);
          }}
        />
      </OnboardingAccordion>
      <OnboardingItemDrawer
        triggerRef={drawerTriggerRef}
        heading={DrawerView[currView].heading}
        closeButtonRef={closeDrawerButtonRef}
        Footer={DrawerView[currView].footer}
      >
        {DrawerView[currView].view}
      </OnboardingItemDrawer>
    </section>
  );
}

const Pair = ({
  newCurrencySwapForm,
  onSubmit,
}: {
  newCurrencySwapForm: UseFormReturn<ServiceSchemaDtoSchema[0]>;
  onSubmit: SubmitHandler<ServiceSchemaDtoSchema[0]>;
}) => {
  // const { control } = useFormContext();
  // const { currency_swap } = useWatch<purposeOfAccountDtoSchema>({ control });
  const currency_swap = useWatch({
    control: newCurrencySwapForm.control,
  });

  const currency = useMemo(() => {
    if (currency_swap) return currency_swap.pair?.split("/")[1];
    return "";
  }, [currency_swap]);

  return (
    <Form {...newCurrencySwapForm}>
      <form
        id={"currency_swap_form"}
        onSubmit={(e) => {
          e.stopPropagation();
          newCurrencySwapForm.handleSubmit(onSubmit)(e);
        }}
        className="pb-[1.5rem] flex flex-col gap-[24px]"
      >
        <FormSingleComboBox
          label="Desired currency pair"
          control={newCurrencySwapForm.control}
          placeholder=""
          name={`pair`}
          data={iSO4217CountryCodeDrownDownData}
        />

        <FormSingleComboBox
          label={`Expected Monthly Volumes ${currency ? `(${currency})` : ""}`}
          control={newCurrencySwapForm.control}
          name={`expected_monthly_volume`}
          data={volumeBands(currency)}
        />
        <FormSingleComboBox
          label={`Expected Annual Volumes ${currency ? `(${currency})` : ""}`}
          control={newCurrencySwapForm.control}
          name={`expected_annual_volume`}
          data={volumeBands(currency)}
        />
      </form>
    </Form>
  );
};
