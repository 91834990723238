import { useRouterState } from "@tanstack/react-router";

export function useRouteName() {
  return useRouterState({
    select: (state) => {
      const pathname = state.location.pathname;
      const routes = pathname.split("/");
      return routes[routes.length - 1];
    },
  });
}
