import { User } from "@/domains/users";
import React, { useRef } from "react";
import { StoreApi, create, useStore } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

export interface AuthState {
  isAuthenticated: boolean;
  user: User.Doc | null;
  login: (user: User.Doc) => void;
  setUser: (user: User.Doc) => void;
  logout: () => void;
}

export const AuthStoreContext = React.createContext<StoreApi<AuthState> | null>(
  null
);

// eslint-disable-next-line react-refresh/only-export-components
export const authStore = create<AuthState>()(
  persist(
    (set) => ({
      isAuthenticated: false,
      user: null,
      login(user) {
        set({ user, isAuthenticated: true });
      },
      setUser(user) {
        set({ user });
      },
      logout: () => {
        set({ user: null, isAuthenticated: false });
      },
    }),
    {
      name: "auth-storage",
      storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
    }
  )
);

export const AuthStoreProvider = ({ children }: React.PropsWithChildren) => {
  const storeRef = useRef<StoreApi<AuthState>>();
  if (!storeRef.current) {
    storeRef.current = authStore;
  }
  return (
    <AuthStoreContext.Provider value={storeRef.current}>
      {children}
    </AuthStoreContext.Provider>
  );
};

export function useAuthStore(): AuthState;
export function useAuthStore<T>(selector: (state: AuthState) => T): T;
// eslint-disable-next-line react-refresh/only-export-components
export function useAuthStore<T>(selector?: (state: AuthState) => T) {
  const store = React.useContext(AuthStoreContext);
  if (!store) {
    throw new Error("Missing AuthStoreProvider");
  }
  return useStore(store, selector!);
}

// eslint-disable-next-line react-refresh/only-export-components
export function useAuthUser() {
  return useAuthStore((s) => s.user);
}
