import { api } from "@/config/wrench";
import { APIResponse } from "../response";
import { User } from "./types";

const usersApi = api.url("/users");
export function signUp(params: User.SignUpDTO): Promise<User.SignupResponse> {
  return usersApi.post(params).json<User.SignupResponse>();
}

export function verifyEmail(
  params: User.VerifyEmailDTO
): Promise<User.SignupResponse> {
  return usersApi.url("/verify-email").post(params).json<APIResponse>();
}

export function getVerificationEmail(): Promise<User.SignupResponse> {
  return usersApi.get("/verify-email").json<APIResponse>();
}

export function updateUserDetails(
  params: User.UpdateUserProfileDTO
): Promise<User.UpdateUserResponse> {
  return usersApi.put(params).json<Promise<User.UpdateUserResponse>>();
}
