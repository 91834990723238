import { ISO3166Alpha2 } from "@/i18n/countries-data";
import { Control, FieldPath, FieldValues } from "react-hook-form";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "./ui/form";
import { PhoneInput } from "./ui/phone-input";

export function FormPhoneInput<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  label,
  defaultCountry,
}: {
  control: Control<TFieldValues>;
  name: TName;
  label?: React.ReactNode;
  defaultCountry?: ISO3166Alpha2;
}) {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem>
          <FormLabel className="text-lg font-medium">{label}</FormLabel>
          <FormControl>
            <PhoneInput
              // @ts-expect-error the countries are actually the same
              defaultCountry={defaultCountry}
              className="w-[500px]"
              {...field}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
