import React, { Dispatch, SetStateAction } from "react";

// a lot of logic in the app requires that we hold a boolean state and toggle it on and off
// this just gives us an abstraction to doing that
export function useToggle(value = false): [
  boolean,
  {
    _set: Dispatch<SetStateAction<boolean>>;
    toggle: Noop;
    on: Noop;
    off: Noop;
  }
] {
  const [state, setState] = React.useState(value);

  const toggle = () => setState((v) => !v);
  const on = () => setState(true);
  const off = () => setState(false);

  return React.useMemo(
    () => [
      state,
      {
        _set: setState,
        toggle,
        on,
        off,
      },
    ],
    [state]
  );
}
