import { CountryCodeAlpha2 } from "@/i18n/countries-data";
import { APIResponse } from "../response";
import { Settings } from "../settings/types";

export type AccountType = "individual" | "corporate";

export const Individual: AccountType = "individual";
export const Corporate: AccountType = "corporate";

export type AffiliateType = "shareholder" | "director";

export const Shareholder: AffiliateType = "shareholder";
export const Director: AffiliateType = "director";

export type OrganizationServicesType =
  | "cross_border"
  | "currency_swap"
  | "business_account"
  | "collections"
  | "cash_management";

export const CrossBorder: OrganizationServicesType = "cross_border";
export const Swap: OrganizationServicesType = "currency_swap";
export const BusinessAccount: OrganizationServicesType = "business_account";
export const Collections: OrganizationServicesType = "collections";
export const CashManagement: OrganizationServicesType = "cash_management";

export namespace Organization {
  // Define AccountType as a string literal type.
  export type AccountType = "individual" | "corporate";

  // Constants for AccountType.
  export const Individual: AccountType = "individual";
  export const Corporate: AccountType = "corporate";

  // Define AffiliateType as a string literal type.
  export type AffiliateType = "shareholder" | "director";

  // Constants for AffiliateType.
  export const ShareholderType: AffiliateType = "shareholder";
  export const DirectorType: AffiliateType = "director";

  // Define OrganizationServicesType as a string literal type.
  export type OrganizationServicesType =
    | "cross_border"
    | "currency_swap"
    | "business_account"
    | "collections"
    | "cash_management";

  // Constants for OrganizationServicesType.
  export const CrossBorder: OrganizationServicesType = "cross_border";
  export const Swap: OrganizationServicesType = "currency_swap";
  export const BusinessAccount: OrganizationServicesType = "business_account";
  export const Collections: OrganizationServicesType = "collections";
  export const CashManagement: OrganizationServicesType = "cash_management";

  export interface Doc {
    id: string;
    company_name: string;
    trading_name: string;
    email_address: string;
    rc_number: string;
    registration_date: string;
    country_of_incorporation: string;
    legal_address: Settings.Address;
    operations_address: Settings.Address;
    phone_number: string;
    tax_id: string;
    created_at: string;
    created_by: string;
    metadata: Metadata;
    status: Settings.OrganizationStatus;
  }

  export interface Metadata {
    requires_license: boolean;
    client_type: string[];
    inflow_countries: string[];
    outflow_countries: string[];
    funds_source: string[];
    funds_source_description: string;
  }

  export type OrganizationResponse = APIResponse<{
    organization: Organization.Doc;
    documents: Document | null;
    services: OrganizationService[] | null;
    affiliates: Affiliate[] | null;
  }>;

  export type GetCACResponse = APIResponse<{
    response: Response;
    provider: string;
  }>;

  export interface CreateBusinessDTO {
    company_name: string;
    country_of_incorporation: string;
    email_address: string;
    legal_address: Settings.Address;
    operations_address: Settings.Address;
    phone_number: string;
    rc_number: string;
    registration_date: string;
    tax_id?: string;
    trading_name: string;
    requires_license: boolean;
    client_type: string[];
    inflow_countries: string[];
    outflow_countries: string[];
    funds_source: string[];
    position_held: string;
    funds_source_description: string;
    operations_description: string;
  }
  export interface PurposeOfAccountDTO {
    id: string;
    cross_border?: CrossBorder[];
    currency_swap?: Swap[];
    business_account?: BusinessAccount[];
  }

  export interface Affiliate {
    id?: string | null;
    organization_id: string;
    email_address: string;
    ownership_percentage?: number;
    account_type: AccountType;
    type: AffiliateType;
    name?: string | null;
    first_name?: string | null;
    last_name?: string | null;
    date_of_birth: string;
    address: Settings.Address;
    nationality: string;
    phone_number: string;

    proof_of_address_id?: string | null;
    verification_id?: string | null;
    incorporation_certificate_id?: string | null;
    articles_id?: string | null;
    directors_register_id?: string | null;
    shareholders_register_id?: string | null;

    proof_of_address?: Settings.InternalFile | null;
    verification?: Settings.InternalFile | null;
    incorporation_certificate?: Settings.InternalFile | null;
    articles?: Settings.InternalFile | null;
    directors_register?: Settings.InternalFile | null;
    shareholders_register?: Settings.InternalFile | null;
  }

  export interface SetCompanyStructureDTO {
    directors: Affiliate[];
    shareholders: Affiliate[];
  }

  export interface SetDocumentsDTO {
    proof_of_address_id: string;
    incorporation_certificate_id: string;
    articles_id: string;
    directors_register_id: string;
    shareholders_register_id: string;
    operating_license_id?: string;
  }

  export interface Director {
    id: string;
    organization_id: string;
    email_address: string;
    ownership_percentage: number;
    account_type: AccountType;
    type: "director";
    name: string;
    first_name: string;
    last_name: string;
    date_of_birth: string;
    address: Settings.Address;
    nationality: string;
    phone_number: string;
    proof_of_address_id: string;
    verification_id: string;
    // files
    verification?: Settings.InternalFile;
    proof_of_address?: Settings.InternalFile;
    stakeholder_shareholding?: number;
  }

  export interface Shareholder {
    id: string;
    organization_id: string;
    email_address: string;
    ownership_percentage: number;
    account_type: AccountType;
    type: "shareholder";
    name?: string;
    first_name?: string;
    last_name?: string;
    date_of_birth: string;
    address: Settings.Address;
    nationality: string;
    phone_number: string;
    verification_id?: string;
    proof_of_address_id?: string;
    incorporation_certificate_id?: string;
    articles_id?: string;
    directors_register_id?: string;
    shareholders_register_id?: string;

    proof_of_address?: Settings.InternalFile;
    verification?: Settings.InternalFile;
    incorporation_certificate?: Settings.InternalFile;
    articles?: Settings.InternalFile;
    directors_register?: Settings.InternalFile;
    shareholders_register?: Settings.InternalFile;
  }

  export type ServicesResponse = APIResponse<OrganizationService[]>;
  export type StructureResponse = APIResponse<
    (Director | Shareholder)[] | null
  >;

  export type Document = {
    id: string;
    created_at: string;
    organization_id: string;
    proof_of_address_id: string;
    incorporation_certificate_id: string;
    operating_license_id: string;
    shareholders_register_id: string;
    directors_register_id: string;
    articles_id: string;
    organization: Settings.InternalFile;
    proof_of_address: Settings.InternalFile;
    incorporation_certificate: Settings.InternalFile;
    operating_license: Settings.InternalFile;
    shareholders_register: Settings.InternalFile;
    directors_register: Settings.InternalFile;
    articles: Settings.InternalFile;
    agree_to_terms: boolean;
    correct_documents: boolean;
  };

  export type DocumentsResponse = APIResponse<Document>;

  export type OrganizationService =
    | {
        id: string;
        created_at: string;
        organization_id: string;
        type: "cross_border";
        details: {
          currency_pair: CurrencyPair;
          expected_annual_volume: ExpectedAnnualVolume;
          expected_monthly_volume: ExpectedMonthlyVolume;
        }[];
      }
    | {
        id: string;
        created_at: string;
        organization_id: string;
        type: "currency_swap";
        details: {
          currency_pair: CurrencyPair;
          expected_annual_volume: ExpectedAnnualVolume;
          expected_monthly_volume: ExpectedMonthlyVolume;
        }[];
      }
    | {
        id: string;
        created_at: string;
        organization_id: string;
        type: "business_account";
        details: {
          currency: string;
          expected_annual_volume: ExpectedAnnualVolume;
          expected_monthly_volume: ExpectedMonthlyVolume;
        }[];
      };

  export type NGNAccount = {
    country: string;
    account_name: string;
    account_number: string;
    bank_name: string;
    bank_code: string;
    currency: "NGN";
  };
  // USD START
  type USDAccountBase = {
    currency: "USD";
    country: string;
    account_name: string;
    bank_name: string;
  };

  type USDAccountACH = USDAccountBase & {
    type: "ach";
    account_routing_number: string;
    account_number: string;
  };

  type USDAccountNonSWIFT = USDAccountBase & {
    type: "swift";
    swift: string;
    iban: string;
  };
  type USDAccountNonFEDWIRE = USDAccountBase & {
    type: "fed-wire";
    account_routing_number: string;
    account_number: string;
  };

  export type USDAccount =
    | USDAccountACH
    | USDAccountNonSWIFT
    | USDAccountNonFEDWIRE;
  // USD end

  // GBP START
  type GBPAccountBase = {
    country: string;
    account_name: string;
    bank_name: string;
    currency: "GBP";
  };

  type GBPAccountGB = GBPAccountBase & {
    country: "GB";
    account_number: string;
    sort_code: string;
  };

  type GBPAccountNonGB = GBPAccountBase & {
    country: Exclude<CountryCodeAlpha2, "GB">;
    swift: string;
    iban: string;
  };

  export type GBPAccount = GBPAccountGB | GBPAccountNonGB;
  // GBP end

  // EUR START
  type EURAccountBase = {
    country: string;
    account_name: string;
    bank_name: string;
    currency: "EUR";
  };

  type EURAccountGB = EURAccountBase & {
    country: "GB";
    account_number: string;
    sort_code: string;
  };

  type EURAccountNonGB = EURAccountBase & {
    country: Exclude<CountryCodeAlpha2, "GB">;
    swift: string;
    iban: string;
  };

  export type EURAccount = EURAccountGB | EURAccountNonGB;
  // EUR end

  export type SettlementAccount =
    | GBPAccount
    | USDAccount
    | NGNAccount
    | EURAccount;

  export type SettlementAccountsDTO = {
    accounts: SettlementAccount[];
  };

  export type SettlementAccountsResponse = APIResponse<SettlementAccount[]>;
}

export type CreateBusinessResponse = APIResponse<{
  account: Account.Doc;
  organization: Organization.Doc;
}>;

export interface CurrencyPair {
  source_currency: string;
  target_currency: string;
}

export interface ExpectedAnnualVolume {
  lower_limit: number;
  upper_limit: number;
}

export interface ExpectedMonthlyVolume {
  lower_limit: number;
  upper_limit: number;
}

// accounts to be moved out
export namespace Account {
  export type Doc = {
    id: string;
    created_at: string;
    type: AccountType;
    status: Settings.ActivityStatus;
    user_id: string;
    organization_id?: string;
    position_held: string;
    iam_role: string;
    ownership_percentage: number;
    verification_status: Settings.VerificationStatus;
    organization: Organization.Doc;
  };
}

export interface CrossBorder {
  currency_pair: CurrencyPair;
  expected_monthly_volume: ExpectedMonthlyVolume;
  expected_annual_volume: ExpectedAnnualVolume;
}

export interface CurrencyPair {
  source_currency: string;
  target_currency: string;
}
export interface ExpectedMonthlyVolume {
  lower_limit: number;
  upper_limit: number;
}

export interface ExpectedAnnualVolume {
  lower_limit: number;
  upper_limit: number;
}

export interface Swap {
  currency_pair: CurrencyPair;
  expected_monthly_volume: ExpectedMonthlyVolume;
  expected_annual_volume: ExpectedAnnualVolume;
}

export interface BusinessAccount {
  currency: string;
  expected_monthly_volume: ExpectedMonthlyVolume;
  expected_annual_volume: ExpectedAnnualVolume;
}

interface Response {
  entity: Entity;
}

interface Entity {
  Affiliates: DojahAffiliate[];
  Branch_Address: string;
  City: string;
  Classification: string;
  Date_of_Registration: string;
  Email: string;
  Head_Office_Address: string;
  LGA: string;
  Name_of_Company: string;
  Number_of_Affiliates: string;
  RC_Number: string;
  Share_capital: string;
  Share_capital_in_words: string;
  State: string;
  Status: string;
  Type_of_Company: string;
}

interface DojahAffiliate {
  accreditationnumber: unknown;
  address: string;
  affiliateType: string;
  city: string;
  companyId: number;
  companyName: unknown;
  companyRcNumber: unknown;
  corporateName: unknown;
  corporateRcNumber: unknown;
  corporationName: unknown;
  countryName: string;
  dateOfAppointment: unknown;
  dateOfBirth: unknown;
  dateOfTermination: unknown;
  email: string;
  firstname: string;
  formerName: unknown;
  formerNationality: unknown;
  formerSurname: unknown;
  gender: unknown;
  isChairman: boolean;
  isCorporate: boolean;
  lga: unknown;
  name: string;
  nationality: unknown;
  occupation: unknown;
  otherDirectorshipDetails: unknown;
  othername: string;
  phoneNumber: string;
  postcode: unknown;
  rcNumber: unknown;
  searchScore: unknown;
  shareAllotted: unknown;
  shareType: unknown;
  state: string;
  status: string;
  streetNumber: unknown;
  surname: string;
}
