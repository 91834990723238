import { DialogTrigger } from "@/components/ui/dialog";
import EditIcon from "@/icons/edit";
import { Trash } from "@/icons/trash";
import { Dialog } from "@radix-ui/react-dialog";
import { DeleteOnboardingItemDialog } from "../delete-onboarding-item-modal";

const OnboardingItemCard = ({
  item,
  onEditClick,
  onDelete,
}: {
  item: Record<string, string | undefined>;
  onEditClick: () => void;
  onDelete: () => void;
}) => {
  return (
    <div className="bg-grey-card rounded-[0.375rem] py-[1.125rem] px-[0.75rem] flex items-start justify-between w-full min-h-[10.875rem]">
      <div className=" flex-1 space-y-[0.75rem]">
        {Object.keys(item).map((key) => (
          <div key={key} className="flex flex-col gap-[0.375rem]">
            <p className="font-inter font-[500] text-[0.75rem] leading-[0.9075rem] text-label">
              {key}
            </p>
            <p className="font-inter font-[700] text-[0.875rem] leading-[1.05875rem] text-label">
              {item[key] ? item[key] : ""}
            </p>
          </div>
        ))}
      </div>
      <div className="flex gap-[1.5rem] items-center">
        <EditIcon
          width={24}
          height={24}
          onClick={onEditClick}
          className="cursor-pointer"
        />
        <Dialog>
          <DialogTrigger>
            <Trash width={24} height={24} className="cursor-pointer" />
          </DialogTrigger>
          <DeleteOnboardingItemDialog onConfirm={onDelete} />
        </Dialog>
      </div>
    </div>
  );
};

export default OnboardingItemCard;
