import SignOutModal from "@/components/sign-out-modal";
import { Button } from "@/components/ui/button";
import { DialogTrigger } from "@/components/ui/dialog";
import { Header } from "@/components/ui/header";
import { LoginIcon } from "@/icons/login-in";
import { useAuthUser } from "@/internals/auth";
import { useRedirectEffect } from "@/internals/hooks/useRedirectEffect";
import { validateRedirectSearch } from "@/internals/validators/route.validation";
import { zodResolver } from "@hookform/resolvers/zod";
import { Dialog } from "@radix-ui/react-dialog";

import { Form } from "@/components/ui/form";

import { FormInputOTPForm } from "@/components/form-input-otp";
import { sessionQueryOptions } from "@/domains/sessions";
import { useGetVerificationEmail, useVerifyEmail } from "@/domains/users";
import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute, useRouter } from "@tanstack/react-router";
import React from "react";
import { useForm } from "react-hook-form";
import { useCountdown } from "usehooks-ts";
import { z } from "zod";

export const Route = createFileRoute("/verify-email")({
  component: IdentityVerification,
  validateSearch: validateRedirectSearch,
  loader: (opts) =>
    opts.context.queryClient.ensureQueryData(
      sessionQueryOptions({ abortController: opts.abortController })
    ),
});

const FormSchema = z.object({
  code: z.string().min(6, {
    message: "Your one-time password must be 6 characters.",
  }),
});

function IdentityVerification() {
  const { refetch } = useSuspenseQuery(sessionQueryOptions());
  const router = useRouter();

  const search = Route.useSearch();
  const verifyEmail = useVerifyEmail();
  const getVerificationEmail = useGetVerificationEmail();
  const user = useAuthUser();

  const INITIAL_COUNT = 60;
  const [count, { startCountdown, resetCountdown }] = useCountdown({
    countStart: INITIAL_COUNT,
  });

  useRedirectEffect(
    user?.email_verification_status === "verified",
    search.redirect
  );

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      code: "",
    },
  });

  React.useEffect(() => {
    if (count === 0) {
      resetCountdown();
    }
  }, [count, resetCountdown]);

  function onSubmit(values: z.infer<typeof FormSchema>) {
    verifyEmail.mutate(values, {
      onSuccess: () => {
        refetch();
        router.navigate({ to: "/setup-user", search: search });
      },
    });
  }

  const reSendOTP = () => {
    startCountdown();
    getVerificationEmail.mutate(undefined);
  };

  if (!user) {
    return null;
  }

  return (
    <div className="flex flex-col justify-center items-center border h-screen pb-[2.875rem]">
      <div className="flex-1 w-full md:w-[31.25rem] space-y-[2.375rem] flex flex-col justify-center">
        <Header
          header="Verify Email"
          headerClassName="capitalize text-center"
          subtitle={`Please check your email (${user.email_address}) for OTP`}
          subHeaderClassName="text-center"
        />
        <div className="flex flex-col justify-center">
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <div className="w-full flex space-y-6 items-center justify-center mb-10">
                <FormInputOTPForm name="code" control={form.control} />
              </div>

              <Button
                type="button"
                variant="link"
                className="justify-start mb-2 px-0"
                onClick={reSendOTP}
                loading={getVerificationEmail.isPending}
              >
                Resend OTP {count != INITIAL_COUNT ? count : null}
              </Button>

              <Button type="submit" loading={verifyEmail.isPending}>
                Submit
              </Button>
            </form>
          </Form>
        </div>
      </div>
      <Dialog>
        <DialogTrigger asChild>
          <div className="flex  w-full justify-center cursor-pointer">
            <div className="flex gap-x-2  text-text-input-placeholder">
              <LoginIcon />
              <p className="text-[1rem] text-text-input-placeholder">
                Sign Out
              </p>
            </div>
          </div>
        </DialogTrigger>
        <SignOutModal />
      </Dialog>
    </div>
  );
}
