import { UseFormReturn, useWatch } from "react-hook-form";
import { NgnAccountSchemaDto } from "./validators";
import { FormInput } from "@/components/form-input";
import { Form } from "@/components/ui/form";
import { Settings } from "@/domains/settings/types";
import { FormSingleComboBox } from "@/components/form-single-combo-box";
import { useEffect } from "react";
import { validateBankAccountOptions } from "@/domains/settings";
import { toast } from "sonner";
import { useQuery } from "@tanstack/react-query";

const NGNAccount = ({
  newNgnForm,
  onSubmit,
  banksList,
}: {
  newNgnForm: UseFormReturn<NgnAccountSchemaDto>;
  onSubmit: () => void;
  banksList: Settings.Banks["NG"];
}) => {
  const bank_details = useWatch({
    control: newNgnForm.control,
  });

  const validateBankAccount = useQuery(
    validateBankAccountOptions({
      country: "NG",
      account_number:
        bank_details.account_number?.length === 10
          ? bank_details.account_number
          : "",
      bank_code: bank_details.bank_code as string,
    })
  );

  useEffect(() => {
    if (validateBankAccount.isSuccess) {
      newNgnForm.setValue(
        "account_name",
        validateBankAccount.data.account_name
      );
    }
    if (validateBankAccount.isError) {
      toast(validateBankAccount.error.message);
    }
  }, [validateBankAccount.isSuccess, validateBankAccount.isError]);

  return (
    <Form {...newNgnForm}>
      <form
        onSubmit={(e) => {
          e.stopPropagation();
          newNgnForm.handleSubmit(onSubmit)(e);
        }}
        id={"ngn_form"}
        className="pb-[1.5rem] flex flex-col gap-[24px]"
      >
        <div>
          <div className="flex flex-col gap-[1.5rem]">
            <FormInput
              disabled
              name={`country`}
              label="Country"
              className="hidden"
              labelClass="hidden"
              control={newNgnForm.control}
            />

            <FormInput
              name={`account_number`}
              label="Account Number"
              control={newNgnForm.control}
            />

            <FormSingleComboBox
              name={"bank_code"}
              label={"Bank Name"}
              control={newNgnForm.control}
              data={banksList?.map((bank) => ({
                label: bank.name,
                value: bank.code,
              }))}
              onValueChange={(bank_code) =>
                newNgnForm.setValue(
                  "bank_name",
                  banksList.find((bank) => bank.code === bank_code)
                    ?.name as string
                )
              }
            />
            <FormInput
              name={`account_name`}
              label="Account Name"
              disabled
              control={newNgnForm.control}
            />
            <FormInput
              disabled
              name={`currency`}
              label="Currency"
              className="hidden"
              labelClass="hidden"
              control={newNgnForm.control}
            />
          </div>
        </div>
      </form>
    </Form>
  );
};

export default NGNAccount;
