import { Control, FieldPath, FieldValues } from "react-hook-form";
import { CheckboxProps, CheckboxWithLabel } from "./ui/checkbox-with-label";
import { FormControl, FormField, FormItem } from "./ui/form";

interface FormCheckboxProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends CheckboxProps {
  control: Control<TFieldValues>;
  name: TName;
}

const FormCheckbox = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  props: FormCheckboxProps<TFieldValues, TName>
) => {
  const { control, name, label, ...rest } = props;

  return (
    <FormField
      control={control}
      name={name}
      render={({ field, fieldState }) => {
        return (
          <FormItem className="w-full ">
            {/* <FormLabel className="text-lg font-medium">{label}</FormLabel> */}
            <FormControl>
              <CheckboxWithLabel
                className={fieldState.error && "border-destructive"}
                {...field}
                {...rest}
                label={label}
                onCheckedChange={field.onChange}
              />
            </FormControl>
          </FormItem>
        );
      }}
    />
  );
};

export default FormCheckbox;
