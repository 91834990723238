import { __DEV__ } from "@/config/env";
import { Organization } from "@/domains/organizations/types";
import { Settings } from "@/domains/settings/types";

export const booleanSelectData = [
  {
    label: "Yes",
    value: "true",
  },
  {
    label: "No",
    value: "false",
  },
];

export const RC_NUMBER_MAX_LENGTH = 10;

export const rawVolumeBands = [
  [50_000, 100_000],
  [100_000, 500_000],
  [1_000_000, 100_000_000],
  [100_000_000, 1_000_000_000],
];

const nairaVolumeBands = [
  [1_000_000, 50_000_000],
  [50_000_000, 250_000_000],
  [250_000_000, 1_000_000_000],
  [1_000_000_000, 1_000_000_000_000],
];

export const volumeBands = (currency?: string) => {
  const bands = currency === "NGN" ? nairaVolumeBands : rawVolumeBands;
  return bands.map((band) => ({
    label: formatAmountLimit(
      { lowerLimit: band[0], upperLimit: band[1] },
      currency
    ),
    value: `${band[0]}-${band[1]}`,
  }));
};

export function formatAmountLimit(
  limit: Settings.AmountLimit,
  currency = "USD"
): string {
  let locale = "en";
  if (currency === "NGN") {
    locale = "en-NG";
  }
  const format = Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency ? currency : "USD",
  }).format;

  return `${format(limit.lowerLimit)} - ${format(limit.upperLimit)}`;
}

export function formatAmountLimitPair({
  currency,
  value,
}: {
  currency: string;
  value: string;
}) {
  return formatAmountLimit(
    {
      lowerLimit: Number(value.split("-")[0]),
      upperLimit: Number(value.split("-")[1]),
    },
    currency
  );
}

export const ONBOARDING_ITEMS = {
  "purpose-of-account": {
    key: "purpose-of-account",
    level: 1,
    title: "Purpose of account",
    subTitle: "Tell us about your intended transactions",
    sideNavTitle: "Purpose of Account",
  },
  "company-structure": {
    key: "company-structure",
    level: 2,
    title: "Company Structure",
    subTitle: "How is the company structured",
    sideNavTitle: "Company Structure",
  },
  "supporting-documents": {
    key: "supporting-documents",
    level: 3,
    title: "Supporting Documents",
    subTitle: "Supporting documents for onboarding",
    sideNavTitle: "Supporting Documents",
  },
  "settlement-accounts": {
    key: "settlement-accounts",
    level: 4,
    title: "Settlement Accounts",
    subTitle: "Add settlement accounts",
    sideNavTitle: "Settlement Accounts",
  },
} as const;

export type OnboardingScreens = keyof typeof ONBOARDING_ITEMS;

export const Licenses = [
  {
    value: "true",
    label: "Yes",
  },
  {
    value: "false",
    label: "No",
  },
];

export const Clients = [
  {
    label: "Both",
    value: "both",
  },
  {
    label: "Companies",
    value: String(Organization.Corporate),
  },
  {
    label: "Individual",
    value: String(Organization.Individual),
  },
];

export const SourceOfFunds = [
  "Gainful Employment",
  "Trading",
  "Business Capital",
  "Crowdfunding",
  "Donations/Grants/Subsidies",
  "Debt Capital",
  "Equity Capital",
  "Retained Earnings (Profits)",
  "Loan",
  "Venture Capital",
  "Angel Investment",
  "Personal Savings",
].map((i) => ({
  label: i,
  value: i,
}));

export const addressDefault = {
  address: "",
};

export const InitOnboardingProfile = !__DEV__
  ? {
      phone_number: "",
      rc_number: "",
      company_name: "",
      email_address: "",
      legal_address: addressDefault,
      operations_address: addressDefault,
      client_type: "",
      funds_source: "",
      outflow_countries: [],
      inflow_countries: [],
      funds_source_description: "",
      requires_license: "false",
      country_of_incorporation: "",
      trading_name: "",
      tax_id: "",
      operations_description: "",
      position_in_company: "",
    }
  : {
      rc_number: "129292",
      company_name: "KENSAROWIRO LIMITED",
      email_address: "connect@kswira.com.ng",
      requires_license: "true",
      funds_source_description:
        "siskslslksklsksiskslslksklsksiskslslksklsksiskslslksklsksiskslslksklsksiskslslksklsk",
      operations_description:
        "siskslslksklsksiskslslksklsksiskslslksklsksiskslslksklsksiskslslksklsksiskslslksklsk",
      legal_address: {
        address: "43 isaac john street ikeja",
      },
      operations_address: {
        address: "43 isaac john street ikeja",
        state: "Lagos",
        city: "Ojota",
        zip_code: "105102",
        country: "NG",
      },
      client_type: "both",
      funds_source: "Retained Earnings (Profits)",
      outflow_countries: ["AM", "AI"],
      inflow_countries: ["AD", "AE"],
      country_of_incorporation: "AL",
      trading_name: "KENSAROWIRO LIMITED",
      tax_id: "",
      registration_date: new Date("2017-04-17T13:33:22.770Z"),
      phone_number: "+2349067034563",
      position_in_company: "",
    };

export const InitPurposeOfAccount = {
  cross_border: [
    {
      pair: "",
      expected_monthly_volume: "",
      expected_annual_volume: "",
    },
  ],

  currency_swap: [
    {
      pair: "",
      expected_monthly_volume: "",
      expected_annual_volume: "",
    },
  ],

  business_account: [
    {
      pair: "",
      expected_monthly_volume: "",
      expected_annual_volume: "",
    },
  ],
  cash_management: [
    {
      pair: "",
      expected_monthly_volume: "",
      expected_annual_volume: "",
    },
  ],
};

export const InitFile = {
  id: "",
  name: "",
  path: "",
  url: "",
};

export const InitShareholders = {
  id: null,
  shareholder_type: "corporate",
  name: "",
  date_of_birth: new Date(),
  address: addressDefault,
  stakeholder_shareholding: "",
  email: "",
  phone_number: "",
  register: InitFile,
  memorandum: InitFile,
  incorporation_certificate: InitFile,
  register_of_directors: InitFile,
  verification: InitFile,
  nationality: "NG",
} as const;

export const InitDirector = {
  id: null,
  first_name: "",
  last_name: "",
  date_of_birth: new Date(),
  address: addressDefault,
  email: "",
  verification: InitFile,
  proof_of_address: InitFile,
  nationality: "NG",
  phone_number: "",
  isShareholder: false,
  stakeholder_shareholding: "",
} as const;

export const InitCompanyStructure = {
  position_in_company: "",
  verification: "",
  proof_of_address: "",
  first_name: "",
  last_name: "",
  date_of_birth: new Date(),
  residential_address: "",
  email: "",
  directors: [InitDirector],
  shareholders: [InitShareholders],
};

export const positionInCompany = [
  "Administrative Staff",
  "Chief Financial Officer",
  "Chief Trading Officer",
  "Director",
  "Director of Finance",
  "Member of Board Of Trustee",
  "Shareholder",
  "Sole Proprietor",
  "Treasurer",
  "Other",
].map((i) => ({
  label: i,
  value: i,
}));

export const InitSupportingDocuments = {
  incorporation_certificate: InitFile,
  memorandum: InitFile,
  register_of_shareholders: InitFile,
  register_of_directors: InitFile,
  proof_of_address: InitFile,
  business_operating_license: InitFile,
  agree_to_terms: false,
  correct_documents: false,
};

export const InitSettlementAccounts = {
  ngn_account: [
    {
      account_name: "",
      account_number: "",
      bank_name: "",
      bank_code: "",
    },
  ],
  usd_account: [
    {
      account_name: "",
      routing_number: "",
      account_type: "",
    },
  ],
  gbp_account: [
    {
      bank_name: "",
      account_name: "",
      sort_code: "",
    },
  ],
  eur_account: [
    {
      account_name: "",
      iban: "",
      code: "",
    },
  ],
};
