export const QUERY_KEYS = {} as const;
export const SESSION_MUTATION_KEYS = {
  signIn: ["sign-in"],
  forgotPassword: ["forgot-password"],
  forgotPasswordLink: ["forgot-password-link"],
} as const;

export const SESSION_QUERY_KEYS = {
  sessions: ["sessions"],
} as const;
