import { ValidationError } from "./error";

export function extractValidationErrors(validationError: ValidationError) {
  const errorMessages: Record<string, string> = {};

  function traverseErrors(errors: ValidationError, prefix: string = "") {
    for (const key in errors) {
      const error = errors[key];
      if (typeof error === "string") {
        errorMessages[`${prefix}${key}`] = error;
      } else {
        traverseErrors(error as ValidationError, `${prefix}${key}.`);
      }
    }
  }

  traverseErrors(validationError);
  return errorMessages;
}
