import { PlusIcon } from "@heroicons/react/20/solid";

import { useRef, useState } from "react";

import { InitSettlementAccounts } from "@/data/onboarding.data";

import { useFieldArray, useForm, useFormContext } from "react-hook-form";

import { Button } from "@/components/ui/button";

import { zodResolver } from "@hookform/resolvers/zod";
import OnboardingAccordion from "../onboarding-accordions/onboarding-accordion";
import OnboardingAccordionContent from "../onboarding-accordions/onboarding-accordion-content";
import OnboardingItemCard from "../onboarding-accordions/onboarding-item-card";
import OnboardingItemDrawer from "../onboarding-accordions/onboarding-item-drawer";

import { countriesDrownDownData } from "@/i18n/countries-data";
import { DrawerViewsKeys, DrawerViews } from "../company-structure/types";
import USDAccount from "./USDAccount";
import {
  SettlementAccountsSchemaDto,
  UsdAccountSchemaDto,
  usdAccountSchema,
} from "./validators";

const UsdSection = () => {
  const drawerTriggerRef = useRef<HTMLButtonElement>(null);
  const closeDrawerButtonRef = useRef<HTMLButtonElement>(null);
  const [editingIndex, setEditingIndex] = useState<string>();
  // const closeDrawer = () => {
  //   if (closeDrawerButtonRef.current) {
  //     closeDrawerButtonRef.current.click();
  //   }
  // };
  const openDrawer = () => {
    if (drawerTriggerRef.current) {
      drawerTriggerRef.current.click();
    }
  };
  const { control } = useFormContext<SettlementAccountsSchemaDto>();
  const newUsdForm = useForm<UsdAccountSchemaDto>({
    defaultValues: InitSettlementAccounts["usd_account"][0],
    resolver: zodResolver(usdAccountSchema),
  });
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "usd_account",
  });

  const getFieldsMap = (account: UsdAccountSchemaDto) => ({
    Country: countriesDrownDownData.find(
      (country) => country.value === account.country
    )?.label,

    ...(account.type === "swift" && {
      IBAN: account.iban,
      "BIC/SWIFT": account.swift,
      "Beneficiary's bank Name": account.bank_name,
    }),
    ...((account.type === "fed-wire" || account.type === "ach") && {
      "Account routing number": account.account_routing_number,
      "Account number": account.account_number,
    }),
    "Legal Name": account.account_name,
  });
  const handleClickEdit = (i: UsdAccountSchemaDto, index: number) => {
    setEditingIndex(String(index));
    Object.keys(i).forEach((key) => {
      newUsdForm.setValue(
        key as keyof UsdAccountSchemaDto,
        i[key as keyof UsdAccountSchemaDto]
      );
    });
    setCurrView("edit");
  };
  const handleAddNew = () => {
    if (fields[0] && !fields[0].country) {
      update(0, newUsdForm.getValues());
    } else {
      append(newUsdForm.getValues());
    }
    setCurrView("list");
    newUsdForm.reset();
  };
  const handleSubmitUpdate = () => {
    if (editingIndex) {
      update(Number(editingIndex), newUsdForm.getValues());
      setCurrView("list");
      newUsdForm.reset();
    }
  };

  const [currView, setCurrView] = useState<DrawerViewsKeys>("form");
  const DrawerView: DrawerViews = {
    form: {
      heading: "Add USD($) Account",
      view: <USDAccount newUsdForm={newUsdForm} onSubmit={handleAddNew} />,
      footer: (
        <Button
          type="submit"
          form={"usd_form"}
          className="flex gap-[0.5rem] items-center h-[3.5rem]"
        >
          <p>Add</p>
        </Button>
      ),
    },
    list: {
      heading: "USD($) Account details",
      view: (
        <div className="flex flex-col gap-[1.5rem] ">
          {fields.map((field, index) => {
            if (field.country)
              return (
                <OnboardingItemCard
                  key={index}
                  item={getFieldsMap(field)}
                  onEditClick={() => {
                    handleClickEdit(field, index);
                  }}
                  onDelete={() => {
                    remove(index);
                  }}
                />
              );
          })}
        </div>
      ),
      footer: (
        <Button
          variant={"secondary"}
          type="button"
          className="flex gap-[0.5rem] items-center h-[3.5rem] "
          onClick={() => {
            setCurrView("form");
          }}
        >
          {" "}
          <p>Add USD Account</p>
          <PlusIcon width={16} height={16} />{" "}
        </Button>
      ),
    },
    edit: {
      heading: "USD($) Account details",
      view: (
        <USDAccount newUsdForm={newUsdForm} onSubmit={handleSubmitUpdate} />
      ),
      footer: (
        <Button
          type="submit"
          form={"usd_form"}
          className="flex gap-[0.5rem] items-center h-[3.5rem]"
        >
          <p>Update</p>
        </Button>
      ),
    },
  };

  return (
    <section className={"mt-[38px]"}>
      <OnboardingAccordion
        name={"USD Account details"}
        buttonLabel="Add USD account"
        openFormDrawer={() => {
          newUsdForm.reset();
          setCurrView("form");
          openDrawer();
        }}
      >
        <OnboardingAccordionContent
          type="account"
          fields={
            fields[0] && fields[0].country
              ? fields.map((field) => getFieldsMap(field))
              : undefined
          }
          openListDrawer={() => {
            setCurrView("list");
            openDrawer();
          }}
          onEditClick={() => {
            openDrawer();
            handleClickEdit(fields[0], 0);
          }}
          onDelete={() => {
            remove(0);
          }}
        />
      </OnboardingAccordion>
      <OnboardingItemDrawer
        triggerRef={drawerTriggerRef}
        heading={DrawerView[currView].heading}
        closeButtonRef={closeDrawerButtonRef}
        Footer={DrawerView[currView].footer}
      >
        {DrawerView[currView].view}
      </OnboardingItemDrawer>
    </section>
  );
};

export default UsdSection;
