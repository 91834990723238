import CurrencyAddedIcon from "@/icons/currency-added";
import OnboardingItemCard from "./onboarding-item-card";
import { UsersIcon } from "@/icons/users";
import BriefCaseIcon from "@/icons/brief-case";

interface OnboardingAccordionContentProps {
  fields?: Record<string, string | undefined>[];
  openListDrawer: () => void;
  onEditClick: () => void;
  onDelete: () => void;
  type: "currency" | "director" | "shareholder" | "account";
}

const OnboardingAccordionContent = ({
  fields,
  openListDrawer,
  onEditClick,
  onDelete,
  type,
}: OnboardingAccordionContentProps) => {
  const icons: Record<typeof type, JSX.Element> = {
    currency: <CurrencyAddedIcon width={20} height={17} />,
    director: <UsersIcon />,
    shareholder: <UsersIcon />,
    account: <BriefCaseIcon />,
  };
  return (
    <>
      {fields ? (
        <>
          <div className="flex flex-col w-full gap-[0.75rem]">
            <OnboardingItemCard
              item={fields[0]}
              onEditClick={onEditClick}
              onDelete={onDelete}
            />
            <div className="bg-grey-card rounded-[0.375rem] py-[1.125rem] px-[0.75rem] flex items-center justify-between">
              <div className="flex items-center gap-[0.5rem]">
                {icons[type]}
                <p className="text-[1rem] text-label-black font-[700]">
                  {fields.length} other {type} added
                </p>
              </div>
              <p
                className="text-primary-green font-[600] text-[0.875rem] leading-[1.05875rem] underline underline-offset-2 cursor-pointer"
                onClick={openListDrawer}
              >
                See all
              </p>
            </div>
          </div>
        </>
      ) : (
        <EmptyState Icon={icons[type]} type={type} />
      )}
    </>
  );
};

const EmptyState = ({
  Icon,
  type,
}: {
  Icon: React.ReactNode;
  type: OnboardingAccordionContentProps["type"];
}) => {
  return (
    <div className="h-[7.4375rem] flex flex-col items-center justify-center">
      <div className="bg-grey-bg rounded-full h-[2.75rem] w-[2.75rem] flex items-center justify-center mb-[0.5rem]">
        {Icon}
      </div>
      <p className="font-inter font-[700] text-[1rem] leading-[1.21rem] text-label-black">
        No {type} added
      </p>
    </div>
  );
};

export default OnboardingAccordionContent;
