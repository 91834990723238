import wretch from "wretch";
import AbortAddon from "wretch/addons/abort";
import FormDataAddon from "wretch/addons/formData";
import QueryStringAddon from "wretch/addons/queryString";
import { API_V1 } from "./env";

export const api = wretch(API_V1)
  .options({ credentials: "include", mode: "cors" })
  .addon(FormDataAddon)
  .addon(QueryStringAddon)
  .addon(AbortAddon());

export function nullifySignal(signal?: AbortController) {
  return signal || new AbortController();
}
