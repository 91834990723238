import { useState } from "react";
import { Checkbox } from "./check-box";

// eslint-disable-next-line react-refresh/only-export-components
export const useCheckbox = () => {
  const [isChecked, setIsChecked] = useState(false);

  const toggle = () => {
    setIsChecked((prev) => {
      return !prev;
    });
  };
  return { isChecked, toggle };
};
export interface CheckboxProps {
  label: string | React.ReactNode;
  className?: string;
  labelClassName?: string;
  id?: string;
  handleOnChange?: () => void;
  onCheckedChange?: (e: boolean) => void;
  checked?: boolean;
}
const CheckboxWithLabel: React.FC<CheckboxProps> = ({
  label,
  className,
  labelClassName,
  id,
  handleOnChange,
  checked,
  onCheckedChange,
}) => {
  return (
    <div className="flex gap-2 items-center">
      <Checkbox
        className={` ${className ? className : ""}`}
        id={`${id ? id : label}`}
        onClick={handleOnChange}
        checked={checked}
        onCheckedChange={onCheckedChange}
      />

      <label
        className={`text-lg font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 ${
          labelClassName ? labelClassName : "text-grey-background"
        }`}
        htmlFor={`${id ? id : label}`}
      >
        {label}
      </label>
    </div>
  );
};

export { CheckboxWithLabel };
