import { api, nullifySignal } from "@/config/wrench";
import { APIResponse } from "../response";
import { Session } from "./types";

const sessionApi = api.url("/sessions");

export function signIn(
  params: Session.LoginDTO,
  controller?: AbortController
): Promise<Session.LoginResponse> {
  return sessionApi
    .signal(nullifySignal(controller))
    .post(params)
    .json<Session.LoginResponse>();
}

export function getSession(
  controller?: AbortController
): Promise<Session.LoginResponse> {
  return sessionApi
    .signal(nullifySignal(controller))
    .get()
    .json<Session.LoginResponse>();
}

export function forgotPassword(
  params: Session.ForgotPasswordDTO
): Promise<APIResponse> {
  return sessionApi.url("/forgot-password").post(params).json<APIResponse>();
}

export function forgotPasswordLink(
  params: Session.ForgotPasswordLinkDTO
): Promise<APIResponse> {
  return sessionApi.url("/forgot-password").query(params).get().json<APIResponse>();
}
