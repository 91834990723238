import CautionIcon from "@/icons/caution";
import { Button, buttonVariants } from "./ui/button";
import { DialogClose, DialogContent } from "./ui/dialog";
import { useAuthStore } from "@/internals/auth";
import { useRouter } from "@tanstack/react-router";
import { cn } from "@/lib/utils";

function SignOutModal() {
  const logoutFn = useAuthStore((s) => s.logout);
  const router = useRouter();

  const logout = () => {
    logoutFn();
    router.navigate({ to: "/sign-in" });
    router.invalidate();
  };

  return (
    <DialogContent className="h-[20rem] rounded-[1.875rem]">
      <div className=" flex flex-col justify-between">
        <div className="flex flex-col items-center justify-center gap-[1.6875rem]">
          <CautionIcon />
          <p>Are you sure you want to sign out?</p>
        </div>
        <div className="flex gap-[2.375rem]">
          <DialogClose
            className={cn(
              buttonVariants({ size: "sm", className: "px-3 w-full" })
            )}
          >
            Go Back
          </DialogClose>
          <Button
            variant={"destructive"}
            type="submit"
            size="sm"
            className="px-3 w-full"
            onClick={logout}
          >
            Sign Out
          </Button>
        </div>
      </div>
    </DialogContent>
  );
}

export default SignOutModal;
