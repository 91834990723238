import { queryOptions } from "@tanstack/react-query";
import { getBanksList, getIdentityConfig, validateBankAccount } from "./api";
import { SETTINGS_QUERY_KEYS } from "./constants";
import { Settings } from "./types";

export function identityConfigOptions() {
  return queryOptions({
    queryKey: SETTINGS_QUERY_KEYS.identityConfig,
    queryFn: async () => getIdentityConfig(),
  });
}

export function banksListOptions() {
  return queryOptions({
    queryKey: SETTINGS_QUERY_KEYS.banksList,
    queryFn: async () => await getBanksList(),
    select(data) {
      return data.data;
    },
  });
}

export const validateBankAccountOptions = (
  params: Settings.BankValidationDto
) => {
  return queryOptions({
    queryKey: SETTINGS_QUERY_KEYS.validateBankAccount({
      account_number: params.account_number,
      bank_code: params.bank_code,
    }),
    queryFn: async () => await validateBankAccount(params),
    select(data) {
      return data.data;
    },
    enabled: !!params.bank_code && !!params.account_number,
  });
};
