import { ONBOARDING_ITEMS, OnboardingScreens } from "@/data/onboarding.data";

import SignOutModal from "@/components/sign-out-modal";
import { Dialog, DialogTrigger } from "@/components/ui/dialog";
import ChevronLeftIcon from "@/icons/chevron-left";
import { LoginIcon } from "@/icons/login-in";
import { useRouteName } from "@/internals/hooks/useRouteName";
import { getKeys } from "@/internals/object-keys";
import { Link, useParams, useRouter } from "@tanstack/react-router";
import { MenuItem } from "../../components/ui/menu-item";
import { ButtonIconProps } from "../../components/ui/types";
import styles from "./side-nav.module.css";

const BackButton: ButtonIconProps = () => <ChevronLeftIcon />;

const OnboardingSideNavigation = () => {
  const routeName = useRouteName() as OnboardingScreens;
  const router = useRouter();

  const organizationId = useParams({
    from: "/organization/$organizationId/_onboarding",
    select(params) {
      return params.organizationId;
    },
  });

  const items = getKeys(ONBOARDING_ITEMS);

  const goBack = () => {
    if (routeName === "purpose-of-account") {
      router.navigate({ to: "/select-business" });
      return;
    }
    router.history.back();
  };

  return (
    <div className="md:w-80 bg-white h-screen flex-1 fixed border-r border-grey-border hidden md:flex">
      <div className="flex flex-col justify-center items-center h-full w-full py-[2rem]">
        <div className="h-full">
          <MenuItem
            className="w-[5.12rem] flex justify-center px-2.5 bg-destructive-foreground hover:bg-grey-border mb-[3.625rem]"
            size="lg"
            leftIcon={BackButton}
            onClick={goBack}
          >
            Back
          </MenuItem>
          <div className=" flex flex-col flex-1  text-grey-background">
            <ol className={`relative flex flex-col`}>
              {items.map((key, idx) => {
                const item = ONBOARDING_ITEMS[key];
                const activeIdx = items.findIndex((i) => i === routeName);
                const isPastIdx = activeIdx > idx;
                const isFirstIdx = idx === 0;

                return (
                  <li
                    key={key}
                    className={`relative flex ms-4 items-start ${styles.li}`}
                  >
                    <div className="flex gap-2 h-max justify-center items-start">
                      <div className="flex flex-col items-center  h-[2.645rem]">
                        <div
                          className={`absolute w-2  h-2 rounded-full  sidenav-circle ${
                            styles.sideNavCircle
                          } ${isPastIdx || isFirstIdx ? "bg-primary-green" : "bg-grey-background"} `}
                        />

                        {idx !== items.length - 1 ? (
                          <div
                            className={`${isPastIdx ? "bg-primary-green" : "bg-grey-light-background"}  w-[0.125rem] h-full`}
                          />
                        ) : (
                          <span className="w-[0.125rem]"></span>
                        )}
                      </div>
                      <Link
                        to={`/organization/$organizationId/onboarding/${key}`}
                        params={{ organizationId }}
                        preload="intent"
                      >
                        <p
                          className={`cursor-pointer text-base leading-3 font-normal hover:font-semibold sidenav-item ${
                            isPastIdx || isFirstIdx
                              ? "text-primary-green font-semibold"
                              : ""
                          } ${styles.sideNavItem}`}
                        >
                          {item.sideNavTitle}
                        </p>
                      </Link>
                    </div>
                  </li>
                );
              })}
            </ol>
          </div>
        </div>

        <Dialog>
          <DialogTrigger asChild>
            <div className="flex gap-x-2 text-text-input-placeholder">
              <LoginIcon />
              <p className="text-[1rem] text-text-input-placeholder">
                Sign Out
              </p>
            </div>
          </DialogTrigger>
          <SignOutModal />
        </Dialog>
      </div>
    </div>
  );
};

export default OnboardingSideNavigation;
