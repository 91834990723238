import { Button } from "@/components/ui/button";
import {
  DefaultDialogClose,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { organizationQueryOptions } from "@/domains/organizations";
import { Settings } from "@/domains/settings/types";
import { CircleCloseIcon } from "@/icons/circle-close";
import DownloadFileIcon from "@/icons/download-file";
import ExternalLinkIcon from "@/icons/external-link";
import EyeIcon from "@/icons/eye";
import { truncateString } from "@/internals/strings";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute, useParams } from "@tanstack/react-router";
import _ from "lodash";
import { Loader2 } from "lucide-react";
import React from "react";
import useDownloader from "react-use-downloader";

export const Route = createFileRoute(
  "/organization/$organizationId/_dashboard/dashboard/settings"
)({
  component: SettingsScreen,
  loader: (opts) =>
    opts.context.queryClient.ensureQueryData(
      organizationQueryOptions(
        (opts.params as Record<string, string>).organizationId
      )
    ),
});

function DocumentFile({
  data,
}: {
  data: {
    key: string;
    name: string;
    file: Settings.InternalFile;
  };
}) {
  const { download, isInProgress } = useDownloader({
    mode: "cors",
  });
  if (!data) {
    return null;
  }
  const fileType = data.file?.name?.split(".")?.pop() || "--";
  const fileName = data.file?.name ?? "--";
  return (
    <div>
      <p className="text-[1.125rem] mb-[1rem]">{data.name}</p>
      <div className="bg-white  rounded-[1.25rem] flex items-center justify-between border border-grey-dark w-[31rem] space-y-[0.25rem] px-[1.875rem] py-[1.1875rem]">
        <div className="flex items-center gap-[0.25rem]">
          <p className="text-[0.75rem] font-[600] text-primary-green bg-primary-green-light rounded-[0.375rem] p-2">
            {fileType}
          </p>
          <p className="text-[1rem]">{truncateString(fileName)}</p>
        </div>
        <div className="flex gap-[0.625rem] items-center">
          <Dialog>
            <DialogTrigger asChild>
              <EyeIcon className="cursor-pointer" />
            </DialogTrigger>
            <DialogContent
              className="sm:max-w-[425px]"
              renderClose={() => (
                <DefaultDialogClose icon={<CircleCloseIcon />} />
              )}
            >
              <DialogHeader>
                <DialogTitle>{data.name}</DialogTitle>
                <DialogDescription>{fileName}</DialogDescription>
              </DialogHeader>
              <div className="grid gap-4 py-4">
                <DocViewer
                  documents={[
                    {
                      uri: data.file.url,
                      fileName: fileName,
                      fileType: fileType,
                    },
                  ]}
                  pluginRenderers={DocViewerRenderers}
                />
              </div>
              <DialogFooter>
                <DialogClose asChild>
                  <Button type="button" variant="ghost">
                    Close
                  </Button>
                </DialogClose>
              </DialogFooter>
            </DialogContent>
          </Dialog>
          {isInProgress ? (
            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
          ) : (
            <DownloadFileIcon
              className="cursor-pointer"
              onClick={() => download(data.file.url, fileName)}
            />
          )}

          <a href={data.file.url} target="_blank">
            <ExternalLinkIcon className="cursor-pointer" />
          </a>
        </div>
      </div>
    </div>
  );
}

function SettingsScreen() {
  const organizationId = useParams({
    from: "/organization/$organizationId",
    select(params) {
      return params.organizationId;
    },
  });
  const { data } = useSuspenseQuery(organizationQueryOptions(organizationId));
  const { organization, documents } = data;

  const documentSubmitted = React.useMemo(() => {
    if (_.isEmpty(documents)) {
      return [];
    }

    const keys = [
      ["proof_of_address", "Proof of address"],
      ["incorporation_certificate", "Incorporation certificate"],
      ["operating_license", "Business operating license"],
      ["shareholders_register", "Register of shareholders"],
      ["directors_register", "Register of directors"],
      ["articles", "Memorandum & Articles of Association"],
    ] as const;

    return keys
      .map(([key, name]) => {
        if (documents[key]) {
          return {
            key,
            file: documents[key],
            name,
          };
        }
      })
      .filter(Boolean);
  }, [documents]);

  return (
    <div className="py-[4.875rem]">
      <div className="flex flex-col justify-center items-center">
        <div className="bg-white rounded-[1.25rem] border border-grey-dark w-[31rem] space-y-[0.25rem] px-[1.875rem] py-[1.1875rem]">
          <p className="text-[1.25rem] font-[800]">
            {organization.company_name}
          </p>
          <p className="text-[0.75rem]">
            Company registration number: {organization.rc_number}
          </p>
        </div>
        <div className="mt-[3.3125rem]">
          <div className="mb-[1.125rem]">
            <p className="text-[1.25rem] font-[800]">Supporting Documents</p>
          </div>
          <div className="space-y-[1.5rem]">
            {documentSubmitted.map((data) =>
              !data ? null : <DocumentFile key={data.key} data={data} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
