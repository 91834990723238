import { useMutation } from "@tanstack/react-query";
import {
  getVerificationEmail,
  signUp,
  updateUserDetails,
  verifyEmail,
} from "./api";
import { USER_MUTATION_KEYS } from "./constants";
import { toast } from "sonner";

export function useSignUp() {
  return useMutation({
    mutationKey: USER_MUTATION_KEYS.signUp,
    mutationFn: signUp,
  });
}

export function useVerifyEmail() {
  return useMutation({
    mutationKey: USER_MUTATION_KEYS.verifyEmail,
    mutationFn: verifyEmail,
    onSuccess(data) {
      toast(data.message);
    },
  });
}

export function useGetVerificationEmail() {
  return useMutation({
    mutationKey: USER_MUTATION_KEYS.verifyEmail,
    mutationFn: getVerificationEmail,
    onSuccess(data) {
      toast(data.message);
    },
  });
}

export function useUpdateUserProfile() {
  return useMutation({
    mutationKey: USER_MUTATION_KEYS.updateUserDetails,
    mutationFn: updateUserDetails,
  });
}
