import * as Sentry from "@sentry/react";

export function initSentry() {
  Sentry.init({
    dsn: "https://18d2c5ea86d0ff8c43f2fa4132e0cc02@o4507225748406272.ingest.de.sentry.io/4507225750044752",
    integrations: [
      Sentry.browserTracingIntegration(),
      // Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/clearerpay\.com\/api/],
    // // Session Replay
    // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    // replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}
