import FormFileInput from "@/components/form-file-input";
import { Button } from "@/components/ui/button";
import { Form } from "@/components/ui/form";
import { InitSupportingDocuments } from "@/data/onboarding.data";
import {
  Organization,
  documentsOptions,
  useSetDocuments,
} from "@/domains/organizations";
import { useOnboardingFlow } from "@/internals/hooks/useOnboardingFlow";
import {
  SupportingDocumentSchema,
  supportingDocumentSchemaDTO,
} from "@/sections/onboarding/supporting-documents/validator";
import { zodResolver } from "@hookform/resolvers/zod";
import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute, useParams } from "@tanstack/react-router";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "sonner";

export const Route = createFileRoute(
  "/organization/$organizationId/_onboarding/onboarding/supporting-documents"
)({
  component: SupportingDocuments,
  loader: (opts) =>
    opts.context.queryClient.ensureQueryData(
      documentsOptions((opts.params as Record<string, string>).organizationId)
    ),
});

function extractData(
  data?: Organization.DocumentsResponse["data"]
): supportingDocumentSchemaDTO {
  if (!data) {
    return InitSupportingDocuments;
  }

  return {
    business_operating_license: data.operating_license,
    incorporation_certificate: data.incorporation_certificate,
    memorandum: data.articles,
    proof_of_address: data.proof_of_address,
    register_of_directors: data.directors_register,
    register_of_shareholders: data.shareholders_register,
  };
}

export function SupportingDocuments() {
  const { prev: handlePrevious, next: handleNext } = useOnboardingFlow();

  const organizationId = useParams({
    from: "/organization/$organizationId",
    select(params) {
      return params.organizationId;
    },
  });
  const { data } = useSuspenseQuery(documentsOptions(organizationId));

  const values = extractData(data);

  const form = useForm<supportingDocumentSchemaDTO>({
    resolver: zodResolver(SupportingDocumentSchema),
    defaultValues: values,
  });

  const setDocuments = useSetDocuments();

  const onSubmit: SubmitHandler<supportingDocumentSchemaDTO> = (values) => {
    console.log(values);

    setDocuments.mutate(
      {
        id: organizationId,
        articles_id: values.memorandum.id,
        directors_register_id: values.register_of_directors.id,
        incorporation_certificate_id: values.incorporation_certificate.id,

        proof_of_address_id: values.proof_of_address.id,
        shareholders_register_id: values.register_of_shareholders.id,
        operating_license_id: values.business_operating_license?.id,
      },
      {
        onSuccess() {
          toast.success("Documents uploaded successfully");
          handleNext();
        },
      }
    );
  };
  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col w-[500px] max-w-[500px] mx-auto mt-5"
      >
        <div className="mt-[38px] flex flex-col gap-[2.375rem]">
          <FormFileInput
            label="Certificate of Incorporation"
            maxSize={10}
            name={"incorporation_certificate"}
            control={form.control}
          />

          <FormFileInput
            label="Memorandum & Articles of Association"
            maxSize={10}
            name={"memorandum"}
            control={form.control}
          />

          <FormFileInput
            label="Upload Shareholders register for any companies that hold or control
      voting rights of more than 10% of the applicant company - Dated
      within 6 months"
            maxSize={10}
            name={"register_of_shareholders"}
            control={form.control}
          />

          <FormFileInput
            label="Upload Register of Directors for the applicant company - Dated within 6 months"
            maxSize={10}
            name={"register_of_directors"}
            control={form.control}
          />

          <FormFileInput
            label="Upload Proof of Address (Bank statement or Utility bill valid 3
      months)"
            maxSize={10}
            name={"proof_of_address"}
            control={form.control}
          />

          <FormFileInput
            label="Upload Business Operating License (If applicable to business activity)"
            maxSize={10}
            name={"business_operating_license"}
            control={form.control}
          />
        </div>

        <div className="my-12 flex items-center justify-between gap-8">
          <Button
            className="bg-grey-light-background text-black rounded-[0.625rem]"
            size={"sm"}
            onClick={handlePrevious}
            disabled={setDocuments.isPending}
          >
            Previous
          </Button>
          <Button
            size={"sm"}
            type="submit"
            className="rounded-[0.625rem]"
            loading={setDocuments.isPending}
          >
            Next
          </Button>
        </div>
      </form>
    </Form>
  );
}
