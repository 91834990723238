import { Button } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { cn } from "@/lib/utils";
import { ChevronDownIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { Check } from "lucide-react";

import { Badge } from "../ui/badge";

import React from "react";
import { MultiSelectProps } from "../ui/multi-select/types";

export function MultiComboBox({
  data: items,
  placeholder,
  emptyLabel,
  onValueChange,
  isInvalid,
  value = [],
  showValues = true,
}: MultiSelectProps) {
  // const { selectedItems, handleMultiSelection, removeItem } = useMultiSelect();
  const labelMap = React.useMemo(() => {
    const container: Record<string, string> = {};
    items.forEach((item) => {
      container[item.value] = item.label;
    });
    return container;
  }, [items]);

  return (
    <>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            type="button"
            variant="outline"
            role="combobox"
            className={cn(
              "h-10 relative w-full cursor-default rounded-lg bg-transparent py-2 pl-3 pr-10 text-left border border-text-input  text-base text-black focus:ring-1 focus:ring-primary-green focus:border-primary-green focus:shadow-primary-green justify-start font-normal",
              isInvalid && "border-destructive"
            )}
          >
            <span className="block truncate text-black">
              {value?.length === 0
                ? placeholder
                : value.map((i) => labelMap[i])?.join(",  ")}
            </span>

            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronDownIcon
                className="h-5 w-5 text-black"
                aria-hidden="true"
              />
            </span>
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-[31.25rem] p-0 ">
          <Command className="w-full">
            <CommandList>
              <CommandInput placeholder="Search..." />
              <CommandEmpty>{emptyLabel}</CommandEmpty>
              <CommandGroup className=" w-full">
                {items.map((item) => {
                  return (
                    <CommandItem
                      key={item.label}
                      className="gap-2 data-[disabled]:pointer-events-auto data-[disabled]:opacity-100"
                      value={`${item.label}-${item.value}`}
                      onSelect={(currentValue) => {
                        if (currentValue) {
                          const [, actualValue] = currentValue.split("-");
                          if (value?.length > 0) {
                            const foundIndex = value?.findIndex(
                              (i) => i === actualValue
                            );
                            if (foundIndex > -1) {
                              onValueChange?.(
                                value.filter((j) => j !== actualValue)
                              );

                              return;
                            }
                            if (foundIndex === -1)
                              onValueChange?.([...value, actualValue!]);
                          } else {
                            onValueChange?.([...value, actualValue!]);
                          }
                        }
                      }}
                    >
                      <Check
                        className={cn(
                          "mr-2 h-4 w-4",
                          value.length > 0 &&
                            value?.findIndex((i) => i === item.value) > -1
                            ? "opacity-100"
                            : "opacity-0"
                        )}
                      />
                      <span>{item.label}</span>
                    </CommandItem>
                  );
                })}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
      {value?.length > 0 && showValues ? (
        <div className="flex flex-wrap items-center gap-2 ">
          {value?.map((item, index) => (
            <Badge
              key={index}
              className="flex items-center justify-between rounded-[6px] p-2.5 h-[2.375rem] min-w-[15.375rem]  text-label font-medium text-sm"
            >
              {labelMap[item]}
              <XMarkIcon
                className="h-5 w-5 cursor-pointer"
                aria-hidden="true"
                onClick={() => {
                  onValueChange?.(value.filter((j) => j !== item));
                }}
              />
            </Badge>
          ))}
        </div>
      ) : null}
    </>
  );
}
