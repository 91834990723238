import { SVGProps } from "react";

const LoginIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.75 6V18C21.75 20.418 20.418 21.75 18 21.75H12C9.582 21.75 8.25 20.418 8.25 18V17C8.25 16.586 8.586 16.25 9 16.25C9.414 16.25 9.75 16.586 9.75 17V18C9.75 19.577 10.423 20.25 12 20.25H18C19.577 20.25 20.25 19.577 20.25 18V6C20.25 4.423 19.577 3.75 18 3.75H12C10.423 3.75 9.75 4.423 9.75 6V7C9.75 7.414 9.414 7.75 9 7.75C8.586 7.75 8.25 7.414 8.25 7V6C8.25 3.582 9.582 2.25 12 2.25H18C20.418 2.25 21.75 3.582 21.75 6ZM12.47 14.47C12.177 14.763 12.177 15.238 12.47 15.531C12.616 15.677 12.808 15.751 13 15.751C13.192 15.751 13.384 15.678 13.53 15.531L16.53 12.531C16.599 12.462 16.654 12.379 16.692 12.287C16.768 12.104 16.768 11.897 16.692 11.714C16.654 11.622 16.599 11.539 16.53 11.47L13.53 8.47C13.237 8.177 12.762 8.177 12.469 8.47C12.176 8.763 12.176 9.23801 12.469 9.53101L14.189 11.251H3C2.586 11.251 2.25 11.587 2.25 12.001C2.25 12.415 2.586 12.751 3 12.751H14.189L12.47 14.47Z"
        fill="currentColor"
      />
    </svg>
  );
};

export { LoginIcon };
