import { Settings } from "@/domains/settings/types";
import { extractAddressFromMapPlace } from "@/domains/settings/utils";
import {
  FieldError,
  FieldErrorsImpl,
  FieldPath,
  FieldValues,
  Merge,
  UseFormSetValue,
} from "react-hook-form";
import { getKeys } from "./object-keys";
import { capitalizeFirstLetter } from "./strings";

export function getAddressErrors(
  errors: Merge<FieldError, FieldErrorsImpl<Settings.Address>> | undefined
) {
  if (!errors) {
    return "";
  }
  const container: string[] = [];
  getKeys(errors).forEach((key) => {
    const value = errors[key];
    // @ts-expect-error message for some reason isn't on value
    if (value?.message) {
      container.push(
        // @ts-expect-error message for some reason isn't on value
        `${capitalizeFirstLetter(key).replace("_", " ")} ${value?.message}`
      );
    }
  });
  return container.filter(Boolean).join(", ");
}

export function onPlaceSelectedFn<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(setValue: UseFormSetValue<TFieldValues>, address: TName) {
  return (place: Settings.MapPlace) => {
    const result = extractAddressFromMapPlace(place);
    getKeys(result).map((key) => {
      // @ts-expect-error TODO: ignore for now to fix later
      setValue(`${address}.${key}`, result[key]);
    });
  };
}
