import {
  Control,
  FieldPath,
  FieldValues,
  useFormContext,
} from "react-hook-form";
import { SingleComboBox } from "./combo-box/single";

import { SingleSelectItem } from "./ui/single-select";

import { ErrorMessage } from "@hookform/error-message";
import { FormField, FormItem, FormLabel } from "./ui/form";

export function FormSingleComboBox<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  name,
  emptyLabel,
  label,
  data,
  placeholder,
  onValueChange,
  control,
}: {
  // TODO: make this required
  control?: Control<TFieldValues>;
  name: TName;
  label?: React.ReactNode;
  emptyLabel?: React.ReactNode;
  data: SingleSelectItem[];
  placeholder?: string;
  delimiter?: string;
  dtoKeys?: string[];
  onValueChange?: (e: string) => void;
}) {
  const { formState } = useFormContext();

  return (
    <FormField
      control={control}
      name={name}
      render={({ field, fieldState }) => (
        <FormItem>
          <FormLabel className="font-medium text-lg ">{label}</FormLabel>
          <SingleComboBox
            placeholder={placeholder}
            data={data}
            emptyLabel={emptyLabel}
            onValueChange={(e) => {
              onValueChange && onValueChange(e);
              field.onChange(e);
            }}
            isInvalid={fieldState.error}
            value={field.value}
          />
          <ErrorMessage
            name={name}
            errors={formState.errors}
            render={({ message }) => (
              <p className={"text-sm font-medium text-destructive"}>
                {message}
              </p>
            )}
          />
        </FormItem>
      )}
    ></FormField>
  );
}
