import { SVGProps } from "react";

const CurrencyAddedIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 20 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16 9C13.79 9 12 10.79 12 13C12 15.21 13.79 17 16 17C18.21 17 20 15.21 20 13C20 10.79 18.21 9 16 9ZM17.25 13.5H16.5V14.25C16.5 14.53 16.28 14.75 16 14.75C15.72 14.75 15.5 14.53 15.5 14.25V13.5H14.75C14.47 13.5 14.25 13.28 14.25 13C14.25 12.72 14.47 12.5 14.75 12.5H15.5V11.75C15.5 11.47 15.72 11.25 16 11.25C16.28 11.25 16.5 11.47 16.5 11.75V12.5H17.25C17.53 12.5 17.75 12.72 17.75 13C17.75 13.28 17.53 13.5 17.25 13.5ZM15 0H3C1 0 0 1 0 3V11C0 13 1 14 3 14H10.23C10.4 14 10.54 13.86 10.54 13.69C10.51 13.46 10.5 13.23 10.5 13C10.5 9.97 12.97 7.5 16 7.5C16.56 7.5 17.1 7.57999 17.6 7.73999C17.8 7.79999 18 7.66002 18 7.46002V3C18 1 17 0 15 0ZM3 8C2.45 8 2 7.55 2 7C2 6.45 2.45 6 3 6C3.55 6 4 6.45 4 7C4 7.55 3.55 8 3 8ZM9 10C7.34 10 6 8.66 6 7C6 5.34 7.34 4 9 4C10.66 4 12 5.34 12 7C12 8.66 10.66 10 9 10Z"
        fill="#939393"
      />
    </svg>
  );
};

export default CurrencyAddedIcon;
