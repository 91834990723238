import { SVGProps } from "react";

const DownloadFile = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="17"
      height="20"
      viewBox="0 0 17 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.53 6.47L10.53 0.470001C10.389 0.329001 10.199 0.25 10 0.25H4C1.582 0.25 0.25 1.582 0.25 4V16C0.25 18.418 1.582 19.75 4 19.75H13C15.418 19.75 16.75 18.418 16.75 16V7C16.75 6.801 16.671 6.61 16.53 6.47ZM10.75 2.811L14.189 6.25H13C11.423 6.25 10.75 5.577 10.75 4V2.811ZM13 18.25H4C2.423 18.25 1.75 17.577 1.75 16V4C1.75 2.423 2.423 1.75 4 1.75H9.25V4C9.25 6.418 10.582 7.75 13 7.75H15.25V16C15.25 17.577 14.577 18.25 13 18.25ZM9.53003 12.47C9.82303 12.763 9.82303 13.238 9.53003 13.531L7.53003 15.531C7.46103 15.6 7.37789 15.655 7.28589 15.693C7.19389 15.731 7.09702 15.751 6.99902 15.751C6.90102 15.751 6.80391 15.731 6.71191 15.693C6.61991 15.655 6.53702 15.6 6.46802 15.531L4.46802 13.531C4.17502 13.238 4.17502 12.763 4.46802 12.47C4.76102 12.177 5.23605 12.177 5.52905 12.47L6.24902 13.19V10C6.24902 9.586 6.58502 9.25 6.99902 9.25C7.41302 9.25 7.74902 9.586 7.74902 10V13.189L8.46899 12.469C8.76299 12.177 9.23703 12.177 9.53003 12.47Z"
        fill="#25314C"
      />
    </svg>
  );
};

export default DownloadFile;
