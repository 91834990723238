import { cva } from "class-variance-authority";
import React from "react";
import { ErrorText } from "./error-text";
import { Input } from "./input";
import { Label } from "./label";
import { TextInputProps } from "./types";

const textInputVariants = cva("", {
  variants: {
    type: {
      default: "text",
      email: "email",
      password: "password",
      number: "number",
      tel: "tel",
    },
  },
  defaultVariants: {
    type: "default",
  },
});

export const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      iconLeft,
      iconRight,
      placeholder,
      labelTitle,
      disabled,
      className,
      labelClassName,
      variant,
      errorText,
      ...rest
    },
    ref
  ) => {
    // Get the type based on the variant
    const type = textInputVariants({ type: variant });

    return (
      <div className="grid w-full items-center gap-[14px]">
        <Label
          htmlFor={type}
          className={`${
            labelClassName ? labelClassName : ""
          } font-medium text-lg`}
        >
          {labelTitle}
        </Label>
        <Input
          ref={ref}
          type={type}
          placeholder={placeholder}
          disabled={disabled || false}
          className={`border-text-input bg-transparent placeholder:text-text-input-placeholder disabled:bg-textinput-disabled disabled:placeholder:text-textinput text-base ${
            className || ""
          }`}
          iconLeft={iconLeft}
          iconRight={iconRight}
          {...rest}
        />
        <ErrorText text={errorText} />
      </div>
    );
  }
);

TextInput.displayName = "Input";
