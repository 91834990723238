import { PlusIcon } from "@heroicons/react/20/solid";

import { useRef, useState } from "react";

import { InitSettlementAccounts } from "@/data/onboarding.data";

import { useFieldArray, useForm, useFormContext } from "react-hook-form";

import { Button } from "@/components/ui/button";

import OnboardingAccordion from "../onboarding-accordions/onboarding-accordion";
import OnboardingItemDrawer from "../onboarding-accordions/onboarding-item-drawer";
import OnboardingAccordionContent from "../onboarding-accordions/onboarding-accordion-content";
import OnboardingItemCard from "../onboarding-accordions/onboarding-item-card";
import { zodResolver } from "@hookform/resolvers/zod";

import {
  GbpAccountSchemaDto,
  SettlementAccountsSchemaDto,
  varyingCountryAccountSchema,
} from "./validators";
import GBPAccount from "./GBPAccount";
import { DrawerViews, DrawerViewsKeys } from "../company-structure/types";

const GbpSection = () => {
  const drawerTriggerRef = useRef<HTMLButtonElement>(null);
  const closeDrawerButtonRef = useRef<HTMLButtonElement>(null);
  const [editingIndex, setEditingIndex] = useState<string>();
  // const closeDrawer = () => {
  //   if (closeDrawerButtonRef.current) {
  //     closeDrawerButtonRef.current.click();
  //   }
  // };
  const openDrawer = () => {
    if (drawerTriggerRef.current) {
      drawerTriggerRef.current.click();
    }
  };
  const { control } = useFormContext<SettlementAccountsSchemaDto>();
  const newGbpForm = useForm<GbpAccountSchemaDto>({
    defaultValues: InitSettlementAccounts["gbp_account"][0],
    resolver: zodResolver(varyingCountryAccountSchema),
  });
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "gbp_account",
  });

  const getFieldsMap = (account: GbpAccountSchemaDto) => ({
    Country: account.country,
    ...(account.country === "GB" && {
      "Sort Code": account.sort_code,
      "Account Number": account.account_number,
    }),
    ...(account.country !== "GB" && {
      IBAN: account.iban,
      "BIC/SWIFT": account.swift,
      "Bank Name": account.bank_name,
    }),
    "Legal Name": account.account_name,
  });
  const handleClickEdit = (i: GbpAccountSchemaDto, index: number) => {
    setEditingIndex(String(index));
    Object.keys(i).forEach((key) => {
      newGbpForm.setValue(
        key as keyof GbpAccountSchemaDto,
        i[key as keyof GbpAccountSchemaDto]
      );
    });

    setCurrView("edit");
  };

  const handleAddNew = () => {
    if (fields[0] && fields[0].account_name === "") {
      update(0, newGbpForm.getValues());
    } else {
      append(newGbpForm.getValues());
    }
    setCurrView("list");
    newGbpForm.reset();
  };

  const handleSubmitUpdate = () => {
    if (editingIndex) {
      update(Number(editingIndex), newGbpForm.getValues());
      setCurrView("list");
      newGbpForm.reset();
    }
  };

  const [currView, setCurrView] = useState<DrawerViewsKeys>("form");
  const DrawerView: DrawerViews = {
    form: {
      heading: "Add GBP(£) Account",
      view: <GBPAccount newGbpForm={newGbpForm} onSubmit={handleAddNew} />,
      footer: (
        <Button
          type="submit"
          form={"gbp_form"}
          className="flex gap-[0.5rem] items-center h-[3.5rem]"
        >
          <p>Add</p>
        </Button>
      ),
    },
    list: {
      heading: "GBP(£) Account details",
      view: (
        <div className="flex flex-col gap-[1.5rem] ">
          {fields.map((field, index) => {
            if (field.account_name !== "")
              return (
                <OnboardingItemCard
                  key={index}
                  item={getFieldsMap(field)}
                  onEditClick={() => {
                    handleClickEdit(field, index);
                  }}
                  onDelete={() => {
                    remove(index);
                  }}
                />
              );
          })}
        </div>
      ),
      footer: (
        <Button
          variant={"secondary"}
          type="button"
          className="flex gap-[0.5rem] items-center h-[3.5rem] "
          onClick={() => {
            setCurrView("form");
          }}
        >
          {" "}
          <p>Add GBP Account</p>
          <PlusIcon width={16} height={16} />{" "}
        </Button>
      ),
    },
    edit: {
      heading: "GBP(£) Account details",
      view: (
        <GBPAccount newGbpForm={newGbpForm} onSubmit={handleSubmitUpdate} />
      ),
      footer: (
        <Button
          type="submit"
          form={"gbp_form"}
          className="flex gap-[0.5rem] items-center h-[3.5rem]"
        >
          <p>Update</p>
        </Button>
      ),
    },
  };

  return (
    <section className={"mt-[38px]"}>
      <OnboardingAccordion
        name={"GBP Account details"}
        buttonLabel="Add GBP account"
        openFormDrawer={() => {
          newGbpForm.reset();
          setCurrView("form");
          openDrawer();
        }}
      >
        <OnboardingAccordionContent
          type="account"
          fields={
            fields[0] && fields[0].account_name !== ""
              ? fields.map((field) => getFieldsMap(field))
              : undefined
          }
          openListDrawer={() => {
            setCurrView("list");
            openDrawer();
          }}
          onEditClick={() => {
            openDrawer();
            handleClickEdit(fields[0], 0);
          }}
          onDelete={() => {
            remove(0);
          }}
        />
      </OnboardingAccordion>
      <OnboardingItemDrawer
        triggerRef={drawerTriggerRef}
        heading={DrawerView[currView].heading}
        closeButtonRef={closeDrawerButtonRef}
        Footer={DrawerView[currView].footer}
      >
        {DrawerView[currView].view}
      </OnboardingItemDrawer>
    </section>
  );
};

export default GbpSection;
