import { ClearerLogo } from "@/assets";
import { FormInput } from "@/components/form-input";
import { Button } from "@/components/ui/button";
import { Form } from "@/components/ui/form";
import { DEFAULT_ANIMATION_CONFIG } from "@/config/animation";
import { useForgotPassword, useForgotPasswordLink } from "@/domains/sessions";
import { useAuthStore } from "@/internals/auth";
import {
  codeRedirectSearch,
  emailSearch,
} from "@/internals/validators/route.validation";
import { zodResolver } from "@hookform/resolvers/zod";
import { createFileRoute, useRouter } from "@tanstack/react-router";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import { z } from "zod";

export const Route = createFileRoute("/forgot-password")({
  component: Root,
  validateSearch: codeRedirectSearch.merge(emailSearch),
});

const PostForgotPasswordSchema = z
  .object({
    password: z.string().min(8),
    confirmPassword: z.string().min(8),
  })
  .superRefine(({ confirmPassword, password }, ctx) => {
    if (confirmPassword !== password) {
      ctx.addIssue({
        code: "custom",
        message: "The passwords did not match",
      });
    }
  });

const GetForgotPasswordSchema = z.object({
  email_address: z.string().email(),
});

function Root() {
  const { code, email } = Route.useSearch();

  if (code) {
    return <PostForgotPassword code={code} />;
  }
  return <GetForgotPassword email={email} />;
}

function PostForgotPassword({ code }: { code: string }) {
  const forgotPasswordMutation = useForgotPassword();
  const logoutFn = useAuthStore((s) => s.logout);

  const router = useRouter();
  const form = useForm<z.infer<typeof PostForgotPasswordSchema>>({
    resolver: zodResolver(PostForgotPasswordSchema),
    defaultValues: {
      confirmPassword: "",
      password: "",
    },
  });

  const logout = () => {
    logoutFn();
    router.navigate({ to: "/sign-in" });
    router.invalidate();
  };

  const onSubmit = (data: z.infer<typeof PostForgotPasswordSchema>) => {
    forgotPasswordMutation.mutate(
      {
        code,
        password: data.password,
        confirm_password: data.confirmPassword,
      },
      {
        onSuccess(data) {
          toast(data.message);
          setTimeout(() => {
            logout();
          }, DEFAULT_ANIMATION_CONFIG.duration);
        },
      }
    );
  };

  return (
    <section className="bg-gray-50 dark:bg-gray-900">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <a
          href="#"
          className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white"
        >
          <img className="h-8 mr-2" src={ClearerLogo} alt="logo" />
        </a>
        <div className="w-full p-6 bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md dark:bg-gray-800 dark:border-gray-700 sm:p-8">
          <h2 className="mb-1 text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
            Forgot Password?
          </h2>
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="mt-4 space-y-4 lg:mt-5 md:space-y-5"
            >
              <FormInput
                control={form.control}
                name="password"
                label="New Password"
                type="password"
                autoComplete="new-password"
                disabled={!code}
              />
              <FormInput
                control={form.control}
                name="confirmPassword"
                label="Confirm Password"
                type="password"
                autoComplete="new-password"
                disabled={!code}
              />
              <Button
                size="lg"
                variant="default"
                type="submit"
                className="w-full"
                disabled={!code}
                loading={forgotPasswordMutation.isPending}
              >
                {code ? "Reset Password" : "Invalid route"}
              </Button>
            </form>
          </Form>
        </div>
      </div>
    </section>
  );
}

function GetForgotPassword({ email }: { email?: string }) {
  const forgotPasswordMutation = useForgotPasswordLink();
  const form = useForm<z.infer<typeof GetForgotPasswordSchema>>({
    resolver: zodResolver(GetForgotPasswordSchema),
    defaultValues: {
      email_address: email ?? "",
    },
  });
  const onSubmit = (data: z.infer<typeof GetForgotPasswordSchema>) => {
    forgotPasswordMutation.mutate(
      {
        email_address: data.email_address,
      },
      {
        onSuccess(data) {
          toast(data.message);
        },
      }
    );
  };

  return (
    <section className="bg-gray-50 dark:bg-gray-900">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <a
          href="#"
          className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white"
        >
          <img className="h-8 mr-2" src={ClearerLogo} alt="logo" />
        </a>
        <div className="w-full p-6 bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md dark:bg-gray-800 dark:border-gray-700 sm:p-8">
          <h2 className="mb-1 text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
            Get Reset Password Instructions
          </h2>
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="mt-4 space-y-4 lg:mt-5 md:space-y-5"
            >
              <FormInput
                control={form.control}
                name="email_address"
                label="Email Address"
                type="email"
                autoComplete="email webauthn"
              />
              <Button
                size="lg"
                variant="default"
                type="submit"
                className="w-full"
                loading={forgotPasswordMutation.isPending}
              >
                Reset Password
              </Button>
            </form>
          </Form>
        </div>
      </div>
    </section>
  );
}
