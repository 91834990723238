import { SVGProps } from "react";

const HomeIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.0522 21.75H6.9472C5.3762 21.75 4.0312 20.628 3.7492 19.083L2.29424 11.0999C2.11624 10.1189 2.48323 9.11599 3.25323 8.483L9.93523 2.98995C11.1322 2.00595 12.8692 2.00595 14.0662 2.98995L20.7482 8.483C21.5182 9.11599 21.8852 10.1189 21.7072 11.0999L20.2523 19.083C19.9683 20.628 18.6232 21.75 17.0522 21.75ZM11.9992 3.75094C11.6032 3.75094 11.2091 3.88291 10.8871 4.14791L4.20525 9.64095C3.85425 9.92895 3.68722 10.385 3.76922 10.831L5.22417 18.813C5.37517 19.645 6.10022 20.249 6.94622 20.249H17.0512C17.8972 20.249 18.6212 19.645 18.7732 18.813L20.2282 10.8299C20.3092 10.3839 20.1422 9.92895 19.7922 9.64095L13.1102 4.14791C12.7892 3.88391 12.3952 3.75094 11.9992 3.75094ZM19.5121 18.948H19.5221H19.5121ZM14.7492 17C14.7492 16.586 14.4132 16.25 13.9992 16.25H9.9992C9.5852 16.25 9.2492 16.586 9.2492 17C9.2492 17.414 9.5852 17.75 9.9992 17.75H13.9992C14.4132 17.75 14.7492 17.414 14.7492 17Z"
        fill="currentcolor"
      />
    </svg>
  );
};

export default HomeIcon;
