import { Account } from "@/domains/organizations";
import {
  accountsQueryOptions,
  organizationQueryOptions,
} from "@/domains/organizations/hooks";
import { getOnboardingLevel } from "@/domains/organizations/utils";
import { useSuspenseQuery } from "@tanstack/react-query";
import { Link, useRouter } from "@tanstack/react-router";
import { PlusCircleIcon } from "lucide-react";

function SelectBusinessItem({ account }: { account: Account.Doc }) {
  const router = useRouter();
  const organization = account.organization;
  const organizationId = organization.id;

  const { data } = useSuspenseQuery(organizationQueryOptions(organizationId));

  const onClick = () => {
    if (organization.status === "onboarding") {
      const level = getOnboardingLevel(data);
      router.navigate({
        to: `/organization/$organizationId/onboarding/${level}`,
        params: { organizationId: organization.id },
      });
      return;
    }

    return router.navigate({
      to: "/organization/$organizationId/dashboard",
      params: { organizationId: organization.id },
    });
  };
  return (
    <div
      onClick={onClick}
      className="bg-grey-off border rounded-[0.5rem] px-[1.125rem] py-[0.625rem] cursor-pointer"
    >
      <div className="border-b-2 border-dashed border-text-text-default py-[0.5rem]">
        <p className="font-semibold text-[1rem]">Company name</p>
        <p className="font-medium text-[0.875rem] text-grey-background">
          {account.organization.company_name}
        </p>
      </div>
      <div className="pt-[0.5rem] flex gap-[0.625rem] font-medium">
        <p className="text-grey-background">status</p>
      </div>
    </div>
  );
}

function EmptyState() {
  return (
    <div className="flex flex-col items-center justify-center py-5">
      <p>You have no registered business.</p>
    </div>
  );
}

export function SelectBusiness() {
  const { data } = useSuspenseQuery(accountsQueryOptions());
  const accounts = data?.data ?? [];
  const isEmpty = accounts.length === 0;

  return (
    <div className="flex items-center  justify-center py-10">
      <div className=" grid w-full max-w-lg gap-4 border bg-background p-6 shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-[1.875rem]">
        <p className="text-lg font-bold">
          {isEmpty ? "Create New Business" : "Select Business"}
        </p>

        {isEmpty ? <EmptyState /> : null}
        {accounts?.map((account) => (
          <SelectBusinessItem key={account.id} account={account} />
        ))}

        <Link
          to="/create-business"
          className="flex py-2 items-center justify-center gap-2 cursor-pointer"
        >
          <PlusCircleIcon className="w-5 h-5" />
          <p>Create new business</p>
        </Link>
      </div>
    </div>
  );
}
