import { ClearerLogo } from "@/assets";

import {
  accountsQueryOptions,
  organizationQueryOptions,
} from "@/domains/organizations";

import HomeIcon from "@/icons/home";
import { SettingsIcon } from "@/icons/settings";
import { useAuthStore } from "@/internals/auth";

import { cn } from "@/lib/utils";

import { DashboardMenu } from "@/sections/dashboard/dashboard-menu";
import { useSuspenseQuery } from "@tanstack/react-query";
import { Link } from "@tanstack/react-router";

import { createFileRoute, Outlet, useParams } from "@tanstack/react-router";

const dashboardRoutes = [
  {
    label: "Home",
    route: "/",
    Icon: ({ isActive }: { isActive: boolean }) => (
      <HomeIcon color={isActive ? "#78B60F" : "#939393"} />
    ),
  },
  {
    label: "Settings",
    route: "/settings",
    Icon: ({ isActive }: { isActive: boolean }) => (
      <SettingsIcon color={isActive ? "#78B60F" : "#939393"} />
    ),
  },
] as const;

const DashboardLayout = () => {
  const organizationId = useParams({
    from: "/organization/$organizationId/_dashboard",
    select(params) {
      return params.organizationId;
    },
  });
  const user = useAuthStore((state) => state.user);
  const { data } = useSuspenseQuery(accountsQueryOptions());

  if (!user) {
    return null;
  }

  const businessOptions = data.data.map((account) => ({
    label: account.organization.company_name,
    value: account.organization.id,
  }));

  return (
    <main className="flex">
      <div className="md:w-[18.5rem] bg-white border-r border-grey-border h-screen  fixed  hidden md:flex flex-col items-center px-[1.125rem] ">
        <a
          href="https://clearerpay.com/"
          target="_blank"
          className="py-[1.0625rem]"
        >
          <img
            src={ClearerLogo}
            className="object-cover w-[8.3125rem] h-[2.875rem]"
          />
        </a>
        <div className=" w-full pt-[2.5rem] space-y-3">
          {dashboardRoutes?.map(({ Icon, label, route }) => {
            return (
              <Link
                key={label}
                to={`/organization/$organizationId/dashboard${route}`}
                params={{
                  organizationId: organizationId,
                }}
                className={cn(
                  "flex gap-[0.5rem] justify-start items-center  w-full h-[2.75rem] rounded-[0.25rem] px-[0.5rem] cursor-pointer text-grey-background"
                )}
                activeOptions={{
                  exact: true,
                }}
                activeProps={{
                  className: "bg-grey-light-background text-label font-bold",
                }}
              >
                {({ isActive }) => (
                  <>
                    <Icon isActive={isActive} />
                    <p className="font-semibold">{label}</p>
                  </>
                )}
              </Link>
            );
          })}
        </div>
      </div>
      <div className="md:pl-[18.5rem]  flex-1">
        <div className="min-h-[5rem] py-[1.625rem] bg-white flex  justify-between items-center md:pl-[1.5rem] pr-[2.875rem]">
          {/* TODO: replace */}
          <h2 className="font-[800] text-[1.5rem]"></h2>
          <div className="flex gap-2 ">
            <DashboardMenu user={user} businessOptions={businessOptions} />
          </div>
        </div>
        <Outlet />
      </div>
    </main>
  );
};

export const Route = createFileRoute(
  "/organization/$organizationId/_dashboard"
)({
  component: DashboardLayout,
  loader: (opts) =>
    opts.context.queryClient
      // fetch account
      .ensureQueryData(accountsQueryOptions())
      .then((data) =>
        Promise.all(
          // fetch all data related to the organization
          data.data?.map((account) =>
            opts.context.queryClient.ensureQueryData(
              organizationQueryOptions(account.organization.id)
            )
          )
        )
      ),
});
