import { SVGProps } from "react";

import { DEFAULT_ANIMATION_CONFIG } from "@/config/animation";
import { animated, useSpring } from "@react-spring/web";

interface FileProps extends SVGProps<SVGSVGElement> {
  isHovered?: boolean;
}

const File = ({ isHovered, ...props }: FileProps) => {
  const { x } = useSpring({
    from: { x: 0 },
    x: isHovered ? 1 : 0,
    config: {
      duration: DEFAULT_ANIMATION_CONFIG.duration,
      easing: DEFAULT_ANIMATION_CONFIG.easing,
    },
  });

  const svgStyle = {
    fill: x.to({ range: [0, 1], output: ["#D9D9D9", "#78B60F"] }),
  };

  return (
    <svg
      width="77"
      height="76"
      viewBox="0 0 77 76"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <animated.path
        d="M24 17C24 14.2386 26.2386 12 29 12H41.0464C42.1773 12 43.2749 12.3834 44.1599 13.0877L57.3952 23.6205C58.5741 24.5587 59.267 25.9783 59.2815 27.4848L59.4687 46.9519C59.4954 49.732 57.2492 52 54.4689 52H29C26.2386 52 24 49.7614 24 47V17Z"
        style={svgStyle}
      />
      <path
        d="M29.2578 22C29.2578 19.7909 31.0487 18 33.2578 18H40.6059C41.5107 18 42.3887 18.3067 43.0967 18.8701L52.2157 26.1271C53.1587 26.8776 53.7131 28.0134 53.7247 29.2185L53.8473 41.9615C53.8686 44.1856 52.0716 46 49.8474 46H33.2578C31.0487 46 29.2578 44.2091 29.2578 42V22Z"
        stroke="white"
        strokeWidth="2"
      />
      <animated.path
        d="M6.04894 24.927C6.3483 24.0057 7.6517 24.0057 7.95106 24.927L8.34708 26.1459C8.48096 26.5579 8.86491 26.8369 9.29814 26.8369H10.5797C11.5484 26.8369 11.9512 28.0765 11.1675 28.6459L10.1307 29.3992C9.7802 29.6538 9.63354 30.1052 9.76741 30.5172L10.1634 31.7361C10.4628 32.6574 9.40831 33.4235 8.6246 32.8541L7.58778 32.1008C7.2373 31.8462 6.7627 31.8462 6.41221 32.1008L5.3754 32.8541C4.59169 33.4235 3.53721 32.6574 3.83656 31.7361L4.23259 30.5172C4.36646 30.1052 4.2198 29.6538 3.86932 29.3992L2.8325 28.6459C2.04879 28.0765 2.45156 26.8369 3.42029 26.8369H4.70186C5.13509 26.8369 5.51904 26.5579 5.65292 26.1459L6.04894 24.927Z"
        style={svgStyle}
      />
      <animated.path
        d="M37.0489 60.9271C37.3483 60.0057 38.6517 60.0057 38.9511 60.9271L39.7961 63.5279C39.93 63.9399 40.3139 64.2188 40.7472 64.2188H43.4818C44.4505 64.2188 44.8533 65.4585 44.0696 66.0279L41.8572 67.6353C41.5067 67.8899 41.3601 68.3413 41.494 68.7533L42.339 71.3541C42.6384 72.2754 41.5839 73.0415 40.8002 72.4721L38.5878 70.8647C38.2373 70.6101 37.7627 70.6101 37.4122 70.8647L35.1998 72.4721C34.4161 73.0415 33.3616 72.2754 33.661 71.3541L34.506 68.7533C34.6399 68.3413 34.4933 67.8899 34.1428 67.6353L31.9304 66.0279C31.1467 65.4585 31.5495 64.2188 32.5182 64.2188H35.2528C35.6861 64.2188 36.07 63.9399 36.2039 63.5279L37.0489 60.9271Z"
        style={svgStyle}
      />
      <path
        d="M8.04894 44.927C8.3483 44.0057 9.6517 44.0057 9.95106 44.9271L10.5716 46.8369C10.7055 47.2489 11.0894 47.5279 11.5227 47.5279H13.5308C14.4995 47.5279 14.9023 48.7675 14.1186 49.3369L12.494 50.5172C12.1435 50.7719 11.9968 51.2232 12.1307 51.6353L12.7512 53.5451C13.0506 54.4664 11.9961 55.2325 11.2124 54.6631L9.58778 53.4828C9.2373 53.2281 8.7627 53.2281 8.41221 53.4828L6.78761 54.6631C6.0039 55.2325 4.94942 54.4664 5.24878 53.5451L5.86932 51.6353C6.00319 51.2232 5.85653 50.7719 5.50604 50.5172L3.88144 49.3369C3.09773 48.7675 3.50051 47.5279 4.46923 47.5279H6.47735C6.91057 47.5279 7.29453 47.2489 7.4284 46.8369L8.04894 44.927Z"
        fill="#EEEFF0"
      />
      <path
        d="M10.0489 4.92705C10.3483 4.00574 11.6517 4.00574 11.9511 4.92705L12.1226 5.45491C12.2564 5.86694 12.6404 6.1459 13.0736 6.1459H13.6287C14.5974 6.1459 15.0002 7.38551 14.2164 7.95492L13.7674 8.28115C13.4169 8.5358 13.2703 8.98716 13.4041 9.39919L13.5757 9.92705C13.875 10.8484 12.8205 11.6145 12.0368 11.0451L11.5878 10.7188C11.2373 10.4642 10.7627 10.4642 10.4122 10.7188L9.96319 11.0451C9.17947 11.6145 8.12499 10.8484 8.42435 9.92705L8.59586 9.39919C8.72973 8.98716 8.58307 8.5358 8.23259 8.28115L7.78356 7.95492C6.99985 7.38551 7.40262 6.1459 8.37135 6.1459H8.92637C9.3596 6.1459 9.74356 5.86694 9.87743 5.45491L10.0489 4.92705Z"
        fill="#EEEFF0"
      />
      <path
        d="M67.0489 50.9271C67.3483 50.0057 68.6517 50.0057 68.9511 50.9271L69.7961 53.5279C69.93 53.9399 70.3139 54.2188 70.7472 54.2188H73.4818C74.4505 54.2188 74.8533 55.4585 74.0696 56.0279L71.8572 57.6353C71.5067 57.8899 71.3601 58.3413 71.494 58.7533L72.339 61.3541C72.6384 62.2754 71.5839 63.0415 70.8002 62.4721L68.5878 60.8647C68.2373 60.6101 67.7627 60.6101 67.4122 60.8647L65.1998 62.4721C64.4161 63.0415 63.3616 62.2754 63.661 61.3541L64.506 58.7533C64.6399 58.3413 64.4933 57.8899 64.1428 57.6353L61.9304 56.0279C61.1467 55.4585 61.5495 54.2188 62.5182 54.2188H65.2528C65.6861 54.2188 66.07 53.9399 66.2039 53.5279L67.0489 50.9271Z"
        fill="#EEEFF0"
      />
      <animated.path
        d="M47.0489 2.92705C47.3483 2.00574 48.6517 2.00574 48.9511 2.92705L49.3471 4.1459C49.481 4.55792 49.8649 4.83688 50.2981 4.83688H51.5797C52.5484 4.83688 52.9512 6.0765 52.1675 6.6459L51.1307 7.39919C50.7802 7.65383 50.6335 8.1052 50.7674 8.51722L51.1634 9.73607C51.4628 10.6574 50.4083 11.4235 49.6246 10.8541L48.5878 10.1008C48.2373 9.84617 47.7627 9.84617 47.4122 10.1008L46.3754 10.8541C45.5917 11.4235 44.5372 10.6574 44.8366 9.73607L45.2326 8.51722C45.3665 8.1052 45.2198 7.65383 44.8693 7.39919L43.8325 6.6459C43.0488 6.0765 43.4516 4.83688 44.4203 4.83688H45.7019C46.1351 4.83688 46.519 4.55792 46.6529 4.1459L47.0489 2.92705Z"
        style={svgStyle}
      />
    </svg>
  );
};

export { File };
