import { SVGProps } from "react";

const PlusIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      //   width="16"
      //   height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.75 8C15.75 8.414 15.414 8.75 15 8.75H8.75V15C8.75 15.414 8.414 15.75 8 15.75C7.586 15.75 7.25 15.414 7.25 15V8.75H1C0.586 8.75 0.25 8.414 0.25 8C0.25 7.586 0.586 7.25 1 7.25H7.25V1C7.25 0.586 7.586 0.25 8 0.25C8.414 0.25 8.75 0.586 8.75 1V7.25H15C15.414 7.25 15.75 7.586 15.75 8Z"
        fill="#78B60F"
      />
    </svg>
  );
};

export default PlusIcon;
