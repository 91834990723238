import {
  accountsQueryOptions,
  servicesQueryOptions,
} from "@/domains/organizations/hooks";
import { PendingComponent } from "@/sections/pending";
import { SelectBusiness } from "@/sections/select-business";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/select-business")({
  component: () => <SelectBusiness />,
  loader: (opts) =>
    opts.context.queryClient
      // fetch account
      .ensureQueryData(accountsQueryOptions())
      .then((data) => {
        if ((data?.data ?? [])?.length === 0) {
          return;
        }
        return Promise.all(
          // fetch all data related to the organization
          data.data?.map((account) =>
            opts.context.queryClient.ensureQueryData(
              servicesQueryOptions(account.organization.id)
            )
          )
        );
      }),
  pendingComponent: PendingComponent,
  // Do not cache this route's data after it's unloaded
  gcTime: 0,
});
