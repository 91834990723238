import { z } from "zod";

export const AddressSchema = z.object({
  address: z.string({ required_error: "Required" }).min(2),
});

export const InternalFileSchema = z.object(
  {
    id: z.string().trim().min(1, "Required"),
    name: z.string().trim().min(1, "Required"),
    path: z.string().trim().min(1, "Required"),
    url: z.string().url({ message: "must be url" }),
  },
  { invalid_type_error: "File required" }
);
