import { queryOptions, useMutation } from "@tanstack/react-query";
import { getSession, forgotPassword, forgotPasswordLink, signIn } from "./api";
import { SESSION_MUTATION_KEYS, SESSION_QUERY_KEYS } from "./constants";

interface Options {
  abortController?: AbortController;
}

export function useSignIn() {
  return useMutation({
    mutationKey: SESSION_MUTATION_KEYS.signIn,
    mutationFn: signIn,
  });
}

export function sessionQueryOptions(options?: Options) {
  return queryOptions({
    queryKey: SESSION_QUERY_KEYS.sessions,
    queryFn: async () => getSession(options?.abortController),
  });
}

export function useForgotPassword() {
  return useMutation({
    mutationKey: SESSION_MUTATION_KEYS.forgotPassword,
    mutationFn: forgotPassword,
  });
}

export function useForgotPasswordLink() {
  return useMutation({
    mutationKey: SESSION_MUTATION_KEYS.forgotPasswordLink,
    mutationFn: forgotPasswordLink,
  });
}
