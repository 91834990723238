import { SVGProps } from "react";

const CircleCloseIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11 0.25C5.072 0.25 0.25 5.073 0.25 11C0.25 16.927 5.072 21.75 11 21.75C16.928 21.75 21.75 16.927 21.75 11C21.75 5.073 16.928 0.25 11 0.25ZM11 20.25C5.899 20.25 1.75 16.101 1.75 11C1.75 5.899 5.899 1.75 11 1.75C16.101 1.75 20.25 5.899 20.25 11C20.25 16.101 16.101 20.25 11 20.25ZM14.53 8.53003L12.06 11L14.53 13.47C14.823 13.763 14.823 14.238 14.53 14.531C14.384 14.677 14.192 14.751 14 14.751C13.808 14.751 13.616 14.678 13.47 14.531L11 12.061L8.53 14.531C8.384 14.677 8.192 14.751 8 14.751C7.808 14.751 7.616 14.678 7.47 14.531C7.177 14.238 7.177 13.763 7.47 13.47L9.94 11L7.47 8.53003C7.177 8.23703 7.177 7.76199 7.47 7.46899C7.763 7.17599 8.23801 7.17599 8.53101 7.46899L11.001 9.93903L13.471 7.46899C13.764 7.17599 14.239 7.17599 14.532 7.46899C14.823 7.76199 14.823 8.23803 14.53 8.53003Z"
        fill="#939393"
      />
    </svg>
  );
};

export { CircleCloseIcon };
