import { Settings } from "@/domains/settings/types";
import { InputProps } from "react-day-picker";
import { Control, FieldPath, FieldValues } from "react-hook-form";
import { FormTextArea } from "../form-textarea";

interface FormInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends InputProps {
  control: Control<TFieldValues>;
  name: TName;
  label?: React.ReactNode;
  onPlaceSelected?: (place: Settings.MapPlace) => void;
}

export function AddressInput<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({ control, name, label }: FormInputProps<TFieldValues, TName>) {
  // const { ref } = usePlacesWidget<HTMLInputElement>({
  //   apiKey: GOOGLE_MAPS_API_KEY,
  //   onPlaceSelected: onPlaceSelected,
  //   libraries: ["places"],
  //   options: {
  //     //   componentRestrictions: { country: "ng" },
  //     types: ["geocode"],
  //   },
  // });
  return (
    // <FormInput
    //   control={control}
    //   name={name}
    //   label={label}
    //   {...props}
    //   // ref={ref}
    // />
    <FormTextArea name={name} control={control} label={label} />
  );
}
