import _ from "lodash";
import { Organization } from "./types";

type OnboardingLevel =
  | "purpose-of-account"
  | "company-structure"
  | "supporting-documents";

// getOnboardingLevel
export function getOnboardingLevel(
  data: Organization.OrganizationResponse["data"]
): OnboardingLevel {
  if (_.isEmpty(data.documents)) {
    return "supporting-documents";
  }

  if (_.isEmpty(data.services)) {
    return "purpose-of-account";
  }

  if (_.isEmpty(data.affiliates)) {
    return "company-structure";
  }
  return "purpose-of-account";
}

function isNGNAccount(
  account: Organization.SettlementAccount
): account is Organization.NGNAccount {
  return account.currency === "NGN";
}

function isGBPAccount(
  account: Organization.SettlementAccount
): account is Organization.GBPAccount {
  return account.currency === "GBP";
}

function isUSDAccount(
  account: Organization.SettlementAccount
): account is Organization.USDAccount {
  return account.currency === "USD";
}

function isEURAccount(
  account: Organization.SettlementAccount
): account is Organization.EURAccount {
  return account.currency === "EUR";
}
export function mapSettlementAccounts(
  settlementAccounts?: Organization.SettlementAccount[]
) {
  if (_.isEmpty(settlementAccounts) || _.isNil(settlementAccounts)) {
    return null;
  }
  const ngn_account: Organization.NGNAccount[] = settlementAccounts
    ?.filter(isNGNAccount)
    .map((acct) => ({
      account_name: acct.account_name,
      bank_name: acct.bank_name,
      account_number: acct.account_number,
      country: acct.country,
      currency: "NGN",
      bank_code: acct.bank_code as string,
    }));
  const gbp_account: Organization.GBPAccount[] = settlementAccounts
    ?.filter(isGBPAccount)
    .map((account) => {
      const base = {
        country: account.country,
        account_name: account.account_name,
        bank_name: account.bank_name,
        currency: account.currency,
      } as const;

      if (account.country === "GB") {
        return {
          ...base,
          country: account.country,
          account_number: account.account_number,
          sort_code: account.sort_code as string,
        };
      } else {
        return {
          ...base,
          country: account.country,
          swift: String(account.swift),
          iban: String(account.iban),
        };
      }
    });
  const eur_account: Organization.EURAccount[] = settlementAccounts
    ?.filter(isEURAccount)
    .map((account) => {
      const base = {
        country: account.country,
        account_name: account.account_name,
        bank_name: account.bank_name,
        currency: account.currency,
      } as const;

      if (account.country === "GB") {
        return {
          ...base,
          country: account.country,
          account_number: account.account_number,
          sort_code: account.sort_code as string,
        };
      } else {
        return {
          ...base,
          country: account.country,
          swift: String(account.swift),
          iban: String(account.iban),
        };
      }
    });
  const usd_account: Organization.USDAccount[] = settlementAccounts
    .filter(isUSDAccount)
    .map((account) => {
      const base = {
        currency: "USD",
        country: account.country,
        account_name: account.account_name,
        bank_name: account.bank_name,
      } as const;

      switch (account.type) {
        case "swift":
          return {
            ...base,
            type: account.type,
            swift: account.swift,
            iban: account.iban,
          };
        default:
          return {
            ...base,
            type: account.type,
            account_routing_number: account.account_routing_number,
            account_number: account.account_number,
          };
      }
    });

  return { ngn_account, gbp_account, eur_account, usd_account };
}
