import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";

import { Button } from "@/components/ui/button";
import PlusIcon from "@/icons/plus";

import React from "react";

interface OnboardingAccordionProps {
  name: string;
  openFormDrawer: () => void;
  children: React.ReactNode;

  buttonLabel: string;
}

const OnboardingAccordion = ({
  name,
  children,
  openFormDrawer,
  buttonLabel,
}: OnboardingAccordionProps) => {
  // const formTriggerRef = useRef<HTMLButtonElement>(null);
  // const openForm = () => {
  //   if (formTriggerRef.current) {
  //     formTriggerRef.current.click();
  //   }
  // };
  return (
    <>
      <Accordion type="single" collapsible className="w-full">
        <AccordionItem
          value="item-1"
          className={"border p-[0.75rem] rounded-[0.625rem]"}
        >
          <AccordionTrigger className=" py-0  font-[700] text-[1.5rem] leading-[1.95rem] hover:no-underline">
            {name}
          </AccordionTrigger>
          <AccordionContent className={"pt-[0.75rem]"}>
            {children}

            <Button
              variant={"secondary"}
              type="button"
              className="flex gap-[0.5rem] items-center h-[3.5rem] mt-[0.75rem]"
              onClick={() => {
                // openForm();
                openFormDrawer();
              }}
            >
              <p>{buttonLabel}</p>
              <PlusIcon width={16} height={16} />{" "}
            </Button>
          </AccordionContent>
        </AccordionItem>
      </Accordion>

      {/* <OnboardingItemDrawer
        key={"form"}
        ref={formTriggerRef}
        heading="Add currency"
        subTitle="Desired currency pairs"
      >
        {form}
      </OnboardingItemDrawer> */}
    </>
  );
};

export default OnboardingAccordion;
