import React from "react";

interface ErrorTextProps {
  text?: string;
}

const ErrorText: React.FC<ErrorTextProps> = ({ text }) => {
  return text ? <p className="text-destructive text-sm">{text}</p> : null;
};

export { ErrorText };
