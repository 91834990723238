import { SVGProps } from "react";

const Eye = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.2355 6.13794C17.9225 3.93894 14.9754 0.25 10.0004 0.25C5.02536 0.25 2.07825 3.93894 0.76525 6.13794C0.07825 7.28594 0.07825 8.71306 0.76525 9.86206C2.07825 12.0611 5.02536 15.75 10.0004 15.75C14.9754 15.75 17.9225 12.0611 19.2355 9.86206C19.9225 8.71306 19.9225 7.28694 19.2355 6.13794ZM17.9484 9.09204C16.7984 11.018 14.2354 14.25 10.0004 14.25C5.76536 14.25 3.20236 11.019 2.05236 9.09204C1.65036 8.41804 1.65036 7.58098 2.05236 6.90698C3.20236 4.98098 5.76536 1.74902 10.0004 1.74902C14.2354 1.74902 16.7984 4.97998 17.9484 6.90698C18.3514 7.58198 18.3514 8.41804 17.9484 9.09204ZM10.0004 3.75C7.65636 3.75 5.75036 5.657 5.75036 8C5.75036 10.343 7.65636 12.25 10.0004 12.25C12.3444 12.25 14.2504 10.343 14.2504 8C14.2504 5.657 12.3444 3.75 10.0004 3.75ZM10.0004 10.75C8.48336 10.75 7.25036 9.517 7.25036 8C7.25036 6.483 8.48336 5.25 10.0004 5.25C11.5174 5.25 12.7504 6.483 12.7504 8C12.7504 9.517 11.5174 10.75 10.0004 10.75Z"
        fill="#25314C"
      />
    </svg>
  );
};

export default Eye;
