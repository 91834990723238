import { FormDatePicker } from "@/components/form-date-picker";
import { FormInput } from "@/components/form-input";
import { FormSingleComboBox } from "@/components/form-single-combo-box";
import { SubmitHandler, UseFormReturn, useWatch } from "react-hook-form";
import { companyStructureSchemaDTO } from "./validator";

import FormFileInput from "@/components/form-file-input";
import { AddressInput } from "@/components/ui/address-input";

import { countriesDrownDownData } from "@/i18n/countries-data";

import { FormPhoneInput } from "@/components/form-phone-input";
import { onPlaceSelectedFn } from "@/internals/address";
import FormCheckbox from "@/components/form-checkbox";
import { Form } from "@/components/ui/form";

const AddDirector = ({
  newDirectorForm,
  onSubmit,
  isEditMode,
}: {
  newDirectorForm: UseFormReturn<companyStructureSchemaDTO["directors"][0]>;
  onSubmit: SubmitHandler<companyStructureSchemaDTO["directors"][0]>;
  isEditMode?: boolean;
}) => {
  const director = useWatch({
    control: newDirectorForm.control,
  });
  const onPlaceSelected = onPlaceSelectedFn(
    newDirectorForm.setValue,
    `address`
  );

  return (
    <Form {...newDirectorForm}>
      <form
        id={"director_form"}
        onSubmit={(e) => {
          e.stopPropagation();
          newDirectorForm.handleSubmit(onSubmit)(e);
        }}
        className="pb-[1.5rem] mt-[38px] w-full flex flex-col gap-[24px]"
      >
        <div className="flex items-center justify-between gap-4">
          <FormInput
            name={`first_name`}
            label="First Name"
            control={newDirectorForm.control}
          />
          <FormInput
            name={`last_name`}
            label="Last Name"
            control={newDirectorForm.control}
          />
        </div>

        <FormDatePicker
          control={newDirectorForm.control}
          label="Date of birth"
          name={`date_of_birth`}
        />

        <AddressInput
          label="Residential Address"
          control={newDirectorForm.control}
          name={`address.address`}
          onPlaceSelected={onPlaceSelected}
        />

        <FormSingleComboBox
          label="Nationality"
          data={countriesDrownDownData}
          control={newDirectorForm.control}
          name={`nationality`}
        />

        <FormInput
          name={`email`}
          label="Email Address"
          control={newDirectorForm.control}
        />

        <FormPhoneInput
          name={`phone_number`}
          label="Phone Number"
          defaultCountry={"NG"}
          control={newDirectorForm.control}
        />

        <FormFileInput
          label="Upload ID"
          maxSize={10}
          name={`verification`}
          control={newDirectorForm.control}
        />

        <FormFileInput
          label="Upload Proof of Address (Billed 90 days)"
          maxSize={10}
          name={`proof_of_address`}
          control={newDirectorForm.control}
        />

        {!isEditMode ? (
          <>
            {" "}
            <FormCheckbox
              control={newDirectorForm.control}
              className="cursor-pointer"
              labelClassName="text-sm"
              name={`isShareholder`}
              label="Director is also a shareholder"
            />
            {director.isShareholder ? (
              <FormInput
                label="Shareholding (0% - 100%)"
                type="number"
                name={`stakeholder_shareholding`}
                control={newDirectorForm.control}
              />
            ) : null}
          </>
        ) : null}
      </form>
    </Form>
  );
};

export default AddDirector;
