import { z } from "zod";

export const ServiceSchema = z.array(
  z.object({
    pair: z.string().trim().min(1, "Required"),
    expected_monthly_volume: z.string().trim().min(1, "Required"),
    expected_annual_volume: z.string().trim().min(1, "Required"),
  })
);

export const purposeOfAccountDtoSchema = z
  .object({
    cross_border: ServiceSchema,
    currency_swap: ServiceSchema,
    business_account: ServiceSchema,
    // cash_management: RequiredServiceSchema,
  })
  .partial();

export type ServiceSchemaDtoSchema = z.infer<typeof ServiceSchema>;

export type purposeOfAccountDtoSchema = z.infer<
  typeof purposeOfAccountDtoSchema
>;
