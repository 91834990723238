import ApplicationReviewInProgress from "@/sections/dashboard/application-review-in-progress";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute(
  "/organization/$organizationId/_dashboard/dashboard/"
)({
  component: () => (
    <div>
      <ApplicationReviewInProgress />
    </div>
  ),
});
