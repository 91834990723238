import { SVGProps } from "react";

const ChevronLeftIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="6"
      height="9"
      viewBox="0 0 6 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.49983 8.06258C4.35583 8.06258 4.21181 8.00785 4.10231 7.8976L1.10231 4.8976C0.882562 4.67785 0.882562 4.32157 1.10231 4.10182L4.10231 1.10182C4.32206 0.882074 4.67834 0.882074 4.89809 1.10182C5.11784 1.32157 5.11784 1.67785 4.89809 1.8976L2.29561 4.50008L4.89809 7.10256C5.11784 7.32231 5.11784 7.67858 4.89809 7.89833C4.78784 8.00783 4.64383 8.06258 4.49983 8.06258Z"
        fill="#131313"
      />
    </svg>
  );
};

export default ChevronLeftIcon;
