export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function getFirstString(str: string) {
  return str.charAt(0).toUpperCase();
}

export function truncateString(str: string, length = 25) {
  if (str.length > length) {
    return str.substring(0, length) + "...";
  }
  return str;
}
