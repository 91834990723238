import { useUploadFile } from "@/domains/settings";
import { Settings } from "@/domains/settings/types";
import { File } from "@/icons/file";
import { Trash } from "@/icons/trash";
import useComponentFocus from "@/internals/hooks/useComponentFocus";
import { cn } from "@/lib/utils";
import * as React from "react";
import { DropzoneRootProps, useDropzone } from "react-dropzone";

import { toast } from "sonner";

import { Progress } from "./progress";

import _ from "lodash";
import { XIcon } from "lucide-react";

type fileType = `image/${string}` | `application/pdf`;

export const FileTypes = {
  pdf: "application/pdf",
  jpeg: "image/jpeg",
  png: "image/png",
  jpg: "image/jpg",
} as const;

interface FileUploadProps {
  inputStyles?: string;
  inputName: string;
  maxSize?: number;
  acceptedTypes?: fileType[];
  label?: string;
  onUploadComplete?: (fileData: Settings.InternalFile | null) => void;
  value?: Settings.InternalFile | null;
}

interface InputDisplayProps {
  onClick: () => unknown;
  size: string;
  getRootProps: () => DropzoneRootProps;
}

function InputDisplay({ onClick, size, getRootProps }: InputDisplayProps) {
  const { focused: hovered, ...rest } = useComponentFocus();
  return (
    <div
      className="flex  gap-4 items-center max-h-[96px] min-w-[500px] w-full cursor-pointer p-6 "
      onClick={onClick}
      {...getRootProps()}
      {...rest}
    >
      <File isHovered={hovered} />
      <div>
        <p className="text-text-input-placeholder font-medium text-sm">
          Click to upload or drop a file here to upload
        </p>
        <p className="mt-2 text-text-input-placeholder font-normal text-xs">
          Max file size: {size}
        </p>
      </div>
    </div>
  );
}

const FileUpload = ({
  inputStyles,
  inputName,
  maxSize: _maxSize = 5,
  acceptedTypes = [FileTypes.jpeg, FileTypes.png, FileTypes.jpg],
  label,
  onUploadComplete,
  value: _value,
}: FileUploadProps) => {
  const maxSize = _maxSize * 1024 * 1024;

  const value =
    _.isEmpty(_value) || _.values(_value).every((v) => v === "")
      ? null
      : _value;
  const fileType = value?.name?.split(".")?.pop() || "--";
  const fileName = value?.name ?? "--";

  const [progress, setProgress] = React.useState<number | null>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const { mutation: uploadFile, controller } = useUploadFile();

  const size = maxSize ? `${maxSize / (1024 * 1024)} MB` : "unlimited";

  const handleUploadClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    } else {
      console.error("Input field not found");
    }
  };

  const reset = React.useCallback(() => {
    setProgress(null);
    onUploadComplete?.(null);
  }, [onUploadComplete]);

  const handleFiles = React.useCallback(
    async (files: (FileList | File[]) | null) => {
      if (files && files.length > 0) {
        const selectedFile = files[0];
        if (maxSize && selectedFile.size > maxSize) {
          toast.error("File size exceeds maximum allowed size");
          return;
        }

        if (!acceptedTypes.includes(selectedFile.type as fileType)) {
          toast.error("File type not allowed");
          return;
        }
        const data = new FormData();
        data.append("file", selectedFile);
        try {
          uploadFile.mutate(
            {
              formData: data,
              onUploadProgress: function (progressEvent) {
                const percentCompleted = Math.round(
                  (progressEvent.loaded / (progressEvent?.total ?? 1)) * 100
                );
                setProgress(percentCompleted);
              },
            },

            {
              onSuccess: (res) => {
                onUploadComplete?.(res.data.data.data);
              },
            }
          );
        } catch (error) {
          console.log(error);
        }
      } else {
        // File was cleared
        reset();
      }
    },
    [acceptedTypes, maxSize, onUploadComplete, reset, uploadFile]
  );

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleFiles(event.target.files);
  };

  const trimFilename = (filename: string) => {
    const maxLength = 20; // Maximum length for filename before trimming
    if (filename.length > maxLength) {
      return filename.substring(0, maxLength - 3) + "..."; // Trim filename and add ellipsis
    }
    return filename;
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop: handleFiles });

  return (
    <>
      <div className="flex flex-col gap-[14px]">
        <p className="font-medium text-lg">{label}</p>
        <p className="font-medium text-xs text-gray-400">
          Supported file format jpg*, jpeg*, png*
        </p>

        <div
          className={cn(
            "bg-grey-light flex flex-col justify-center items-center border-2 border-[#ECECEC] rounded-[20px]",
            inputStyles
          )}
        >
          <input
            type="file"
            name={inputName}
            hidden // Hide the input visually
            {...getInputProps()}
            ref={inputRef}
            onChange={handleFileChange}
            accept={acceptedTypes?.join(",")}
          />

          {value ? (
            <>
              {
                <WithFile
                  fileName={trimFilename(fileName)}
                  fileType={fileType}
                  onUploadComplete={onUploadComplete}
                  reset={reset}
                />
              }
            </>
          ) : progress && uploadFile.isPending ? (
            <UploadInProgressView
              fileName={"Loading..."}
              fileType={fileType}
              progress={progress}
              controller={controller}
            />
          ) : (
            <InputDisplay
              getRootProps={getRootProps}
              size={size}
              onClick={handleUploadClick}
            />
          )}
        </div>
      </div>
    </>
  );
};

export { FileUpload };

const UploadInProgressView = ({
  progress,
  fileName,
  fileType,
  controller,
}: {
  progress: number;
  fileName: string;
  fileType: string;
  controller?: AbortController;
}) => {
  return (
    <div className="min-w-[500px] w-full p-6 flex flex-col min-h-[90px]">
      <div className="flex items-center justify-between gap-2 mb-1">
        <div className="flex items-center gap-1">
          <div className="bg-[#EEFFD0] p-1 rounded-[6px] flex flex-col justify-center items-center">
            <p className="text-[#78B60F] uppercase font-semibold text-[0.75rem]">
              {fileType}
            </p>
          </div>
          <p className="text-sm">{fileName}</p>
        </div>
        <XIcon color="#DC0000" size={16} onClick={() => controller?.abort()} />
      </div>
      <Progress value={progress} color="green" />
    </div>
  );
};

const WithFile = ({
  fileType,
  fileName,
  reset,
  onUploadComplete,
}: {
  fileType: string;
  fileName: string;
  reset: () => void;
  onUploadComplete?: (fileData: Settings.InternalFile | null) => void;
}) => {
  return (
    <div className="min-w-[500px] w-full p-6 flex min-h-[90px] items-center justify-between">
      <div className="flex items-center gap-2">
        <div className="bg-[#EEFFD0] p-2 rounded-[6px] flex flex-col justify-center items-center">
          <p className="text-[#78B60F] uppercase font-semibold text-[0.75rem]">
            {fileType}
          </p>
        </div>
        <p className="text-base">{fileName}</p>
      </div>
      <Trash
        width={16}
        height={16}
        className="cursor-pointer"
        onClick={() => {
          reset();
          onUploadComplete?.(null); // Notify parent component that file is cleared
        }}
      />
    </div>
  );
};
