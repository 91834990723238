import * as React from "react";
import { ChevronDown } from "lucide-react";

import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import {
  DropdownMenuLabel,
  DropdownMenuSeparator,
} from "@/components/ui/dropdown-menu";
import { LoginIcon } from "@/icons/login-in";
import { MenuItem } from "@/components/ui/menu-item";
import { User } from "@/domains/users";
import { getFirstString } from "@/internals/strings";
import { Dialog } from "@/components/ui/dialog";
import SignOutModal from "@/components/sign-out-modal";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";

import { cn } from "@/lib/utils";

export function DashboardMenu({
  user,
  businessOptions,
}: {
  user: User.Doc;
  businessOptions: { label: string; value: string }[];
}) {
  const [open, setOpen] = React.useState(false);
  const [signoutIsOpen, setSignOutIsOpen] = React.useState(false);
  return (
    <>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <MenuItem
            role="combobox"
            aria-expanded={open}
            className="w-full flex justify-center items-center px-2.5 bg-grey hover:bg-grey-border rounded-[0.625rem]"
            size="lg"
            rightIcon={<ChevronDown width={"16px"} />}
          >
            <div className="bg-grey-light-background rounded-full w-[1.375rem] h-[1.375rem] p-1 flex items-center justify-center">
              <p className="text-[0.75rem] font-[500]">
                {getFirstString(user!.firstname)}
                {getFirstString(user!.lastname)}
              </p>
            </div>
            <p className="text-[0.875rem] font-[500] ml-[0.875rem] mr-[0.8rem]">
              {`${user?.firstname} ${user?.lastname}`}
            </p>
          </MenuItem>
        </PopoverTrigger>
        <PopoverContent
          className={cn("w-[20.25rem] p-3 mt-5 rounded-xl")}
          align="end"
        >
          <DropdownMenuLabel>Switch Organizations</DropdownMenuLabel>

          <Command className="w-full ">
            <CommandList>
              <CommandInput placeholder="Search..." />
              <CommandEmpty>No Organizations found</CommandEmpty>
              <CommandGroup className=" w-full mt-4">
                {businessOptions.map((item) => {
                  return (
                    <CommandItem
                      key={item.value}
                      className="gap-2 data-[disabled]:pointer-events-auto data-[disabled]:opacity-100 capitalize"
                      value={`${item.label}-${item.value}`}
                      onSelect={(currentValue) => {
                        if (currentValue) {
                          console.log(currentValue);
                        }
                      }}
                    >
                      <span>{item.label.toLowerCase()}</span>
                    </CommandItem>
                  );
                })}
              </CommandGroup>
            </CommandList>
          </Command>
          <DropdownMenuSeparator className="mt-10" />

          <div
            className="text-red-600 flex items-center  cursor-pointer"
            onClick={() => setSignOutIsOpen(true)}
          >
            <LoginIcon className="mr-2 h-4 w-4" />
            Signout
          </div>
        </PopoverContent>
      </Popover>
      <Dialog open={signoutIsOpen} onOpenChange={setSignOutIsOpen}>
        <SignOutModal />
      </Dialog>
    </>
  );
}
