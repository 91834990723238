import OnboardingSideNavigation from "@/sections/onboarding-side-nav";

import {
  Outlet,
  createFileRoute,
  useRouterState,
} from "@tanstack/react-router";

import { Progress } from "@/components/ui/progress";
import { DEFAULT_ANIMATION_CONFIG } from "@/config/animation";
import { OnboardingScreens, ONBOARDING_ITEMS } from "@/data/onboarding.data";
import { motion } from "framer-motion";

function OnboardingHeader({ children }: { children: React.ReactNode }) {
  const pathname = useRouterState().location.pathname;
  const routes = pathname.split("/");
  const routeName = routes[routes.length - 1] as OnboardingScreens;

  const data = ONBOARDING_ITEMS[routeName];

  if (!data) {
    return children;
  }

  return (
    <div className="flex flex-col w-[500px] max-w-[500px] mx-auto mt-5">
      <h1 className="text-2xl font-bold text-black mb-[10px]">{data.title}</h1>
      <p className="font-medium text-base text-grey-text mb-[10px]">
        {data.subTitle}
      </p>
      <Progress value={data.level * 25} className="h-1" />
      {children}
    </div>
  );
}

export const Route = createFileRoute("/organization/$organizationId/_onboarding")({
  // TODO: add not found to the layout
  component: OnboardingLayout,
});

function OnboardingLayout() {
  return (
    <div className="flex">
      <OnboardingSideNavigation />
      <main className="flex-1">
        <div className="flex flex-col md:ml-80 sm:border-r sm:border-zinc-700 min-h-screen">
          <div className="flex flex-col pt-2 px-4 space-y-2  flex-grow pb-4">
            <OnboardingHeader>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  // double of our normal to feel it
                  duration: (DEFAULT_ANIMATION_CONFIG.duration * 2) / 1000,
                  ease: DEFAULT_ANIMATION_CONFIG.easing,
                }}
              >
                <Outlet />
              </motion.div>
            </OnboardingHeader>
          </div>
        </div>
      </main>
    </div>
  );
}
