import * as Sentry from "@sentry/react";
import LogRocket from "logrocket";
import { initLogRocket } from "./logrocket";
import { initSentry } from "./sentry";
import { __DEV__ } from "./env";

export function initAnalyticsTools() {
  if (__DEV__) {
    return;
  }
  initSentry();
  initLogRocket();

  LogRocket.getSessionURL((sessionURL) => {
    Sentry.withScope((scope) => {
      scope.setExtra("sessionURL", sessionURL);
    });
  });
}
