export const QUERY_KEYS = {} as const;
export const ORGANIZATION_MUTATION_KEYS = {} as const;

export const ORGANIZATION_QUERY_KEYS = {
  organizations: ["organizations"],
  accounts: ["accounts"],
  settlementAccounts: (id: string) => [
    "organizations",
    id,
    "settlement_accounts",
  ],
  account: (id: string) => {
    return ["organizations", id, "account"];
  },
  services: (id: string) => {
    return ["organizations", id, "services"];
  },
  structure: (id: string) => {
    return ["organizations", id, "structure"];
  },

  documents: (id: string) => {
    return ["organizations", id, "documents"];
  },
  organization: (id: string) => {
    return ["organizations", id];
  },
  cac: (rc_number: string) => ["cac_lookup", rc_number],
} as const;

export const USD_ACCOUNT_TYPES = ["ach", "fed-wire", "swift"] as const;
