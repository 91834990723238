import { InternalFileSchema } from "@/internals/validators/form.validation";
import { z } from "zod";

export const SupportingDocumentSchema = z.object({
  incorporation_certificate: InternalFileSchema,
  memorandum: InternalFileSchema,
  register_of_shareholders: InternalFileSchema,
  register_of_directors: InternalFileSchema,
  proof_of_address: InternalFileSchema,
  business_operating_license: InternalFileSchema.nullable(),
});

export type supportingDocumentSchemaDTO = z.infer<
  typeof SupportingDocumentSchema
>;
