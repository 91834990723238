import { PlusIcon } from "@heroicons/react/20/solid";

import { useRef, useState } from "react";

import { InitShareholders } from "@/data/onboarding.data";

import {
  useFieldArray,
  useForm,
  useFormContext,
  useWatch,
} from "react-hook-form";

import { Button } from "@/components/ui/button";
import { zodResolver } from "@hookform/resolvers/zod";
import OnboardingAccordion from "../onboarding-accordions/onboarding-accordion";
import OnboardingAccordionContent from "../onboarding-accordions/onboarding-accordion-content";
import OnboardingItemCard from "../onboarding-accordions/onboarding-item-card";
import OnboardingItemDrawer from "../onboarding-accordions/onboarding-item-drawer";
import {
  companyStructureSchemaDTO,
  ShareholderSchemaDTO,
  ShareholdersSchema,
} from "./validator";

import AddShareholder from "./add-shareholder";
import { DrawerViews, DrawerViewsKeys } from "./types";
const ShareholderSection = () => {
  const drawerTriggerRef = useRef<HTMLButtonElement>(null);
  const closeDrawerButtonRef = useRef<HTMLButtonElement>(null);
  const [editingIndex, setEditingIndex] = useState<string>();

  const openDrawer = () => {
    if (drawerTriggerRef.current) {
      drawerTriggerRef.current.click();
    }
  };
  const { control } = useFormContext<companyStructureSchemaDTO>();
  const newShareholderForm = useForm<ShareholderSchemaDTO>({
    defaultValues: InitShareholders,
    // TODO: FIX VALIDATION
    resolver: zodResolver(ShareholdersSchema),
  });
  const { append, remove, update } = useFieldArray({
    control,
    name: "shareholders",
  });

  const fields = useWatch({ control, name: "shareholders" });

  const getFieldsMap = (shareholder: ShareholderSchemaDTO) => ({
    ...(shareholder.shareholder_type === "individual" && {
      [`${shareholder.first_name} ${shareholder.last_name}`]:
        shareholder.shareholder_type,
    }),
    ...(shareholder.shareholder_type === "corporate" && {
      [shareholder.name]: shareholder.shareholder_type,
    }),
    "Email address": shareholder.email,
    "Share holding": `${shareholder.stakeholder_shareholding}%`,
    Phone: shareholder.phone_number,
  });
  const handleClickEdit = (
    shareholder: ShareholderSchemaDTO,
    index: number
  ) => {
    setEditingIndex(String(index));
    Object.keys(shareholder).forEach((key) => {
      newShareholderForm.setValue(
        key as keyof ShareholderSchemaDTO,
        shareholder[key as keyof ShareholderSchemaDTO]
      );
    });

    setCurrView("edit");
  };
  const guardShareholding = (callback: () => void) => {
    const totalShareholding =
      fields.reduce((a, b) => a + Number(b.stakeholder_shareholding), 0) +
      Number(newShareholderForm.getValues("stakeholder_shareholding"));
    if (totalShareholding > 100) {
      newShareholderForm.setError("stakeholder_shareholding", {
        message: "Total shareholding of all shareholders cannot exceed 100%",
        type: "custom",
      });
    } else {
      newShareholderForm.clearErrors("stakeholder_shareholding");
      callback();
    }
  };
  const handleAddNewShareholder = () => {
    guardShareholding(() => {
      if (fields[0] && fields[0].email === "") {
        update(0, newShareholderForm.getValues());
      } else {
        append(newShareholderForm.getValues());
      }

      setCurrView("list");
      newShareholderForm.reset();
    });
  };
  const handleSubmitUpdate = () => {
    guardShareholding(() => {
      if (editingIndex) {
        update(Number(editingIndex), newShareholderForm.getValues());
        setCurrView("list");
        newShareholderForm.reset();
      }
    });
  };

  const [currView, setCurrView] = useState<DrawerViewsKeys>("form");
  const DrawerView: DrawerViews = {
    form: {
      heading: "Add shareholder",
      view: (
        <AddShareholder
          newShareholderForm={newShareholderForm}
          onSubmit={handleAddNewShareholder}
        />
      ),
      footer: (
        <Button
          type="submit"
          form={"shareholder_form"}
          className="flex gap-[0.5rem] items-center h-[3.5rem]"
        >
          <p>Add</p>
        </Button>
      ),
    },
    list: {
      heading: "Shareholder Information",
      view: (
        <div className="flex flex-col gap-[1.5rem] ">
          {fields.map((field, index) => {
            if (field.email !== "")
              return (
                <OnboardingItemCard
                  key={index}
                  item={getFieldsMap(field)}
                  onEditClick={() => {
                    handleClickEdit(field, index);
                  }}
                  onDelete={() => {
                    remove(index);
                  }}
                />
              );
          })}
        </div>
      ),
      footer: (
        <Button
          variant={"secondary"}
          type="button"
          className="flex gap-[0.5rem] items-center h-[3.5rem] "
          onClick={() => {
            setCurrView("form");
          }}
        >
          {" "}
          <p>Add Shareholder</p>
          <PlusIcon width={16} height={16} />{" "}
        </Button>
      ),
    },
    edit: {
      heading: "Edit Shareholder",
      view: (
        <AddShareholder
          newShareholderForm={newShareholderForm}
          onSubmit={handleSubmitUpdate}
        />
      ),
      footer: (
        <Button
          type="submit"
          form={"shareholder_form"}
          className="flex gap-[0.5rem] items-center h-[3.5rem]"
        >
          <p>Update</p>
        </Button>
      ),
    },
  };

  return (
    <section className={"mt-[38px]"}>
      <OnboardingAccordion
        name={"Shareholder Information"}
        buttonLabel="Add Shareholder"
        openFormDrawer={() => {
          newShareholderForm.reset();
          setCurrView("form");
          openDrawer();
        }}
      >
        <OnboardingAccordionContent
          type="shareholder"
          fields={
            fields[0] && fields[0].email !== ""
              ? fields.map((field) => getFieldsMap(field))
              : undefined
          }
          openListDrawer={() => {
            setCurrView("list");
            openDrawer();
          }}
          onEditClick={() => {
            openDrawer();
            handleClickEdit(fields[0], 0);
          }}
          onDelete={() => {
            remove(0);
          }}
        />
      </OnboardingAccordion>
      <OnboardingItemDrawer
        triggerRef={drawerTriggerRef}
        heading={DrawerView[currView].heading}
        closeButtonRef={closeDrawerButtonRef}
        Footer={DrawerView[currView].footer}
      >
        {DrawerView[currView].view}
      </OnboardingItemDrawer>
    </section>
  );
};

export default ShareholderSection;
