import SignOutModal from "@/components/sign-out-modal";
import { DialogTrigger } from "@/components/ui/dialog";
import { Header } from "@/components/ui/header";
import { LoginIcon } from "@/icons/login-in";
import { useAuthUser } from "@/internals/auth";
import { validateRedirectSearch } from "@/internals/validators/route.validation";
import { Dialog } from "@radix-ui/react-dialog";

import { accountsQueryOptions } from "@/domains/organizations/hooks";
import { sessionQueryOptions } from "@/domains/sessions";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/organization/$organizationId/")({
  component: Dashboard,
  validateSearch: validateRedirectSearch,
  loader: (opts) =>
    Promise.all([
      opts.context.queryClient.ensureQueryData(sessionQueryOptions()),
      opts.context.queryClient.ensureQueryData(accountsQueryOptions()),
    ]),
});

function Dashboard() {
  const user = useAuthUser();

  if (!user) {
    return (
      <>
        <Dialog>
          <DialogTrigger asChild>
            <div className="flex  w-full justify-center cursor-pointer">
              <div className="flex gap-x-2  text-text-input-placeholder">
                <LoginIcon />
                <p className="text-[1rem] text-text-input-placeholder">
                  Sign Out
                </p>
              </div>
            </div>
          </DialogTrigger>
          <SignOutModal />
        </Dialog>
      </>
    );
  }
  return (
    <div className="flex flex-col justify-center items-center border h-screen pb-[2.875rem]">
      <div className="flex-1 w-full md:w-[31.25rem] space-y-[2.375rem] flex flex-col justify-center">
        <Header
          header={user.firstname}
          headerClassName="capitalize text-center"
          subHeaderClassName="text-center"
        />
      </div>
      <Dialog>
        <DialogTrigger asChild>
          <div className="flex  w-full justify-center cursor-pointer">
            <div className="flex gap-x-2  text-text-input-placeholder">
              <LoginIcon />
              <p className="text-[1rem] text-text-input-placeholder">
                Sign Out
              </p>
            </div>
          </div>
        </DialogTrigger>
        <SignOutModal />
      </Dialog>
    </div>
  );
}
