import { Button } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { cn } from "@/lib/utils";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Check } from "lucide-react";

import React from "react";
import { ComboBoxProps } from "./types";

export const SingleComboBox = React.forwardRef<
  HTMLButtonElement,
  ComboBoxProps
>(
  (
    {
      data: items,
      placeholder,
      emptyLabel,
      onValueChange,
      value,
      isInvalid,
      popoverContentClass,
    },
    ref
  ) => {
    const [open, setOpen] = React.useState(false);
    return (
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            type="button"
            variant="outline"
            role="combobox"
            aria-expanded={open}
            ref={ref}
            className={cn(
              "h-10 relative w-full cursor-default rounded-lg bg-transparent py-2 pl-3 pr-10 text-left border border-text-input  text-base text-black focus:ring-1 focus:ring-primary-green focus:border-primary-green focus:shadow-primary-green justify-start font-normal",
              isInvalid && "border-destructive"
            )}
          >
            <span className="block truncate text-black">
              {value
                ? items.find((item) => item.value === value)?.label
                : placeholder}
            </span>

            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronDownIcon
                className="h-5 w-5 text-black"
                aria-hidden="true"
              />
            </span>
          </Button>
        </PopoverTrigger>
        <PopoverContent
          className={cn("w-[31.25rem] p-0 ", popoverContentClass)}
        >
          <Command className="w-full">
            <CommandList>
              <CommandInput placeholder="Search..." />
              <CommandEmpty>{emptyLabel}</CommandEmpty>
              <CommandGroup className=" w-full">
                {items.map((item) => {
                  return (
                    <CommandItem
                      key={item.value}
                      className="gap-2 data-[disabled]:pointer-events-auto data-[disabled]:opacity-100"
                      value={`${item.label}-${item.value}`}
                      onSelect={(currentValue) => {
                        if (currentValue) {
                          onValueChange?.(item.value);
                          setOpen(false);
                        }
                      }}
                    >
                      <Check
                        className={cn(
                          "mr-2 h-4 w-4",
                          value === item.value ? "opacity-100" : "opacity-0"
                        )}
                      />
                      <span>{item.label}</span>
                    </CommandItem>
                  );
                })}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    );
  }
);
