import { Button } from "@/components/ui/button";

import { createFileRoute } from "@tanstack/react-router";
import {
  FormProvider,
  SubmitHandler,
  useForm,
  useWatch,
} from "react-hook-form";

import { CheckboxWithLabel } from "@/components/ui/checkbox-with-label";
import { InitSettlementAccounts } from "@/data/onboarding.data";
import {
  settlementAccountOptions,
  useCreateSettlementAccounts,
  useEndOnboarding,
} from "@/domains/organizations";
import { useOnboardingFlow } from "@/internals/hooks/useOnboardingFlow";
import EurSection from "@/sections/onboarding/settlement-accounts/EurSection";
import GbpSection from "@/sections/onboarding/settlement-accounts/GbpSection";
import NgnSection from "@/sections/onboarding/settlement-accounts/NgnSection";
import UsdSection from "@/sections/onboarding/settlement-accounts/UsdSection";
import {
  settlementAccountsSchema,
  SettlementAccountsSchemaDto,
} from "@/sections/onboarding/settlement-accounts/validators";
import { zodResolver } from "@hookform/resolvers/zod";
import { useSuspenseQuery } from "@tanstack/react-query";
import { useParams } from "@tanstack/react-router";
import { useMemo } from "react";
import { toast } from "sonner";
import FormCheckbox from "@/components/form-checkbox";

const SettlementAccounts = () => {
  const organizationId = useParams({
    from: "/organization/$organizationId",
    select(params) {
      return params.organizationId;
    },
  });
  const endOnboarding = useEndOnboarding();

  const { prev: handlePrevious, next: handleNext } = useOnboardingFlow();

  const { data } = useSuspenseQuery(settlementAccountOptions(organizationId));

  const form = useForm<SettlementAccountsSchemaDto>({
    resolver: zodResolver(settlementAccountsSchema),
    // @ts-expect-error server type and client type are different
    defaultValues: data || InitSettlementAccounts,
  });
  const createSettlementAccounts = useCreateSettlementAccounts();
  const { ngn_account, usd_account, eur_account, gbp_account } = useWatch({
    control: form.control,
  });
  const accounts = useMemo(() => {
    return [
      {
        key: "ngn_account",
        label: "NGN Account",
        value: !!ngn_account,
        component: <NgnSection key={"ngn"} />,
      },
      {
        key: "usd_account",
        label: "USD Account",
        value: !!usd_account,
        component: <UsdSection key={"usd"} />,
      },
      {
        key: "eur_account",
        label: "EUR Account",
        value: !!eur_account,
        component: <EurSection key={"eur"} />,
      },
      {
        key: "gbp_account",
        label: "GBP Account",
        value: !!gbp_account,
        component: <GbpSection key={"gbp"} />,
      },
    ] as const;
  }, [ngn_account, usd_account, eur_account, gbp_account]);
  const handleOnChange = (key: keyof SettlementAccountsSchemaDto) => {
    if (!form.getValues(key)) {
      form.setValue(
        key,
        // @ts-expect-error - -
        InitSettlementAccounts[key as keyof SettlementAccountsSchemaDto]
      );
    } else {
      form.setValue(key, undefined);
    }
  };
  const onSubmit: SubmitHandler<SettlementAccountsSchemaDto> = (values) => {
    const { agree_to_terms, correct_documents, ...rest } = values;
    console.log(agree_to_terms, correct_documents);
    const accounts = Object.keys(rest)
      // @ts-expect-error - -
      .flatMap((key) => rest[key])
      .filter((val) => val !== undefined);

    createSettlementAccounts.mutate(
      { id: organizationId, accounts },
      {
        onSuccess: () => {
          toast("Accounts setup successfully");
          endOnboarding.mutate(
            { id: organizationId },
            {
              onSuccess() {
                toast.success("Documents will be reviewed");
                handleNext();
              },
            }
          );
        },
      }
    );
  };
  const { agree_to_terms, correct_documents } = useWatch({
    control: form.control,
  });

  return (
    <FormProvider {...form}>
      <form
        className="mt-5 flex flex-col gap-3"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <div>
          <p className="text-lg font-medium mb-[14px]">
            Select desired currencies
          </p>

          <div className="flex flex-wrap items-center gap-[14px]">
            {accounts.map((account) => (
              <CheckboxWithLabel
                key={account.label}
                label={account.label}
                labelClassName="text-[#020817]"
                checked={account.value}
                handleOnChange={() => handleOnChange(account.key)}
              />
            ))}
          </div>
        </div>

        <>
          {accounts.map((account) => {
            if (account.value) {
              return account.component;
            }
            return null;
          })}
          <FormCheckbox
            control={form.control}
            name={"correct_documents"}
            label={
              <p className="text-sm text-grey-background font-medium leading-[1.058rem]">
                I accept that all documents and fields entered are correct and a
                false or incorrect entry is liable to legal actions
              </p>
            }
          />

          <FormCheckbox
            control={form.control}
            name={"agree_to_terms"}
            label={
              <p className="text-sm text-grey-background font-medium leading-[1.058rem]">
                I have read and agreed to Clearerpay&lsquo;s{" "}
                <a
                  href="https://clearerpay.com/terms-of-use"
                  className="capitalize underline text-primary-green font-bold "
                  target="_blank"
                >
                  terms of service
                </a>
              </p>
            }
          />
        </>
        <div className="my-12 flex items-center justify-between gap-8">
          <Button
            className="bg-grey-light-background text-black rounded-[0.625rem]"
            size={"sm"}
            onClick={handlePrevious}
          >
            Previous
          </Button>
          <Button
            size={"sm"}
            type="submit"
            className="rounded-[0.625rem]"
            disabled={
              (!ngn_account &&
                !usd_account &&
                !eur_account &&
                !gbp_account &&
                !agree_to_terms) ||
              !correct_documents
            }
            loading={
              endOnboarding.isPending || createSettlementAccounts.isPending
            }
          >
            Submit
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

export const Route = createFileRoute(
  "/organization/$organizationId/_onboarding/onboarding/settlement-accounts"
)({
  component: SettlementAccounts,
});
