const CloseCircleFilledIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="16" fill="#EDEDF3" />
      <mask
        id="mask0_4988_41402"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="6"
        y="6"
        width="20"
        height="20"
      >
        <rect
          x="6.39844"
          y="6.39844"
          width="19.2"
          height="19.2"
          fill="#D9D9D9"
        />
      </mask>
      <g mask="url(#mask0_4988_41402)">
        <path
          d="M11.5197 21.3197L10.6797 20.4797L15.1597 15.9997L10.6797 11.5197L11.5197 10.6797L15.9997 15.1597L20.4797 10.6797L21.3197 11.5197L16.8397 15.9997L21.3197 20.4797L20.4797 21.3197L15.9997 16.8397L11.5197 21.3197Z"
          fill="#030517"
        />
      </g>
    </svg>
  );
};

export default CloseCircleFilledIcon;
