import { createFileRoute, useRouter } from "@tanstack/react-router";

import { ClearerLogo } from "@/assets";
import { Button } from "@/components/ui/button";
import { Form } from "@/components/ui/form";
import { Header } from "@/components/ui/header";
import { TextInput } from "@/components/ui/text-input";
import { useSignIn } from "@/domains/sessions";
import { useAuthStore } from "@/internals/auth";
import { useRedirectEffect } from "@/internals/hooks/useRedirectEffect";
import {
  emailSearch,
  validateRedirectSearch,
} from "@/internals/validators/route.validation";
import { zodResolver } from "@hookform/resolvers/zod";
import { Link } from "@tanstack/react-router";
import {
  Control,
  FieldValues,
  SubmitHandler,
  useForm,
  useWatch,
} from "react-hook-form";
import { z } from "zod";

const signInDtoSchema = z.object({
  email_address: z.string().email(),
  password: z.string().min(8).max(100),
});

type SignInDto = z.infer<typeof signInDtoSchema>;

export const Route = createFileRoute("/sign-in")({
  component: SignIn,
  validateSearch: validateRedirectSearch.merge(emailSearch),
});

function ForgotLink<TFieldValues extends FieldValues = FieldValues>({
  control,
}: {
  control: Control<TFieldValues>;
}) {
  const { email_address } = useWatch({ control });
  return (
    <Link
      to="/forgot-password"
      search={email_address ? { email: email_address } : undefined}
    >
      Forgot Password?
    </Link>
  );
}

function SignIn() {
  const router = useRouter();
  const login = useAuthStore((s) => s.login);
  const isAuthenticated = useAuthStore((s) => s.isAuthenticated);

  const search = Route.useSearch();

  useRedirectEffect(isAuthenticated, search.redirect ?? "/select-business");

  const signInMutation = useSignIn();
  const form = useForm<SignInDto>({
    resolver: zodResolver(signInDtoSchema),
    defaultValues: {
      email_address: search?.email ?? "",
      password: "",
    },
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = form;

  const onSubmit: SubmitHandler<SignInDto> = (values) => {
    signInMutation.mutate(values, {
      onSuccess: (data) => {
        login(data);
        if (search.redirect) {
          router.history.push(search.redirect);
          router.invalidate();
          return;
        }
        if (data.accounts) {
          router.navigate({ to: "/select-business" });
        } else {
          router.navigate({ to: "/create-business" });
        }
        router.invalidate();
      },
    });
  };

  return (
    <div className="flex">
      <a href="https://clearerpay.com" target="_blank">
        <div className="md:w-80  h-screen flex-1 justify-center fixed border-r border-grey-border hidden md:flex bg-white bg-gradient-to-b from-gray-50 via-gray-200 to-gray-300 py-[5.4rem]">
          <img
            src={ClearerLogo}
            className="object-cover w-[13.8rem] h-[4.75rem]"
          />
        </div>
      </a>
      <main className="flex-1 ">
        <div className="flex flex-col justify-center md:ml-80 sm:border-r sm:border-zinc-700 min-h-screen ">
          <div className="flex flex-col pt-2 px-4 space-y-2 pb-4 items-center ">
            <Form {...form}>
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="w-full  md:w-[31rem]"
              >
                <Header
                  header="Welcome back"
                  subtitle="Sign-in to your account"
                  className="mb-[2.375rem]"
                />
                <div className="space-y-[1.5rem] w-full  md:w-[31rem]">
                  <TextInput
                    labelTitle="Email Address"
                    variant="email"
                    errorText={errors.email_address?.message}
                    autoComplete="email webauthn"
                    {...register("email_address")}
                  />
                  <div className="space-y-[0.875rem]">
                    <TextInput
                      labelTitle="Create Password"
                      variant="password"
                      errorText={errors.password?.message}
                      autoComplete="current-password"
                      {...register("password")}
                    />
                    <p className="font-medium text-sm">
                      <ForgotLink control={control} />
                    </p>
                  </div>
                </div>
                <div className="space-y-[2.375rem] mt-[2.375rem]">
                  <Button
                    size="lg"
                    variant="default"
                    type="submit"
                    className="w-full"
                    loading={signInMutation.isPending}
                  >
                    Sign In
                  </Button>
                  <div className="text-center">
                    <Link to="/sign-up">
                      <p className="text-[1.125rem]">New here?</p>
                      <span className="font-bold leading-5 text-[1.125rem]">
                        Create an Account
                      </span>
                    </Link>
                  </div>
                </div>
              </form>
            </Form>
          </div>
        </div>
      </main>
    </div>
  );
}
