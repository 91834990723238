import { fixedForwardRef } from "@/internals/forward-ref";
import React from "react";
import { Control, FieldPath, FieldValues } from "react-hook-form";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "./ui/form";
import { Input, InputProps } from "./ui/input";
import { cn } from "@/lib/utils";

interface FormInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends InputProps {
  control: Control<TFieldValues>;
  name: TName;
  label?: React.ReactNode;
  labelClass?: string;
}

const RawFormInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  props: FormInputProps<TFieldValues, TName>,
  ref: React.ForwardedRef<HTMLInputElement>
) => {
  const { control, name, label, labelClass, ...rest } = props;
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem className="w-full ">
          <FormLabel className={cn("text-lg font-medium", labelClass)}>
            {label}
          </FormLabel>
          <FormControl>
            <Input {...rest} {...field} ref={ref} />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

export const FormInput = fixedForwardRef(RawFormInput);
