const ChevronDown = () => {
  return (
    <svg
      width="12"
      height="8"
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.00156 7.04922L0.351562 1.37422L1.40156 0.324219L6.00156 4.92422L10.6016 0.324219L11.6516 1.37422L6.00156 7.04922Z"
        fill="#323546"
      />
    </svg>
  );
};

export default ChevronDown;
