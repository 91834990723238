import { Control, FieldPath, FieldValues } from "react-hook-form";

import { MultiComboBox } from "./combo-box/multi";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "./ui/form";
import { SelectItem } from "./ui/multi-select/types";

// TODO: Move to util
// const getObjectFromString = ({
//   string,
//   delimiter,
//   keys,
// }: {
//   string: string;
//   delimiter: string;
//   keys: string[];
// }) => {
//   const values = string.split(delimiter);
//   const object: Record<string, string> = {};
//   keys.forEach((key, idx) => {
//     object[key] = values[idx];
//   });

//   return object;
// };

export function FormMultipleComboBox<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  emptyLabel,
  label,
  data,
  placeholder,
  // delimiter,
  // dtoKeys,
  control,
  name,
}: {
  dtoKeys?: string[];
  label?: React.ReactNode;
  emptyLabel?: React.ReactNode;
  data: SelectItem<string>[];
  placeholder?: string;
  delimiter?: string;
  control: Control<TFieldValues>;
  name: TName;
}) {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field, fieldState }) => (
        <FormItem>
          <FormLabel>{label}</FormLabel>
          <FormControl>
            <MultiComboBox
              placeholder={placeholder}
              value={field.value}
              data={data}
              emptyLabel={emptyLabel}
              isInvalid={fieldState.error}
              onValueChange={(vals) => {
                // if (delimiter && dtoKeys) {
                //   vals.forEach((val, index) => {
                //     const obj = getObjectFromString({
                //       string: val,
                //       delimiter,
                //       keys: dtoKeys,
                //     });
                //     console.log({ obj });
                //     Object.keys(obj).forEach((key) => {
                //       console.log(
                //         `${name as string}.${index}.${key}`,
                //         obj[key]
                //       );
                //     });
                //   });
                // } else {
                //   vals.forEach((val, idx) => {
                //     console.log(`${name as string}.${idx}`, val);
                //   });
                // }
                field.onChange(vals);
              }}
              label={undefined}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
