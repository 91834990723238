import { Button } from "@/components/ui/button";

import { SupportedCurrencies } from "@/i18n/currency-data";

import { createFileRoute, useParams } from "@tanstack/react-router";
import React, { useState } from "react";

import { CheckboxWithLabel } from "@/components/ui/checkbox-with-label";

import { zodResolver } from "@hookform/resolvers/zod";
import { AnimatePresence, motion } from "framer-motion";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import { DEFAULT_ANIMATION_CONFIG } from "@/config/animation";
import { BusinessAccount } from "@/sections/onboarding/purpose-of-account/business-account";
import { CrossBorder } from "@/sections/onboarding/purpose-of-account/cross-border";
import { CurrencySwap } from "@/sections/onboarding/purpose-of-account/currency-swap";

import { InitPurposeOfAccount } from "@/data/onboarding.data";
import {
  Organization,
  servicesQueryOptions,
  useSetServices,
} from "@/domains/organizations";
import { useOnboardingFlow } from "@/internals/hooks/useOnboardingFlow";
import { purposeOfAccountDtoSchema } from "@/sections/onboarding/purpose-of-account/validator";
import { useSuspenseQuery } from "@tanstack/react-query";
import { toast } from "sonner";

export const Route = createFileRoute(
  "/organization/$organizationId/_onboarding/onboarding/purpose-of-account"
)({
  component: PurposeOfAccount,
  loader: (opts) =>
    opts.context.queryClient.ensureQueryData(
      servicesQueryOptions(
        (opts.params as Record<string, string>).organizationId
      )
    ),
});

function extractVolumes(volume: string) {
  const [lower_limit, upper_limit] = volume.replace("$", "").split("-");
  return {
    lower_limit: Number(lower_limit),
    upper_limit: Number(upper_limit),
  };
}

function extractCurrencyPair(pair: string) {
  const [source_currency, target_currency] = pair.split("/");
  return {
    source_currency,
    target_currency,
  };
}

function extractResponseData(data: Organization.ServicesResponse) {
  const formattedData: purposeOfAccountDtoSchema = {};
  data.data?.forEach((value) => {
    const type = value.type;
    switch (type) {
      case "business_account":
        formattedData[type] = value.details?.map((detail) => {
          return {
            pair: detail.currency,
            expected_annual_volume: `${detail.expected_annual_volume.lower_limit}-${detail.expected_annual_volume.upper_limit}`,
            expected_monthly_volume: `${detail.expected_monthly_volume.lower_limit}-${detail.expected_monthly_volume.upper_limit}`,
          };
        });
        break;

      case "cross_border":
      case "currency_swap":
        formattedData[type] = value.details?.map((detail) => {
          return {
            pair: `${detail.currency_pair.source_currency}/${detail.currency_pair.target_currency}`,
            expected_annual_volume: `${detail.expected_annual_volume.lower_limit}-${detail.expected_annual_volume.upper_limit}`,
            expected_monthly_volume: `${detail.expected_monthly_volume.lower_limit}-${detail.expected_monthly_volume.upper_limit}`,
          };
        });
        break;

      default:
        break;
    }
  });
  return formattedData;
}

export function PurposeOfAccount() {
  const { prev: handlePrevious, next: handleNext } = useOnboardingFlow();

  const setServices = useSetServices();
  const [showCurrencyOptions] = useState(false);
  const organizationId = useParams({
    from: "/organization/$organizationId",
    select(params) {
      return params.organizationId;
    },
  });
  const { data, refetch } = useSuspenseQuery(
    servicesQueryOptions(organizationId)
  );
  const defaultValues = extractResponseData(data);

  const form = useForm<purposeOfAccountDtoSchema>({
    defaultValues,
    resolver: zodResolver(purposeOfAccountDtoSchema),
  });

  const business_account = !!form.watch("business_account");
  const cross_border = !!form.watch("cross_border");
  const currency_swap = !!form.watch("currency_swap");

  const services = React.useMemo(() => {
    return [
      {
        key: "business_account",
        label: "Business Account",
        value: business_account,
        component: <BusinessAccount key="business_account" />,
      },
      {
        key: "cross_border",
        label: "Cross Border Remittance",
        value: cross_border,
        component: <CrossBorder key="cross_border" />,
      },
      {
        key: "currency_swap",
        label: "Currency Swap",
        value: currency_swap,
        component: <CurrencySwap key="currency_swap" />,
      },
    ] as const;
  }, [business_account, cross_border, currency_swap]);

  const onSubmit: SubmitHandler<purposeOfAccountDtoSchema> = (values) => {
    setServices.mutate(
      {
        id: organizationId,
        business_account: values.business_account?.map((value) => {
          return {
            currency: value.pair,
            expected_annual_volume: extractVolumes(
              value.expected_annual_volume
            ),
            expected_monthly_volume: extractVolumes(
              value.expected_monthly_volume
            ),
          };
        }),
        currency_swap: values.currency_swap?.map((value) => {
          return {
            currency_pair: extractCurrencyPair(value.pair),
            expected_annual_volume: extractVolumes(
              value.expected_annual_volume
            ),
            expected_monthly_volume: extractVolumes(
              value.expected_monthly_volume
            ),
          };
        }),
        cross_border: values.cross_border?.map((value) => {
          return {
            currency_pair: extractCurrencyPair(value.pair),
            expected_annual_volume: extractVolumes(
              value.expected_annual_volume
            ),
            expected_monthly_volume: extractVolumes(
              value.expected_monthly_volume
            ),
          };
        }),
      },
      {
        onSuccess(data) {
          toast(data.message);
          handleNext();
          return refetch();
        },
      }
    );
  };

  const handleOnChange = (
    key: "business_account" | "cross_border" | "currency_swap"
  ) => {
    if (!form.getValues(key)) {
      form.setValue(key, InitPurposeOfAccount[key]);
    } else {
      form.setValue(key, undefined);
    }
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} id={"purpose_of_account"}>
        <div>
          <div className="mt-8 flex flex-col gap-[24px]">
            <div>
              <p className="text-lg font-medium mb-[14px]">
                Indicate required service
              </p>

              <div className="flex flex-wrap items-center gap-[14px]">
                {services.map((service) => (
                  <CheckboxWithLabel
                    key={service.label}
                    label={service.label}
                    labelClassName="text-[#020817]"
                    checked={service.value}
                    handleOnChange={() => handleOnChange(service.key)}
                  />
                ))}
              </div>
            </div>

            {showCurrencyOptions ? (
              <AnimatePresence>
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{
                    // double of our normal to feel it
                    duration: (DEFAULT_ANIMATION_CONFIG.duration * 2) / 1000,
                    ease: DEFAULT_ANIMATION_CONFIG.easing,
                  }}
                >
                  <p className="text-lg font-medium mb-[14px]">
                    In which currency?
                  </p>

                  <div className="flex flex-wrap items-center gap-[14px]">
                    {SupportedCurrencies.map((currency) => {
                      return (
                        <CheckboxWithLabel
                          key={currency}
                          id={`currency-${currency}`}
                          label={currency}
                          labelClassName="text-sm leading-none "
                        />
                      );
                    })}
                  </div>
                </motion.div>
              </AnimatePresence>
            ) : null}
          </div>

          {/* <OnboardingAccordion /> */}
          <AnimatePresence>
            {services?.map((service) => {
              if (service.value) {
                return service.component;
              }
              return null;
            })}
          </AnimatePresence>

          <div className="my-[2.375rem] flex items-center justify-between gap-8">
            <Button
              className="bg-grey-light-background text-black rounded-[0.625rem]"
              size={"sm"}
              onClick={handlePrevious}
              disabled={setServices.isPending}
            >
              Previous
            </Button>
            <Button
              size={"sm"}
              type={"submit"}
              form="purpose_of_account"
              loading={setServices.isPending}
              disabled={!currency_swap && !business_account && !cross_border}
              className="rounded-[0.625rem]"
            >
              Next
            </Button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}
