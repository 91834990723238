import { ONBOARDING_ITEMS, OnboardingScreens } from "@/data/onboarding.data";
import { useNavigate, useParams } from "@tanstack/react-router";
import { getKeys } from "../object-keys";
import { useRouteName } from "./useRouteName";

export function useOnboardingFlow() {
  const routeName = useRouteName() as OnboardingScreens;
  const navigate = useNavigate();

  const data = ONBOARDING_ITEMS[routeName];
  const organizationId = useParams({
    from: "/organization/$organizationId/_onboarding",
    select(params) {
      return params.organizationId;
    },
  });

  const getKeyByLevel = (level: number) =>
    getKeys(ONBOARDING_ITEMS).find((key) => {
      const item = ONBOARDING_ITEMS[key];
      return item.level === level;
    });

  const next = () => {
    if (data.level === 4) {
      navigate({
        to: `/organization/$organizationId/dashboard`,
        params: {
          organizationId: organizationId,
        },
      });
      return;
    }
    const nextKey = getKeyByLevel(data.level + 1);

    if (!nextKey) {
      return;
    }
    const item = ONBOARDING_ITEMS[nextKey];

    navigate({
      to: `/organization/$organizationId/onboarding/${item.key}`,
      params: {
        organizationId: organizationId,
      },
    });
  };
  const prev = () => {
    if (data.level === 1) {
      navigate({
        to: "/select-business",
      });
    }
    const nextKey = getKeyByLevel(data.level - 1);

    if (!nextKey) {
      return;
    }
    const item = ONBOARDING_ITEMS[nextKey];

    navigate({
      to: `/organization/$organizationId/onboarding/${item.key}`,
      params: {
        organizationId: organizationId,
      },
    });
  };

  return {
    next,
    prev,
  };
}
