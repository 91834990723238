import { SVGProps } from "react";
const SettingsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentcolor"
      d="M12 8.25A3.754 3.754 0 0 0 8.25 12 3.754 3.754 0 0 0 12 15.75 3.754 3.754 0 0 0 15.75 12 3.754 3.754 0 0 0 12 8.25Zm0 6A2.252 2.252 0 0 1 9.75 12 2.252 2.252 0 0 1 12 9.75 2.252 2.252 0 0 1 14.25 12 2.252 2.252 0 0 1 12 14.25Zm9.208-.297A2.267 2.267 0 0 1 20.081 12a2.26 2.26 0 0 1 1.131-1.955c.515-.299.691-.962.393-1.478l-1.672-2.886a1.085 1.085 0 0 0-1.477-.395 2.27 2.27 0 0 1-2.269-.004 2.256 2.256 0 0 1-1.12-1.945c0-.599-.489-1.086-1.088-1.086h-3.955c-.6 0-1.087.487-1.087 1.086a2.26 2.26 0 0 1-3.389 1.95 1.083 1.083 0 0 0-1.477.395L2.397 8.571a1.084 1.084 0 0 0 .399 1.479 2.266 2.266 0 0 1 1.127 1.949 2.262 2.262 0 0 1-1.13 1.958c-.25.145-.43.378-.504.658-.074.279-.036.57.11.821l1.67 2.884c.298.516.961.695 1.479.396a2.264 2.264 0 0 1 2.255-.003l.002.001a2.253 2.253 0 0 1 1.129 1.952c0 .599.487 1.086 1.086 1.086h3.959c.599 0 1.086-.487 1.086-1.085a2.259 2.259 0 0 1 3.39-1.951 1.084 1.084 0 0 0 1.477-.394l1.674-2.889c.297-.517.12-1.18-.398-1.48Zm-2.377 3.274a3.779 3.779 0 0 0-3.397.193 3.765 3.765 0 0 0-1.847 2.83H10.41a3.76 3.76 0 0 0-1.847-2.831 3.772 3.772 0 0 0-3.394-.192l-1.276-2.203a3.758 3.758 0 0 0 1.528-3.031 3.774 3.774 0 0 0-1.529-3.015l1.277-2.204a3.78 3.78 0 0 0 3.397-.194 3.765 3.765 0 0 0 1.846-2.829h3.175a3.763 3.763 0 0 0 1.85 2.831 3.777 3.777 0 0 0 3.394.193l1.277 2.203a3.76 3.76 0 0 0-1.527 3.026 3.776 3.776 0 0 0 1.528 3.021l-1.278 2.202Z"
    />
  </svg>
);
export { SettingsIcon };
