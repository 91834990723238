import { PlusIcon } from "@heroicons/react/20/solid";

import { useMemo, useRef, useState } from "react";

import {
  formatAmountLimitPair,
  InitPurposeOfAccount,
  volumeBands,
} from "@/data/onboarding.data";

import { FormSingleComboBox } from "@/components/form-single-combo-box";

import {
  SubmitHandler,
  useFieldArray,
  useForm,
  useFormContext,
  UseFormReturn,
  useWatch,
} from "react-hook-form";
import { SupportedCurrencies } from "@/i18n/currency-data";
import { Button } from "@/components/ui/button";
import { purposeOfAccountDtoSchema, ServiceSchemaDtoSchema } from "./validator";
import OnboardingAccordion from "../onboarding-accordions/onboarding-accordion";
import OnboardingItemDrawer from "../onboarding-accordions/onboarding-item-drawer";
import OnboardingAccordionContent from "../onboarding-accordions/onboarding-accordion-content";
import OnboardingItemCard from "../onboarding-accordions/onboarding-item-card";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Form } from "@/components/ui/form";
import { DrawerViews, DrawerViewsKeys } from "../company-structure/types";

export function BusinessAccount() {
  const drawerTriggerRef = useRef<HTMLButtonElement>(null);
  const closeDrawerButtonRef = useRef<HTMLButtonElement>(null);
  const [editingIndex, setEditingIndex] = useState<string>();
  // const closeDrawer = () => {
  //   if (closeDrawerButtonRef.current) {
  //     closeDrawerButtonRef.current.click();
  //   }
  // };
  const openDrawer = () => {
    if (drawerTriggerRef.current) {
      drawerTriggerRef.current.click();
    }
  };
  const { control } = useFormContext<purposeOfAccountDtoSchema>();
  const newBusinessAccountForm = useForm<ServiceSchemaDtoSchema[0]>({
    defaultValues: InitPurposeOfAccount.business_account[0],
    resolver: zodResolver(
      z.object({
        pair: z.string().trim().min(1, "Required"),
        expected_monthly_volume: z.string().trim().min(1, "Required"),
        expected_annual_volume: z.string().trim().min(1, "Required"),
      })
    ),
  });
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "business_account",
  });

  const getFieldsMap = (account: ServiceSchemaDtoSchema[0]) => ({
    "Desired currency": account.pair,
    "Expected Monthly Volumes": formatAmountLimitPair({
      value: account.expected_monthly_volume,
      currency: account.pair,
    }),
    "Expected Annual Volumes": formatAmountLimitPair({
      value: account.expected_annual_volume,
      currency: account.pair,
    }),
  });
  const handleClickEdit = (
    account: ServiceSchemaDtoSchema[0],
    index: number
  ) => {
    setEditingIndex(String(index));
    newBusinessAccountForm.setValue("pair", account.pair);
    newBusinessAccountForm.setValue(
      "expected_annual_volume",
      account.expected_annual_volume
    );
    newBusinessAccountForm.setValue(
      "expected_monthly_volume",
      account.expected_monthly_volume
    );

    setCurrentView("edit");
  };

  const handleAddNew = () => {
    if (fields[0] && fields[0].pair === "") {
      update(0, newBusinessAccountForm.getValues());
    } else {
      append(newBusinessAccountForm.getValues());
    }
    setCurrentView("list");
    newBusinessAccountForm.reset();
  };
  const handleSubmitUpdate = () => {
    if (editingIndex) {
      update(Number(editingIndex), newBusinessAccountForm.getValues());
      setCurrentView("list");
      newBusinessAccountForm.reset();
    }
  };

  const [currentView, setCurrentView] = useState<DrawerViewsKeys>("form");
  const DrawerView: DrawerViews = {
    form: {
      heading: "Add currency",
      view: (
        <Pair
          newBusinessAccountForm={newBusinessAccountForm}
          onSubmit={handleAddNew}
        />
      ),
      footer: (
        <Button
          type="submit"
          form={"business_account_form"}
          className="flex gap-[0.5rem] items-center h-[3.5rem]"
        >
          <p>Add</p>
        </Button>
      ),
    },
    list: {
      heading: "Business Account",
      view: (
        <div className="flex flex-col gap-[1.5rem] max-h-[80dvh] overflow-y-scroll">
          {fields.map((field, index) => {
            if (field.pair !== "")
              return (
                <OnboardingItemCard
                  key={index}
                  item={getFieldsMap(field)}
                  onEditClick={() => {
                    handleClickEdit(field, index);
                  }}
                  onDelete={() => {
                    remove(index);
                  }}
                />
              );
          })}
        </div>
      ),
      footer: (
        <Button
          variant={"secondary"}
          type="button"
          className="flex gap-[0.5rem] items-center h-[3.5rem] "
          onClick={() => {
            setCurrentView("form");
          }}
        >
          {" "}
          <p>Add currency</p>
          <PlusIcon width={16} height={16} />{" "}
        </Button>
      ),
    },
    edit: {
      heading: "Edit currency",
      view: (
        <Pair
          newBusinessAccountForm={newBusinessAccountForm}
          onSubmit={handleSubmitUpdate}
        />
      ),

      footer: (
        <Button
          type="submit"
          form={"business_account_form"}
          className="flex gap-[0.5rem] items-center h-[3.5rem]"
        >
          <p>Udpate</p>
        </Button>
      ),
    },
  };

  return (
    <section className={"mt-[38px]"}>
      <OnboardingAccordion
        name={"Business Account"}
        buttonLabel="Add Currency"
        openFormDrawer={() => {
          newBusinessAccountForm.reset();
          setCurrentView("form");
          openDrawer();
        }}
      >
        <OnboardingAccordionContent
          type="currency"
          fields={
            fields[0] && fields[0].pair !== ""
              ? fields.map((i) => getFieldsMap(i))
              : undefined
          }
          openListDrawer={() => {
            setCurrentView("list");
            openDrawer();
          }}
          onEditClick={() => {
            openDrawer();
            handleClickEdit(fields[0], 0);
          }}
          onDelete={() => {
            remove(0);
          }}
        />
      </OnboardingAccordion>
      <OnboardingItemDrawer
        triggerRef={drawerTriggerRef}
        heading={DrawerView[currentView].heading}
        closeButtonRef={closeDrawerButtonRef}
        Footer={DrawerView[currentView].footer}
      >
        {DrawerView[currentView].view}
      </OnboardingItemDrawer>
    </section>
  );
}

const Pair = ({
  newBusinessAccountForm,
  onSubmit,
}: {
  newBusinessAccountForm: UseFormReturn<ServiceSchemaDtoSchema[0]>;
  onSubmit: SubmitHandler<ServiceSchemaDtoSchema[0]>;
}) => {
  const business_account = useWatch({
    control: newBusinessAccountForm.control,
  });

  const currency = useMemo(() => {
    if (business_account) return business_account.pair;
    return "";
  }, [business_account]);

  return (
    <Form {...newBusinessAccountForm}>
      <form
        id={"business_account_form"}
        className=" pb-[1.5rem] flex flex-col gap-[24px]"
        onSubmit={(e) => {
          e.stopPropagation();
          newBusinessAccountForm.handleSubmit(onSubmit)(e);
        }}
      >
        <FormSingleComboBox
          label="Desired currency"
          control={newBusinessAccountForm.control}
          placeholder=""
          name={`pair`}
          data={SupportedCurrencies.map((currency) => ({
            label: currency,
            value: currency,
          }))}
        />

        <FormSingleComboBox
          label={`Expected Monthly Volumes ${currency ? `(${currency})` : ""}`}
          control={newBusinessAccountForm.control}
          name={"expected_monthly_volume"}
          data={volumeBands(currency)}
        />
        <FormSingleComboBox
          label={`Expected Annual Volumes ${currency ? `(${currency})` : ""}`}
          control={newBusinessAccountForm.control}
          name={`expected_annual_volume`}
          data={volumeBands(currency)}
        />
      </form>
    </Form>
  );
};
