import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "@/components/ui/drawer";

import CloseCircleFilledIcon from "@/icons/close-circle-filled";
import { RefObject, useState } from "react";

interface OnboardingItemDrawerProps {
  heading?: string;
  subTitle?: string;
  children: React.ReactNode;
  Footer: React.ReactNode;
  closeButtonRef: React.RefObject<HTMLButtonElement>;
  triggerRef: React.RefObject<HTMLButtonElement>;
}

const OnboardingItemDrawer = ({
  children,
  heading,
  closeButtonRef,
  Footer,
  triggerRef,
}: OnboardingItemDrawerProps) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      {isOpen ? (
        <div
          onClick={() => {
            setIsOpen(false);
          }}
          className="fixed inset-0 z-50 bg-black opacity-40 backdrop-blur-sm data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0"
        />
      ) : null}
      <Drawer
        direction="right"
        modal={false}
        open={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <DrawerTrigger
          ref={triggerRef as RefObject<HTMLButtonElement>}
          className="hidden"
          onClick={() => {
            setIsOpen(true);
          }}
        />

        <DrawerContent className="md:w-[34.25rem] w-full md:h-full h-[95dvh] after:hidden">
          <DrawerHeader className="flex justify-between items-center">
            <div>
              <DrawerTitle className="text-[1.5rem]">{heading}</DrawerTitle>
              {/* <DrawerDescription>{subTitle}</DrawerDescription> */}
            </div>
            <DrawerClose ref={closeButtonRef} onClick={() => setIsOpen(false)}>
              <CloseCircleFilledIcon />
            </DrawerClose>
          </DrawerHeader>
          <div className="px-[1.25rem] max-h-[80dvh] overflow-y-scroll">
            {children}
          </div>
          <DrawerFooter>{Footer}</DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default OnboardingItemDrawer;
