import { FormInput } from "@/components/form-input";
import { FormSingleComboBox } from "@/components/form-single-combo-box";
import { Form } from "@/components/ui/form";
import { USD_ACCOUNT_TYPES } from "@/domains/organizations/constants";
import { countriesDrownDownData } from "@/i18n/countries-data";
import { UseFormReturn, useWatch } from "react-hook-form";
import { UsdAccountSchemaDto } from "./validators";

const USDAccount = ({
  newUsdForm,
  onSubmit,
}: {
  newUsdForm: UseFormReturn<UsdAccountSchemaDto>;
  onSubmit: () => void;
}) => {
  const account = useWatch({ control: newUsdForm.control });
  return (
    <Form {...newUsdForm}>
      <form
        onSubmit={(e) => {
          e.stopPropagation();
          newUsdForm.handleSubmit(onSubmit)(e);
        }}
        id={"usd_form"}
        className=" pb-[1.5rem] flex flex-col gap-[24px]"
      >
        <div>
          <div className="flex flex-col gap-[1.5rem]">
            <FormInput
              disabled
              defaultValue={"USD"}
              name={`currency`}
              label="Currency"
              className="hidden"
              labelClass="hidden"
              control={newUsdForm.control}
            />

            <FormSingleComboBox
              name={`type`}
              label="Account details type"
              data={USD_ACCOUNT_TYPES.map((accountType) => ({
                label: accountType.replace("-", "").toUpperCase(),
                value: accountType,
              }))}
              control={newUsdForm.control}
            />
            <FormSingleComboBox
              name={`country`}
              label="Country"
              data={countriesDrownDownData}
              control={newUsdForm.control}
            />
            <FormInput
              name={"bank_name"}
              label="Bank Name"
              control={newUsdForm.control}
            />

            <FormInput
              name={`account_name`}
              label="Legal Name"
              control={newUsdForm.control}
            />

            {account.type === "ach" || account.type === "fed-wire" ? (
              <>
                <FormInput
                  name={`account_routing_number`}
                  label="Account routing number"
                  control={newUsdForm.control}
                />

                <FormInput
                  name={`account_number`}
                  label="Account Number"
                  control={newUsdForm.control}
                />
              </>
            ) : (
              <>
                <FormInput
                  name={`iban`}
                  label="IBAN"
                  control={newUsdForm.control}
                />
                <FormInput
                  name={`swift`}
                  label="BIC/SWIFT"
                  control={newUsdForm.control}
                />
              </>
            )}
          </div>
        </div>
      </form>
    </Form>
  );
};

export default USDAccount;
