import { SVGProps } from "react";

const ExternalLink = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.5344 15.5324H2.46876V2.46681H9.00156V0.599609H2.46876C1.43676 0.599609 0.601562 1.43481 0.601562 2.46681V15.5324C0.601562 16.5644 1.43676 17.3996 2.46876 17.3996H15.5344C16.5664 17.3996 17.4016 16.5644 17.4016 15.5324V8.99961H15.5344V15.5324ZM10.8688 0.599609V2.46681H14.2144L5.03916 11.6396L6.35916 12.962L15.5344 3.78681V7.13241H17.4016V0.599609H10.8688Z"
        fill="#25314C"
      />
    </svg>
  );
};

export default ExternalLink;
