import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";

import useComponentFocus from "@/internals/hooks/useComponentFocus";
import { cn } from "@/lib/utils";
import { BaseButtonProps } from "./types";

const buttonVariants = cva(
  "inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        default: "bg-sidebar text-sidebar-foreground hover:bg-white",
        destructive:
          "bg-destructive text-destructive-foreground hover:bg-destructive-light hover:text-destructive",
        outline:
          "border border-input bg-background hover:bg-accent hover:text-accent-foreground",
        secondary:
          "bg-secondary text-secondary-foreground hover:bg-secondary/80",
        ghost: "hover:bg-accent hover:text-accent-foreground",
        link: "text-primary underline-offset-4 hover:underline",
      },
      size: {
        default: "h-10 px-4 py-2 w-56",
        sm: "h-9 rounded-md px-3 w-52",
        lg: "h-11 rounded-md px-8 w-80",
        icon: "h-10 w-10",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
);

export interface MenuItemProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants>,
    BaseButtonProps {
  asChild?: boolean;
}

const MenuItem = React.forwardRef<HTMLButtonElement, MenuItemProps>(
  (
    {
      className,
      variant,
      size,
      asChild = false,
      leftIcon: LeftIcon,
      rightIcon: RightIcon,
      children,
      ...props
    },
    ref
  ) => {
    const Comp = asChild ? Slot : "button";

    const { focused, ...rest } = useComponentFocus();


    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...rest}
        {...props}
      >
        <div className="flex justify-start items-center">
          {LeftIcon ? (
            <span className={`mr-2`}>
              {typeof LeftIcon === "function"
                ? LeftIcon({ focused })
                : LeftIcon}
            </span>
          ) : null}
          {children}
        </div>

        {RightIcon ? (
            <span className={`mr-2`}>
              {typeof RightIcon === "function"
                ? RightIcon({ focused })
                : RightIcon}
            </span>
          ) : null}
      </Comp>
    );
  }
);
MenuItem.displayName = "Button";

export { buttonVariants, MenuItem };
