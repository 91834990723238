import { FormDatePicker } from "@/components/form-date-picker";
import { FormInput } from "@/components/form-input";
import { FormPhoneInput } from "@/components/form-phone-input";
import { FormSingleComboBox } from "@/components/form-single-combo-box";

import { Organization } from "@/domains/organizations";

import FormFileInput from "@/components/form-file-input";
import { AddressInput } from "@/components/ui/address-input";
import { countriesDrownDownData } from "@/i18n/countries-data";
import { onPlaceSelectedFn } from "@/internals/address";
import { SubmitHandler, UseFormReturn, useWatch } from "react-hook-form";
import { ShareholderSchemaDTO } from "./validator";

import { Form } from "@/components/ui/form";

const shareholderType = [
  {
    label: "Company",
    value: Organization.Corporate,
  },
  {
    label: "Individual",
    value: Organization.Individual,
  },
];

const AddShareholder = ({
  newShareholderForm,
  onSubmit,
}: {
  newShareholderForm: UseFormReturn<ShareholderSchemaDTO>;
  onSubmit: SubmitHandler<ShareholderSchemaDTO>;
}) => {
  const shareholder_type = useWatch({
    control: newShareholderForm.control,
    name: `shareholder_type`,
  });
  const onPlaceSelected = onPlaceSelectedFn(
    newShareholderForm.setValue,
    `address`
  );

  return (
    <Form {...newShareholderForm}>
      <form
        id={"shareholder_form"}
        onSubmit={(e) => {
          e.stopPropagation();
          newShareholderForm.handleSubmit(onSubmit)(e);
        }}
        className="pb-[1.5rem] flex flex-col gap-[24px]"
      >
        <FormSingleComboBox
          name={`shareholder_type`}
          label="Shareholder type"
          data={shareholderType}
        />
        {/* TODO: fix optional filed rendering */}
        {shareholder_type === "corporate" ? (
          <>
            <FormInput
              name={`name`}
              label="Business Name"
              placeholder=""
              control={newShareholderForm.control}
            />

            <FormDatePicker
              label="Business registration date"
              control={newShareholderForm.control}
              name={`date_of_birth`}
            />
          </>
        ) : (
          <>
            <FormInput
              name={`first_name`}
              label="First Name"
              placeholder=""
              control={newShareholderForm.control}
            />

            <FormInput
              name={`last_name`}
              label="Last Name"
              placeholder=""
              control={newShareholderForm.control}
            />
            <FormDatePicker
              label="Date of birth"
              control={newShareholderForm.control}
              name={`date_of_birth`}
            />
          </>
        )}

        <FormInput
          label="Shareholding (0% - 100%)"
          type="number"
          name={`stakeholder_shareholding`}
          control={newShareholderForm.control}
        />
        <FormInput
          label="Email Address"
          name={`email`}
          control={newShareholderForm.control}
          type="email"
        />

        <FormPhoneInput
          label="Phone Number"
          defaultCountry="NG"
          name={`phone_number`}
          control={newShareholderForm.control}
        />

        <AddressInput
          control={newShareholderForm.control}
          name={`address.address`}
          label="Address"
          onPlaceSelected={onPlaceSelected}
        />
        <FormSingleComboBox
          label="Nationality"
          data={countriesDrownDownData}
          control={newShareholderForm.control}
          name={`nationality`}
        />

        {shareholder_type === "corporate" ? (
          <>
            <FormFileInput
              label="Certificate of Incorporation"
              maxSize={10}
              name={`incorporation_certificate`}
              control={newShareholderForm.control}
            />

            <FormFileInput
              label="Memorandum & Articles of Association"
              maxSize={10}
              name={`memorandum`}
              control={newShareholderForm.control}
            />

            <FormFileInput
              label="Shareholder&lsquo;s Register"
              maxSize={10}
              name={`register`}
              control={newShareholderForm.control}
            />

            <FormFileInput
              label="Register of Directors"
              maxSize={10}
              name={`register_of_directors`}
              control={newShareholderForm.control}
            />
          </>
        ) : (
          <>
            <FormFileInput
              label="Upload ID"
              maxSize={10}
              name={`verification`}
              control={newShareholderForm.control}
            />
          </>
        )}
      </form>
    </Form>
  );
};

export default AddShareholder;
