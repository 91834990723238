import { createFileRoute, useParams } from "@tanstack/react-router";

import { Button } from "@/components/ui/button";

import { useOnboardingFlow } from "@/internals/hooks/useOnboardingFlow";

import { Form } from "@/components/ui/form";

import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";

import { InitCompanyStructure, InitFile } from "@/data/onboarding.data";
import {
  ShareholdersSchema,
  companyStructureSchema,
  companyStructureSchemaDTO,
} from "@/sections/onboarding/company-structure/validator";

import {
  Organization,
  accountQueryOptions,
  structureOptions,
  useSetStructure,
  useUpdateAccount,
  useUpdateStructure,
} from "@/domains/organizations";
import { useSuspenseQuery } from "@tanstack/react-query";
import { toast } from "sonner";
import { z } from "zod";
import DirectorSection from "@/sections/onboarding/company-structure/director-section";
import ShareholderSection from "@/sections/onboarding/company-structure/shareholder-section";

export const Route = createFileRoute(
  "/organization/$organizationId/_onboarding/onboarding/company-structure"
)({
  component: CompanyStructure,
  loader: (opts) =>
    Promise.all([
      opts.context.queryClient.ensureQueryData(
        structureOptions((opts.params as Record<string, string>).organizationId)
      ),
      opts.context.queryClient.ensureQueryData(
        accountQueryOptions(
          (opts.params as Record<string, string>).organizationId
        )
      ),
    ]),
});

function extractData(
  data: (Organization.Director | Organization.Shareholder)[] | null,
  position_held: string
): companyStructureSchemaDTO {
  if (!data) {
    return InitCompanyStructure;
  }
  const directors = (
    data.filter(
      (item) => item.type === Organization.DirectorType
    ) as Organization.Director[]
  ).map((director) => ({
    id: director.id,
    date_of_birth: new Date(director.date_of_birth),
    first_name: director.first_name,
    last_name: director.last_name,
    address: director.address,
    email: director.email_address,
    nationality: director.nationality,
    phone_number: director.phone_number,
    proof_of_address: director?.proof_of_address ?? InitFile,
    verification: director?.verification ?? InitFile,
    stakeholder_shareholding: director?.stakeholder_shareholding,
    isShareholder: !!director?.stakeholder_shareholding,
  }));
  const shareholders = data.filter(
    (item) => item.type === Organization.ShareholderType
  ) as Organization.Shareholder[];

  // businessShareholder
  const businessShareholder: z.infer<typeof ShareholdersSchema>[] = [];
  const individualShareholder: z.infer<typeof ShareholdersSchema>[] = [];

  for (const shareholder of shareholders) {
    const baseShareholder = {
      id: shareholder.id,
      email: shareholder.email_address,
      stakeholder_shareholding: String(shareholder.ownership_percentage),
      address: shareholder.address,
      phone_number: shareholder.phone_number,
      nationality: shareholder.nationality,
      name: shareholder?.name ?? "",
      date_of_birth: new Date(shareholder.date_of_birth),
    };

    if (shareholder.account_type === "corporate") {
      businessShareholder.push({
        ...baseShareholder,
        shareholder_type: "corporate",
        register_of_directors: shareholder?.directors_register ?? InitFile,
        register: shareholder?.shareholders_register ?? InitFile,
        memorandum: shareholder?.articles ?? InitFile,
        incorporation_certificate:
          shareholder?.incorporation_certificate ?? InitFile,
      });
      continue;
    }

    if (shareholder.account_type === "individual") {
      individualShareholder.push({
        ...baseShareholder,
        shareholder_type: "individual",
        first_name: shareholder?.first_name ?? "",
        last_name: shareholder?.last_name ?? "",
        verification: shareholder?.verification ?? InitFile,
      });
    }
  }

  const mergedShareholders = [...businessShareholder, ...individualShareholder]
    .sort((a, b) => -a.shareholder_type.localeCompare(b.shareholder_type))
    .sort((a, b) =>
      a.stakeholder_shareholding.localeCompare(b.stakeholder_shareholding)
    );

  return {
    position_in_company: position_held,
    // @ts-expect-error - union
    directors,
    shareholders: mergedShareholders,
  };
}

export function CompanyStructure() {
  const organizationId = useParams({
    from: "/organization/$organizationId",
    select(params) {
      return params.organizationId;
    },
  });
  const { data } = useSuspenseQuery(structureOptions(organizationId));
  const { data: account, refetch: refetchAccount } = useSuspenseQuery(
    accountQueryOptions(organizationId)
  );

  const values = extractData(data, account?.position_held as string);

  const { prev: handlePrevious, next: handleNext } = useOnboardingFlow();
  const form = useForm<companyStructureSchemaDTO>({
    resolver: zodResolver(companyStructureSchema),
    defaultValues: values,
  });

  const setStructure = useSetStructure();
  const updateStructure = useUpdateStructure();
  const updateAccount = useUpdateAccount();

  const onSubmit: SubmitHandler<companyStructureSchemaDTO> = async (values) => {
    console.log(values);

    refetchAccount();
    const shareholders: Organization.Affiliate[] = [];

    for (const shareholder of values.shareholders) {
      const baseShareholder = {
        id: shareholder.id,
        email_address: shareholder.email,
        ownership_percentage: Number(shareholder.stakeholder_shareholding),
        address: shareholder.address,
        phone_number: shareholder.phone_number,
        nationality: shareholder.nationality,
        date_of_birth: new Date(shareholder.date_of_birth).toISOString(),
        type: "shareholder",
        organization_id: organizationId,
      } as const;

      if (shareholder.shareholder_type === "corporate") {
        shareholders.push({
          ...baseShareholder,
          account_type: "corporate",
          directors_register_id: shareholder?.register_of_directors.id,
          shareholders_register_id: shareholder?.register.id,
          articles_id: shareholder?.memorandum.id,
          incorporation_certificate_id:
            shareholder?.incorporation_certificate.id,
          name: shareholder?.name,
        });
        continue;
      }

      if (shareholder.shareholder_type === "individual") {
        shareholders.push({
          ...baseShareholder,
          account_type: "individual",
          first_name: shareholder?.first_name,
          last_name: shareholder?.last_name,
          verification_id: shareholder?.verification.id,
        });
      }
    }

    const directors = values.directors.map(
      (director) =>
        ({
          id: director.id,
          email_address: director.email,
          type: "director",
          account_type: "individual",
          date_of_birth: new Date(director.date_of_birth).toISOString(),
          address: director.address,
          phone_number: director.phone_number,
          organization_id: organizationId,

          first_name: director.first_name,
          last_name: director.last_name,
          nationality: director.nationality,
          verification_id: director.verification.id,
          proof_of_address_id: director.proof_of_address.id,
        }) as const
    );

    const isUpdate =
      directors.some((d) => !!d.id) || shareholders.some((s) => !!s.id);

    const updateDirectors = directors.filter((d) => !!d.id);
    const updateShareholders = shareholders.filter((d) => !!d.id);

    const nonUpdateDirectors = directors.filter((d) => !d.id);
    const nonUpdateShareholders = shareholders.filter((d) => !d.id);

    if (isUpdate) {
      await updateStructure.mutateAsync(
        {
          id: organizationId,
          directors: updateDirectors,
          shareholders: updateShareholders,
        },
        {
          onSuccess(data) {
            toast.success(data.message);
            // handleNext();
          },
          onError(error) {
            console.log(error);
          },
        }
      );
    }

    return setStructure.mutateAsync(
      {
        id: organizationId,
        directors: nonUpdateDirectors,
        shareholders: nonUpdateShareholders,
      },
      {
        onSuccess(data) {
          toast.success(data.message);
          handleNext();
        },
        onError(error) {
          // TODO: FIX, get the typename to align sigh

          console.log(error);
        },
      }
    );
  };

  return (
    <Form {...form}>
      <form id={"company_structure"} onSubmit={form.handleSubmit(onSubmit)}>
        <div>
          <DirectorSection />

          <ShareholderSection />

          <div className="my-12 flex items-center justify-between gap-8">
            <Button
              className="bg-grey-light-background text-black rounded-[0.625rem]"
              size="sm"
              onClick={handlePrevious}
              disabled={setStructure.isPending || updateAccount.isPending}
            >
              Previous
            </Button>
            <Button
              size={"sm"}
              type="submit"
              form={"company_structure"}
              className="rounded-[0.625rem]"
              loading={setStructure.isPending || updateAccount.isPending}
            >
              Next
            </Button>
          </div>
        </div>
      </form>
    </Form>
  );
}
