import { useRouter } from "@tanstack/react-router";
import React from "react";

export function useRedirectEffect(condition: boolean, redirect = "/") {
  const router = useRouter();
  // Ah, the subtle nuances of client side auth. 🙄
  React.useLayoutEffect(() => {
    if (condition && redirect) {
      router.history.push(redirect);
    }
  }, [condition, redirect, router.history]);
}
